import React, { useState, useEffect } from 'react';
import WellnessStore from '../DataRepo/WellnessStore';
import { IconButton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress } from '@mui/material';
import loadingimg from '../Assets/images/loading image 1.png'
import cautionimg from '../Assets/images/Caution icon 1.png'
import vegicon from '../Assets/images/veg_icon.png'
import nonvegicon from '../Assets/images/non_veg_icon.png'
import { Button } from '@mui/material';
import lockimg from '../Assets/images/lock_icon.png'
import Dietsampleimg1 from '../Assets/images/D-1.png'
import Dietsampleimg2 from '../Assets/images/D-2.png'
import Dietsampleimg3 from '../Assets/images/D-3.png'
import Dietsampleimg4 from '../Assets/images/D-4.png'
import SubscriptionStore from '../DataRepo/SubscriptionStore';
import AppointmentStore from '../DataRepo/AppointmentStore';
import { getUserDetails } from '../Auth/AuthUtil';

function DietPage() {
    const navigate = useNavigate()

    const userDetails = getUserDetails()

    const { Appointments, isAppointmentsLoading, getAppointments } = AppointmentStore()

    const { Subscriptiondetails, Subscriptiondetailsloading, getSubscriptiondetails, isSubscribed } = SubscriptionStore()

    const { getRecipes, Recipes, isRecipeLoading, Diets, getDiet, isDietLoading } = WellnessStore();

    const location = useLocation();

    useEffect(() => {
        getRecipes()
        getDiet()
        getAppointments(userDetails?.UID)
        getSubscriptiondetails()
    }, [])

    const groupBy = (array, key) => {
        if (!array) return {};
        return array.reduce((result, currentValue) => {
            const groupKey = currentValue[key];
            if (!result[groupKey]) {
                result[groupKey] = [];
            }
            result[groupKey].push(currentValue);
            return result;
        }, {});
    };

    const DietsSuitable = groupBy(Diets?.Suitable, 'categoryEng');
    const DietsNot_Suitable = groupBy(Diets?.Not_Suitable, 'categoryEng');

    const predefinedOrder = [
        'Cereals & Millets',
        'Fruits',
        'Vegetables',
        'Pulses',
        'Spices',
        'Sugar Products',
        'Milk products',
        'Oil and Fats',
        'General',
        'Alcoholic Beverages'
    ];

    const Categorieslabel = {
        'Cereals & Millets': 'Cereals & Millets',
        'Fruits': 'Fruits & Nuts',
        'Vegetables': 'Vegetables',
        'Pulses': 'Pulses',
        'Spices': 'Spices',
        'Sugar Products': 'Sugar Products',
        'Milk products': 'Milk products',
        'Oil and Fats': 'Oil & Fats',
        'General': 'General',
        'Alcoholic Beverages': 'Alcoholic Beverages'
    }

    const landingImages = {
        'Cereals & Millets': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Cereals+%26+Millets.png',
        'Fruits': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Fruits.png',
        'Vegetables': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Vegetables.png',
        'Pulses': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Pulses.png',
        'Spices': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Spices.png',
        'Sugar Products': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Sugar+Products.png',
        'Milk products': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Milk+Products.png',
        'Oil and Fats': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Oil+%26+Fats.png',
        'General': "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/General.png",
        'Alcoholic Beverages': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Alcoholic+Beverages.png'
    };

    const uniqueCategories = predefinedOrder.filter(category =>
        Object.keys(DietsSuitable).includes(category) || Object.keys(DietsNot_Suitable).includes(category)
    );

    const queryParams = new URLSearchParams(location?.search);

    const id = queryParams?.get('id') || '';

    const filteredRecipes = Recipes?.[0]

    const DietSampleImages = [Dietsampleimg1, Dietsampleimg2, Dietsampleimg3, Dietsampleimg4]

    const filterAppointments = Appointments.filter(e => e.appointment_status === 'upcoming' || e.appointment_status === 'confirmed').sort((a, b) => {
        const dateA = a?.timestamp ? new Date(Number(a?.timestamp)) : new Date(0);
        const dateB = b?.timestamp ? new Date(Number(b?.timestamp)) : new Date(0);
        return dateB - dateA;
    });

    return <div className='All-products-section-main'>
        {
            isRecipeLoading || isDietLoading
                ?
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                    <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                        <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                        </div>
                    </div>
                </div> :

                Diets.length === 0 || Recipes.length === 0 ?
                    <div className='p-5'
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100vh' }}>

                        <div className='Unlockdiet mt-5'>
                            <div>
                                <img src={lockimg}></img>
                            </div>

                            <div style={{ width: "100%", maxWidth: "300px" }}>
                                <Button
                                    style={{
                                        width: "100%",
                                        textTransform: "none",
                                        backgroundColor: "#DA6E0B",
                                        border: "none",
                                        color: "#fdfdfd",
                                        borderRadius: "5px",
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        padding: "10px",
                                    }}

                                    onClick={() => {
                                        {
                                            isSubscribed ?
                                                filterAppointments.length === 0 ?
                                                    navigate("/DoctorProfiles")
                                                    : navigate(`/Appointment?id=${filterAppointments[0]?.Appointment_id}&doc_id=${filterAppointments[0]?.doctor_uid}`)
                                                : navigate("/PackageCheckout")
                                        }
                                    }}

                                >
                                    <p className='appointmentbooknowbtn'>
                                        {
                                            isSubscribed ?
                                                filterAppointments.length === 0 ?
                                                    "Book your consultation"
                                                    : "Complete your consultation"
                                                : 'Purchase our Wellness Package'
                                        }
                                    </p>

                                </Button>
                            </div>

                            <p>To get personalized Diet & Recipes</p>

                            <div className="circle-container">
                                {DietSampleImages.map((image, index) => (
                                    <div key={index} className="circle" style={{ backgroundImage: `url(${image})` }}></div>
                                ))}
                            </div>

                        </div>

                    </div> :

                    <div className='Recipe-page-section' style={{ marginTop: '1rem' }}>

                        {Recipes.length > 0 &&
                            <div className='RecipeLandingCard'>
                                <div>
                                    <p>{filteredRecipes?.recipe}</p>
                                    <p>{filteredRecipes?.info}</p>
                                </div>
                                <div>
                                    <img src={filteredRecipes?.imageLink}></img>
                                </div>
                            </div>
                        }

                        {
                            uniqueCategories.length > 0 &&
                            <div className='RmddRec mt-5'>
                                <p>Recommended Foods</p>

                                <div className='rmdfoods'>
                                    {uniqueCategories.map((category, index) => (
                                        <div onClick={() => {
                                            navigate(`/Diet?categoryname=${category}`)
                                        }}>
                                            <div >
                                                <img src={landingImages[category]}></img>
                                            </div>
                                            <div>
                                                <p>{Categorieslabel[category]}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        }

                        {
                            Recipes.length > 0 &&
                            <div className='RmddRec mt-5'>
                                <p>Recommended Recipes</p>
                                <div className="listofRecipes">
                                    {
                                        Recipes.map((e, i) => (
                                            <div key={i} onClick={() => navigate(`/Recipes?id=${e?.id}`)}>
                                                <div className="top-right-icon">
                                                    <img src={e.
                                                        isVeg ? vegicon :
                                                        nonvegicon} alt="Non-Vegetarian Icon" />
                                                </div>
                                                <div>
                                                    <img src={e?.imageLink} alt={`Recipe ${i}`} />
                                                </div>
                                                <p>{e?.recipe}</p>
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        }

                        {Recipes.length > 0 || uniqueCategories.length > 0 &&
                            <div className='mt-10'>
                                <div className='Slot-errreponse'>

                                    <div className='flex flex-col items-center'>
                                        <img src={cautionimg}></img>
                                        <p style={{ fontWeight: 500, fontSize: 'large' }}>Disclaimer</p>
                                    </div>
                                    <p>Recommendations are approved by our Ayurveda physician Dr.Prathiban. The assessment and suggestions provided here are for health and wellness purposes only. Please do not self-medicate. Consult an Ayurveda practitioner or medical professional for accurate guidance.</p>
                                </div>
                            </div>
                        }



                    </div>

        }

    </div >
}

export default DietPage