import create from 'zustand';
import { shopModel } from '../Axios/AxiosConfig';
import axios from 'axios';
import { getUserDetails } from '../Auth/AuthUtil';

const useCartStore = create((set, get) => ({
    cartItems: [],
    Coupons: [],
    isCartLoading: true,
    CouponDetails: null,
    deliveryCharges: 0,

    addItem: async (item, userId) => {
        try {
            const response = await shopModel.post('/cart', { userId, item });
            if (response.status === 200) {
                set((state) => ({
                    cartItems: response.data.items,
                }));
            }
        } catch (error) {
            console.log(error.response.data);
        }
    },

    removeItem: async (id) => {
        try {
            const userId = getUserDetails()?.UID
            const response = await shopModel.delete(`/cart/${userId}/${id}`);
            if (response.status === 200) {
                set((state) => ({
                    cartItems: response.data.items,
                }));
            }
        } catch (error) {
            console.error('Error removing item from cart:', error);
        }
    },

    getCart: async () => {
        try {
            const userId = getUserDetails()?.UID
            const response = await shopModel.get(`/cart/${userId}`)
            if (response.status === 200) {
                set((state) => ({
                    cartItems: response.data.items,
                    isCartLoading: false,
                }));
            }
        }
        catch (err) {
            if (err.response.status === 404) {
                set({
                    isCartLoading: false,
                })
            }
            console.error('Error removing item from cart:', err);
        }
    },

    clearCart: async () => {
        try {
            const userId = getUserDetails()?.UID
            const res = await shopModel.delete(`/cart/${userId}`)
            if (res.status === 200) {
                set({
                    cartItems: []
                })
            }
        }
        catch (err) {
            console.log(err?.response?.data)
        }

    },

    emptyCart: async () => {
        set({
            cartItems: []
        })
    },


    setCoupon: (data) => set({ CouponDetails: data }),

    updateQuantity: async (id, userId) => {
        const state = get();

        const item = state.cartItems.find(item => item.id === id);

        if (item && item?.quantity >= 1) {
            const newQuantity = item?.quantity + 1;
            try {
                const response = await shopModel.patch(`/cart/${userId}/${id}`, { newQuantity });
                if (response.status === 200) {
                    set((state) => ({
                        cartItems: response.data.items,
                    }));
                }
            } catch (error) {
                console.error('Error decreasing item quantity:', error.response.data);
            }
        }
    },

    getCouponDetails: async () => {
        try {
            const res = await shopModel.get('/getCoupons');
            if (res.status && res.data) {
                set({ Coupons: res?.data });
            }
        } catch (err) {
            console.error(err?.response?.data);
        }
    },


    decreaseQuantity: async (id, userId) => {
        const state = get();

        const item = state.cartItems.find(item => item.id === id);

        if (item && item.quantity > 1) {
            const newQuantity = item.quantity - 1;
            try {
                const response = await shopModel.patch(`/cart/${userId}/${id}`, { newQuantity });

                if (response.status === 200) {
                    set((state) => ({
                        cartItems: response.data.items,
                    }));
                }

            } catch (error) {
                console.error('Error decreasing item quantity:', error.response.data);
            }
        }
    },


    totalPrice: () => {
        const cartItems = get().cartItems;

        const existingItem = cartItems.find(cartItem => cartItem.companyid === '1006');

        if (existingItem) {
            set({ deliveryCharges: existingItem.deliverycharges });
        } else {
            set({ deliveryCharges: 0 });
        }

        const total = cartItems.reduce((total, item) => {
            return total + item.price * item.quantity;
        }, 0);

        return total;
    },

}));

export default useCartStore;
