import React, { useState } from 'react';
import Rating from "react-rating-stars-component";
import { useLocation } from 'react-router-dom';
import { teleConsultationModel } from '../Axios/AxiosConfig';

function DoctorReview() {
    const location = useLocation()

    const questions = [
        "How professional was the doctor during the consultation?",
        "How clear were the doctor's explanations and instructions?",
        "How well did the doctor communicate with you during the consultation?",
        "How likely are you to recommend this doctor to others for teleconsultation?",
        "How satisfied are you with the overall teleconsultation experience?"
    ];

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id') || '';

    const [currentStep, setCurrentStep] = useState(0);
    const [ratings, setRatings] = useState(Array(questions.length).fill(0));
    const [feedback, setFeedback] = useState("");
    const [currentRating, setCurrentRating] = useState(0)
    const [overallRating, setOverallRating] = useState(null)

    const handleRatingChange = (newRating) => {
        const updatedRatings = [...ratings];
        updatedRatings[currentStep] = newRating;
        setRatings(updatedRatings);
        setCurrentRating(newRating);

        if (currentStep < questions.length - 1) {
            setTimeout(() => {
                setCurrentStep(currentStep + 1);
                setCurrentRating(0);
            }, 200);
        } else {
            const totalRating = updatedRatings.reduce((sum, rating) => sum + rating, 0);
            const avgRating = totalRating / questions.length;
            setOverallRating(avgRating);
            setCurrentStep(currentStep + 1);
        }
    };

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleSubmitReview = async () => {
        try {
            let res = await teleConsultationModel.post('/Doctor_Review', {
                Appointment_id: id,
                rating: parseInt(overallRating?.toFixed(1)),
                comments: feedback
            })

            if (res.status === 200) {
                alert("Review submitted successfully")
            }
        }
        catch (err) {
            console.log(err?.response?.data)
        }

    }
    

    return (
        <div className='Doctor-review-page'>
            <div>
                <h1>Thank you!</h1>
                <p>Thank you for choosing our TeleConsultation service. We would appreciate it if you could share your feedback about your experience with the Ayurvedic physician.</p>
            </div>

            <div>
                {currentStep < questions.length && (
                    <>
                        <div className='flex flex-col justify-center items-center'>
                            <p>{questions[currentStep]}</p>
                            <Rating
                                count={5}
                                size={40}
                                activeColor="#DA6E0B"
                                value={currentRating} 
                                onChange={handleRatingChange}
                            />
                        </div>

                        <div className='flex flex-row justify-between items-center' style={{ marginTop: "10px" }}>
                            <button
                                style={{
                                    background: "none",
                                    border: "none",
                                    color: "#696969",
                                    cursor: "pointer",
                                    marginRight: "15px",
                                }}
                                onClick={() => {
                                    setCurrentStep(currentStep + 1);
                                    setCurrentRating(0); // Reset stars when skipping
                                }}
                            >
                                Skip
                            </button>

                            <button
                                style={{
                                    backgroundColor: "#90EE90",
                                    border: "none",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    marginLeft: "15px",
                                }}
                            >
                                <span>{currentStep + 1}/{questions.length}</span> →
                            </button>
                        </div>
                    </>
                )}

                {currentStep === questions.length && (
                    <div style={{ marginTop: "20px" }}>
                        <div className='flex flex-col justify-center items-center'>
                            <p>Your Overall Rating: {overallRating ? overallRating.toFixed(1) : "N/A"} / 5</p>
                        </div>

                        <textarea
                            rows="4"
                            cols="50"
                            value={feedback}
                            onChange={handleFeedbackChange}
                            placeholder="Please provide your feedback here..."
                            style={{
                                width: "100%",
                                padding: "10px",
                                borderRadius: "5px",
                                border: "1px solid #D3D3D3",
                                marginBottom: "15px",
                            }}
                        />

                        <div>
                            <button
                                style={{
                                    backgroundColor: "#90EE90",
                                    border: "none",
                                    padding: "10px 20px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}

                                onClick={handleSubmitReview}
                            >
                                Submit Feedback
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default DoctorReview;
