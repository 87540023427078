import { MoreVertical, ChevronLast, ChevronFirst } from "lucide-react";
import { useContext, createContext, useState } from "react";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from "react";
import { IconButton, Avatar } from "@mui/material";
import aiwelllogo from '../Assets/images/Aiwell Logo 1 1.png'
import aiwelllogo2 from '../Assets/images/Aiwell logo 1-1 (1).png'
import Profileicon from '../Assets/images/Profile.png'
import settingsicon from '../Assets/images/settings_black.png'
import profileiconblk from '../Assets/images/profile_black.png'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import logoutblkicon from '../Assets/images/logout_black.png'
import { useNavigate } from "react-router-dom";
import { clearTokens } from "../Auth/AuthUtil";
import { Visibility } from "@mui/icons-material";

const SidebarContext = createContext();

export default function Sidebar({ children }) {
    const [expanded, setExpanded] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const [opendialog, setOpendialog] = useState(false);

    const handleClickOpendialog = () => {
        setOpendialog(true);
    };

    const handleCloseDialog = () => {
        setOpendialog(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        console.log(event);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <aside className={`h-screen shadow-md transition-width duration-300 ${expanded ? "w-64" : "w-18 h-18"}`} id="sidebar">
            <nav className="h-full flex flex-col border-r shadow-sm">
                <div className="p-3 pb-2 flex justify-between items-center">
                    <img
                        src={aiwelllogo}
                        className={`overflow-hidden transition-all ${expanded ? "w-40" : "w-0 ml-5 hidden"}`}
                        alt=""
                    />
                    <button
                        onClick={() => setExpanded((curr) => !curr)}
                        className="p-1.5 rounded-lg"
                    >
                        {expanded ? <ChevronFirst /> : <ChevronLast />}
                    </button>
                </div>

                {
                    !expanded &&
                    <div className="w-full  flex flex-row items-center justify-center">
                        <img src={aiwelllogo2} className="w-14 p-1" />
                    </div>

                } 

                <SidebarContext.Provider value={{ expanded }}>
                    <ul className="flex-1 px-3 mt-5">{children}</ul>
                </SidebarContext.Provider>

                <div className="border-t flex p-3">
                    {/* <Avatar alt="Remy Sharp" src={Doctor_details?.img} /> */}
                    <div >
                        <div className={`flex 
                            w-full
                            profileaction
                            ${expanded ? "flex-row" : "flex-col"} 
                             items-center justify-center`} onClick={handleClick}>
                            <img src={Profileicon} style={{ width: "2rem" }}
                            >
                            </img>
                            <p style={expanded?{}:{visibility:"hidden"}}>Profile</p>
                        </div>



                    </div>
                </div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => {
                        navigate('Profilepage')
                    }}>
                        <div className="menuitem">
                            <p>View Profile</p>
                            <img src={profileiconblk} alt="Logout Icon"></img>
                        </div>
                    </MenuItem>

                    <MenuItem onClick={() => {
                        navigate('Settings')
                    }}>
                        <div className="menuitem">
                            <p>Settings</p>
                            <img src={settingsicon} alt="Logout Icon"></img>
                        </div>
                    </MenuItem>

                    <MenuItem onClick={handleClickOpendialog}>
                        <div className="menuitem">
                            <p>Logout</p>
                            <img src={logoutblkicon} alt="Logout Icon"></img>
                        </div>
                    </MenuItem>
                </Menu>

            </nav>
            <Dialog
                open={opendialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Logout"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to log out?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{
                            // width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            border: '1px solid #DA6E0B ',
                            color: '#DA6E0B',
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: '10px'
                        }}

                        onClick={handleCloseDialog}>No</Button>
                    <Button style={{
                        // width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: '5px',
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: '10px'
                    }}
                        onClick={() => {
                            clearTokens()
                            handleCloseDialog()
                            window.location.reload();
                        }} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </aside>
    );
}

export function SidebarItem({ icon, text, active, alert }) {
    const { expanded } = useContext(SidebarContext);

    return (
        <li
            className={`relative flex items-center py-2 px-3 my-1 mt-3 font-medium rounded-md cursor-pointer transition-colors group 
ś            ${active ? "bg-[#75623F] text-[#FDFDFD]" : "hover:bg-[#D4CEC3] text-gray-600"}`}
        >
            {icon}

            {expanded && <span className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}>
                {text}
            </span> }

            {alert && (
                <div className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${expanded ? "" : "top-2"} `} />
            )}

            {!expanded && (
                <div className={`absolute left-full rounded-md p-6 py-1 ml-6 bg-[#D4CEC3] text-[#75623f] text-sm hidden opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}>
                    {text}
                </div>
            )}
        </li>
    );
}

