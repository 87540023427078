import React, { useState, useEffect } from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { IconButton, Button } from '@mui/material';
import { profileModel } from '../Axios/AxiosConfig';
import { getUserDetails } from '../Auth/AuthUtil';

function EditProfile() {
    const navigate = useNavigate();

    const genderOptions = ["Male", "Female", "Other"];
    const indianLanguages = [
        "Assamese", "Bengali", "Bodo", "Dogri", "Gujarati", "Hindi", "Kannada", "Kashmiri", "Konkani",
        "Maithili", "Malayalam", "Manipuri", "Marathi", "Nepali", "Odia", "Punjabi", "Sanskrit", "Santali",
        "Sindhi", "Tamil", "Telugu", "Urdu"
    ];

    const [profileData, setProfileData] = useState({
        mothertounge: '',
        lastname: '',
        src: '',
        height: '',
        dob: '',
        firstname: '',
        email: '',
        phone: '',
        UID: '',
        gender: '',
        weight: ''
    });

    const [isProfileLoading, setProfileLoading] = useState(true);
    const [previewSrc, setPreviewSrc] = useState('');

    const getProfile = async (id) => {
        try {
            let res = await profileModel.get(`/Profile/${id}`);
            if (res.status === 200) {
                const profile = res.data;
                setProfileData(profile);
                setPreviewSrc(profile.src);
                setProfileLoading(false);
            }
        } catch (err) {
            console.log(err?.response?.data);
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64data = reader.result.split(',')[1]; // Get base64 data
                try {
                    const uploadResponse = await profileModel.post('/UpdateProfilepicture', {
                        UID: profileData.UID,
                        data: base64data,
                        filename: file.name
                    });

                    if (uploadResponse.status === 200) {
                        const imageUrl = uploadResponse.data.data;
                        setProfileData({
                            ...profileData,
                            src: imageUrl
                        });
                        setPreviewSrc(imageUrl);
                    }
                } catch (err) {
                    console.log(err?.response?.data);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData({
            ...profileData,
            [name]: value
        });
    };

    const handleSave = async () => {
        try {
            let res = await profileModel.post(`/Profile`, { ...profileData });
            if (res.status === 200) {
                navigate(-1);
            }
        } catch (err) {
            console.log(err?.response?.data);
        }
    };

    useEffect(() => {
        const userDetails = getUserDetails();
        getProfile(userDetails?.UID);
    }, []);

    return (
        <div className='All-products-section-main'>
            <nav className='flex flex-row p-2 items-center justify-between'>
                <div className='flex flex-row items-center'>
                    <IconButton onClick={() => navigate(-1)}>
                        <ChevronLeft />
                    </IconButton>
                    <p className='mb-0'>Edit Profile</p>
                </div>
            </nav>
            <div className='Profile-edit-section'>
                <div className="Profile-picture-section" style={{ textAlign: 'center', }}>
                    <div className="Profile-picture-container" style={{ position: 'relative', display: 'inline-block' }}>
                        {previewSrc ? (
                            <img
                                src={previewSrc}
                                alt="Profile"
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                    border: '2px solid #ccc'
                                }}
                            />
                        ) : (
                            <div
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    borderRadius: '50%',
                                    backgroundColor: '#f0f0f0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '24px',
                                    color: '#aaa'
                                }}
                            >
                                ?
                            </div>
                        )}
                        <input
                            type="file"
                            accept="image/*"
                            id="profile-picture-upload"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <label htmlFor="profile-picture-upload" style={{ position: 'absolute', bottom: 0, right: 0 }}>
                            <IconButton component="span">
                                <EditIcon />
                            </IconButton>
                        </label>
                    </div>
                </div>
                <div className="Profile-form-container">
                    <div className='Profile-input-container'>
                        <label>First Name</label>
                        <input
                            type="text"
                            name="firstname"
                            value={profileData?.firstname}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className='Profile-input-container'>
                        <label>Last Name</label>
                        <input
                            type="text"
                            name="lastname"
                            value={profileData?.lastname}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className='Profile-input-container'>
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            value={profileData?.email}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className='Profile-input-container'>
                        <label>Phone</label>
                        <input
                            type="text"
                            name="phone"
                            value={profileData?.phone}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className='Profile-input-container'>
                        <label>Gender</label>
                        <input
                            list="gender-options"
                            name="gender"
                            value={profileData?.gender}
                            onChange={handleInputChange}
                        />
                        <datalist id="gender-options">
                            {genderOptions.map((option, index) => (
                                <option key={index} value={option} />
                            ))}
                        </datalist>
                    </div>

                    <div className='Profile-input-container'>
                        <label>Mother Tongue</label>
                        <input
                            list="motherTongue-options"
                            name="mothertounge"
                            value={profileData?.mothertounge}
                            onChange={handleInputChange}
                        />
                        <datalist id="motherTongue-options">
                            {indianLanguages.map((option, index) => (
                                <option key={index} value={option} />
                            ))}
                        </datalist>
                    </div>

                    <div className='Profile-input-container'>
                        <label>Date of Birth</label>
                        <input
                            type="date"
                            name="dob"
                            value={profileData?.dob?.split('/')?.reverse()?.join('-')}
                            onChange={(e) => {
                                setProfileData({
                                    ...profileData,
                                    dob: e?.target?.value.split('-').reverse().join('/')
                                });
                            }}
                        />
                    </div>

                    <div className='Profile-input-container'>
                        <label>Height</label>
                        <input
                            type="text"
                            name="height"
                            value={profileData?.height}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className='Profile-input-container'>
                        <label>Weight</label>
                        <input
                            type="text"
                            name="weight"
                            value={profileData?.weight}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div>
                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            backgroundColor: '#DA6E0B',
                            color: '#FDFDFD',
                            border: '1px solid #DA6E0B',
                            boxSizing: 'border-box',
                            paddingRight: "3rem",
                            paddingLeft: "3rem",
                            borderRadius: "5px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: "10px"
                        }}
                        onClick={handleSave}>Save</Button>
                </div>

            </div>
        </div>
    );
}

export default EditProfile;
