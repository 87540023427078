import create from 'zustand';
import { profileModel } from '../Axios/AxiosConfig'; // Adjust the path according to your structure
import { getUserDetails } from '../Auth/AuthUtil';
import Profile from '../Profile/Profile';

const WellnessStore = create((set) => ({
    isDietLoading: true,
    isMeditationdataLoading: true,
    isRecipeLoading: true,
    isYogaLoading: true,
    isPranayamaLoading: true,
    Profile: null,
    Selected_Wellness: [],
    Meditationdata: [],
    Diets: [],
    Recipes: [],
    selectedYogas: [],
    unselectedYogas: [],
    selectedPranayama: [],
    unselectedPranayama: [],


    getProfile: async () => {
        try {
            const userDetails = getUserDetails();
            if (userDetails && userDetails.UID) {
                let res = await profileModel.get(`/Profile/${userDetails?.UID}`)
                if (res.status === 200) {
                    const profile = res?.data
                    set({ Profile: profile })
                }
            }
        }
        catch (err) {
            console.log(err?.response?.data)
        }
    },

    getDiet: async () => {
        try {
            const userDetails = getUserDetails();
            if (userDetails && userDetails.UID) {
                const res = await profileModel.post('/getDietRecommendations', {
                    id: userDetails.UID
                });
                if (res.status === 200) {
                    set({
                        Diets: res?.data,
                        isDietLoading: false
                    });
                }
            } else {
                set({

                    isDietLoading: false
                });
                console.error('User details not found');
            }

        } catch (err) {
            set({
                isDietLoading: false
            });
            console.error(err.response?.data || err.message);
        }
    },

    getRecipes: async () => {
        try {
            const userDetails = getUserDetails();
            if (userDetails && userDetails.UID) {
                const res = await profileModel.post('/getRecipiesRecommendations', {
                    id: userDetails.UID
                });
                if (res.status === 200) {
                    set({
                        Recipes: res?.data,
                        isRecipeLoading: false
                    });
                }
            } else {
               
                set({
                    isRecipeLoading: false
                });

                console.error('User details not found');
            }

        } catch (err) {
            
            set({
                isRecipeLoading: false
            });

            console.error(err.response?.data || err.message);
        }
    },

    getYoga: async () => {
        try {
            const userDetails = getUserDetails();
            if (userDetails && userDetails.UID) {
                const res = await profileModel.get(`/getYoga/${userDetails?.UID}`);
                if (res.status === 200) {
                    set({
                        selectedYogas: res?.data?.selectedPoses,
                        unselectedYogas: res?.data?.unselectedPoses,
                        isYogaLoading: false
                    });
                }
            } else {
                console.error('User details not found');
            }

        } catch (err) {
            console.error(err.response?.data || err.message);
        }
    },

    getPranayama: async () => {
        try {
            const userDetails = getUserDetails();
            if (userDetails && userDetails.UID) {
                const res = await profileModel.get(`/getPranayama/${userDetails.UID}`);
                if (res.status === 200) {
                    set({
                        selectedPranayama: res?.data?.selectedPranayama,
                        unselectedPranayama: res?.data?.unselectedPranayama,
                        isPranayamaLoading: false
                    });
                }
            } else {
                console.error('User details not found');
            }

        } catch (err) {
            console.error(err.response?.data || err.message);
        }
    },

    getMedidationData: async () => {
        try {
            const res = await profileModel.get('/getMeditation');
            if (res.status === 200) {
                set({
                    Meditationdata: res?.data,
                    isMeditationdataLoading: false
                });
            }
        } catch (err) {
            console.error(err?.response?.data || err?.message);
        }
    },

    getSelected_Wellness: async () => {
        try {
            const userDetails = getUserDetails();
            if (userDetails && userDetails.UID) {
                const res = await profileModel.get(`getwellnessCategories/${userDetails?.UID}`);
                if (res.status === 200) {
                    set({ Selected_Wellness: res?.data?.[0]?.Categories });
                }
            } else {
                console.log('User details not found');
            }
        } catch (err) {
            console.log(err?.response?.data || err?.message);
        }
    }

}));

export default WellnessStore;
