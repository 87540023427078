import React, { useEffect, useState } from 'react'
import CreditsStore from '../DataRepo/CreditsStore'
import coinsanimation from '../Assets/animations/credits.json'
import Lottie from 'lottie-react'
import coinimg from '../Assets/images/Coin_icon.png'
import phenotypeAssesmentimg from '../Assets/images/P.A.png'
import HMimg from '../Assets/images/H.P.png'
import yogalandingimg from '../Assets/images/Yoga_Landingpage.png'
import pranayamalandingimg from '../Assets/images/Pranayama.png'
import meditationlandingimg from '../Assets/images/MeditationL.png'

function CreditsPage() {
  const { getCredits, Credits, getCreditshistory, CreditsHistory } = CreditsStore()

  const [currentSection, setCurrentSection] = useState('All')

  useEffect(() => {
    getCredits()
  }, [])

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];


  const CreditsType = {
    "Phenotype Assessment": phenotypeAssesmentimg,
    "HomeoStatis Imbalanace": HMimg,
    "Yoga": yogalandingimg,
    "Pranayama": pranayamalandingimg,
    "Meditation": meditationlandingimg
  }

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentDate = today.getDate();

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedDate, setSelectedDate] = useState(currentDate);

  const formatMonth = (monthIndex) => {
    const year = today.getFullYear();
    const monthFormatted = (monthIndex + 1).toString().padStart(2, '0');
    return `${year}-${monthFormatted}`;
  };

  const formatDate = (monthIndex, day) => {
    const year = today.getFullYear();
    const monthFormatted = (monthIndex + 1).toString().padStart(2, '0');
    const dayFormatted = day.toString().padStart(2, '0');
    return `${year}-${monthFormatted}-${dayFormatted}`;
  };

  useEffect(() => {
    const monthFormatted = formatMonth(selectedMonth);
    const dateFormatted = selectedDate ? formatDate(selectedMonth, selectedDate) : null;
    getCreditshistory(dateFormatted, monthFormatted);
  }, [selectedMonth, selectedDate]);

  const handleMonthClick = (index) => {
    setSelectedMonth(index);
    setSelectedDate(null);
    const monthFormatted = formatMonth(index);
    getCreditshistory(null, monthFormatted);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    const monthFormatted = formatMonth(selectedMonth);
    const dateFormatted = formatDate(selectedMonth, date);
    console.log(`Date selected: ${dateFormatted}`);
    getCreditshistory(dateFormatted, monthFormatted);
  };


  const renderDays = () => {
    const daysInMonth = new Date(today.getFullYear(), selectedMonth + 1, 0).getDate();
    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }
    return days.map((day) => (
      <div
        key={day}
        onClick={() => handleDateClick(day)}
        style={{
          padding: '5px 10px',
          cursor: 'pointer',
          borderRadius: '4px',
          textAlign: "center",
          backgroundColor: selectedDate === day ? '#f2dac5' : 'transparent',
          border: selectedDate === day ? '1px solid #d9730d' : 'none',
        }}
      >
        <div>{daysOfWeek[(new Date(today.getFullYear(), selectedMonth, day)).getDay()]}</div>
        <div>{day.toString().padStart(2, '0')}</div>
      </div>
    ));
  };


  return <div className='m-5'>
    <div className='Credits-section'>
      <div className='Credits-info'>

        <div className='p-5 creditstoatlCoinsMain-container'>
          <div>
            <Lottie animationData={coinsanimation} loop={true} />
          </div>

          <div className='p-5 creditstoatlCoins-container'>
            <div className='flex flex-col items-center gap-2 creditstotalcoins justify-center'>
              <p>Total Coins</p>
              <p>{(Credits?.totalCredits - Credits?.usedCredits) || 0}</p>
              {Credits &&
                <p>Equals to ₹ {(Credits?.totalCredits - Credits?.usedCredits) / 100}</p>}
            </div>
          </div>

          <div>
            <Lottie animationData={coinsanimation} loop={true} />
          </div>
        </div>

        <div className='m-6 redeem-txt'
          style={{
            backgroundColor: "#DBF9DB",
            border: "2px solid #7FD67F",
            borderRadius: "5px",
            textAlign: "center",
            padding: "1rem",
            cursor: "pointer",
          }}>
          {Credits ? <p>Redeem</p> : <p>Earn Coins</p>}
        </div>

        <div className='redeem-txt'>
          {<p>Redeem for Teleconsultation or Herbal supplements</p> &&
            <p>Take the first step to wellness and watch your coins grow!</p>}
        </div>
      </div>

      <div className='Credits-details-section'>
        <div className='Credits-info-toggle'>

          <div style={currentSection === 'All' ? { backgroundColor: "#fdfdfd", padding: "5px", borderRadius: "5px" } : { padding: "5px" }}
            onClick={() => {
              setCurrentSection('All')
            }}>
            <p>All</p>
          </div>

          <div style={currentSection === 'Redeemed' ? { backgroundColor: "#fdfdfd", padding: "5px", borderRadius: "5px" } : { padding: "5px" }}
            onClick={() => {
              setCurrentSection('Redeemed')
            }}>
            <p>Redeemed</p>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }} className='mt-5'>

          <div
            style={{
              display: 'flex',
              gap: '8px',
              marginBottom: '16px',
              overflowX: 'auto',
              maxWidth: '100%',
            }}

            className='Month-navigation'
          >
            {months.map((month, index) => (
              <div
                key={month}
                onClick={() => handleMonthClick(index)}
                style={{
                  padding: '8px 12px',
                  cursor: 'pointer',
                  borderRadius: '8px',
                  backgroundColor: selectedMonth === index ? '#d9730d' : '#f2dac5',
                  color: selectedMonth === index ? 'white' : 'black',
                }}
              >
                {month}
              </div>
            ))}
          </div>

          <div
            style={{
              display: 'flex',
              gap: '16px',
              // justifyContent: 'center',
              overflowX: 'auto',
              maxWidth: '100%',
            }}
            className='Date-navigation'
          >
            {renderDays()}
          </div>

        </div>

        <div className='mt-5'>
          <div className='totalearnedcoins'>
            {CreditsHistory && CreditsHistory.length > 0 ? (
              <div className='flex flex-row gap-5 items-center justify-center'>
                <span>
                  <img src={coinimg} alt="coin icon" style={{ width: '20px', marginLeft: '5px', marginRight: "5px" }} />
                </span>

                <p style={{ display: 'inline-flex', alignItems: 'center' }}>
                  You have earned {CreditsHistory.reduce((total, item) => total + (item?.credits || 0), 0)} coins
                </p>
              </div>

            ) : (
              <div className='flex flex-row gap-5 items-center justify-center'>
                <span>
                  <img src={coinimg} alt="coin icon" style={{ width: '20px', marginLeft: '5px', marginRight: "5px" }} />
                </span>

                <p style={{ display: 'inline-flex', alignItems: 'center' }}>
                  You haven't collected any coins
                </p>
              </div>
            )}
          </div>
        </div>


        <div className='mt-5 flex flex-col gap-5'>
          {CreditsHistory && CreditsHistory.length > 0 ?
            CreditsHistory.map((e, i) => {
              return <div className='flex flex-row justify-between items-center credits-div' key={i}>
                <div className='flex flex-row items-center gap-5'>
                  <img src={CreditsType[e?.activity]} style={{ width: "3rem" }}></img>
                  <p>{e?.activity}</p>
                </div>

                <div className='flex flex-row items-center gap-5'>
                  <img src={coinimg} />
                  <p>{e?.credits} coins</p>
                </div>
              </div>
            }) : <></>
          }
        </div>

      </div>
    </div>
  </div>
}

export default CreditsPage