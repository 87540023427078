import React, { useEffect, useState } from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { useNavigate, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';

function Policy() {
    const navigate = useNavigate();
    const location = useLocation();
    const [id, setId] = useState(0);

    const policies = {
        '1': { name: "Privacy Policy", link: "https://ayurai.io/privacypolicyaiwell.html" },
        '2': { name: "Return & Refund Policy", link: "https://ayurai.io/returnandrefundaiwell.html" },
        '3': { name: "Shipping Policy", link: "https://ayurai.io/shippingpolicyaiwell.html" },
        '4': { name: "Terms & Conditions", link: "https://ayurai.io/termsandconditionaiwell.html" }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const policy = queryParams.get('Policy');

        setId(policy);

    }, [location]);

    return (
        <div className='All-products-section-main' style={{ position: 'relative', height: '100vh', width: '100vw' }}>
            <nav className='flex flex-row p-2 items-center justify-between'>
                <div className='flex flex-row items-center'>
                    <IconButton onClick={() => navigate(-1)}>
                        <ChevronLeft />
                    </IconButton>
                    <p className='mb-0'>{policies[id]?.name || 'Policy'}</p>
                </div>
            </nav>
            <div className='iframe-container' style={{ position: 'relative', height: 'calc(100vh - 56px)' }}>
                <iframe
                    src={policies[id]?.link}
                    frameBorder="0"
                    style={{
                        border: 'none',
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0
                    }}
                />
            </div>
        </div>
    );
}

export default Policy;
