import create from 'zustand';
import { reportsModel } from '../Axios/AxiosConfig';
import { getUserDetails } from '../Auth/AuthUtil';

const ReportStore = create((set, get) => ({
    Reports: [],
    isReportsLoading: true,

    getReports: async () => {
        try {
            const userDetails = getUserDetails();
            const response = await reportsModel.get(`/GetReports/${userDetails.UID}`);
            if (response.status === 200) {
                set({
                    Reports: response.data,
                    isReportsLoading: false,
                });
            }
        } catch (err) {
            console.log("Error fetching reports:", err?.response?.data || err.message);
        }
    },

    deletereport: async (id) => {
        console.log(id)
        try {
            const deleteResponse = await reportsModel.delete(`/DeleteReport/${id}`);
          
            if (deleteResponse.status === 200) {
                get().getReports();
            }
        } catch (err) {
            console.log("Error deleting report:", err?.response?.data || err.message);
        }
    }
}));

export default ReportStore;
