import React, { useEffect, useState } from 'react'
import WelcomeScreenanimation from '../Assets/animations/welcomscreenrotation.json';
import axios from 'axios';
import Lottie from 'lottie-react';
import { IconButton, Box, CircularProgress, Autocomplete, TextField, Chip, Button, Checkbox } from '@mui/material';
import WellnessStore from '../DataRepo/WellnessStore';
import VikritiStore from '../DataRepo/VikiritiStore';
import vikritinext from '../Assets/images/vikritinext.png'
import { useNavigate } from 'react-router-dom';
import { profileModel } from '../Axios/AxiosConfig';
import { getUserDetails } from '../Auth/AuthUtil';

function Symptoms() {

    const { Vikriti_data, isVikriti_dataloading, getVikritidata } = VikritiStore();

    const userDetails = getUserDetails()
    
    const [inputValue, setInputValue] = useState('');

    const { Selected_Wellness, getSelected_Wellness } = WellnessStore();

    const [selectedSymptoms, setSelectedSymptoms] = useState([]);

    const navigate = useNavigate()

    const [currentLevel, setCurrentLevel] = useState(1);

    const [selectedCustomSymptoms, setSelectedCustomSymptoms] = useState([]);

    const [currentSubCategoryIndex, setCurrentSubCategoryIndex] = useState(0);

    const [selectedSubCategories, setSelectedSubCategories] = useState([]);

    const [currentSubCategoryToDisplay, setCurrentSubCategoryToDisplay] = useState(0);

    const [showSelectedWellness, setShowSelectedWellness] = useState(false)

    const [selectedMainSymptoms, setMainSelectedSymptoms] = useState([]);

    const filteredVikritiData = Vikriti_data?.filter(item => item?.sub_category?.toLowerCase() !== 'na');

    const categories = filteredVikritiData?.filter((s) => Selected_Wellness?.filter((i) => i?.selected).some((e) => s?.category === e?.name));

    const groupedCategories = categories.reduce((acc, symptom) => {
        const { sub_category } = symptom;
        if (!acc[sub_category]) {
            acc[sub_category] = [];
        }
        acc[sub_category].push(symptom);
        return acc;
    }, {});

    const groupedFilteredVikritiData = filteredVikritiData
        ?.filter((s) => selectedSymptoms?.some((e) => e?.sub_category === s?.sub_category))
        ?.reduce((acc, symptom) => {
            const { sub_category } = symptom;
            if (!acc[sub_category]) {
                acc[sub_category] = [];
            }
            acc[sub_category]?.push(symptom);
            return acc;
        }, {});

    const groupedBySubCategory = showSelectedWellness
        ? groupedCategories
        : groupedFilteredVikritiData;

    const filteredSubCategoriesForCategories = Object?.keys(groupedCategories);

    const filteredSubCategoriesForFilteredVikritiData = Object?.keys(groupedFilteredVikritiData);

    const filteredSubCategories = showSelectedWellness
        ? filteredSubCategoriesForCategories
        : filteredSubCategoriesForFilteredVikritiData;

    const currentSubCategory = filteredSubCategories[currentSubCategoryIndex] || filteredSubCategories[0];

    const UpdateVkikriti = async (scores) => {
        try {
            let res = await profileModel.post('/UpdateVikriti', {
                UID: userDetails?.UID,
                count: selectedMainSymptoms?.length,
                date: Date?.now()?.toString(),
                vataAssScore: scores?.vataAssScore,
                pittaAssScore: scores?.pittaAssScore,
                kaphaAssScore: scores?.kaphaAssScore,
                vikritiList: selectedMainSymptoms,
                vataPulseScore: 0,
                pittaPulseScore: 0,
                kaphaPulseScore: 0,
            })

            if (res?.status === 200 && res?.data) {
                navigate('/diseases')
            }
        }

        catch (err) {
            console.log(err)
        }
    }

    const getUniqueSymptoms = () => {
        const symptomsSet = new Set();
        filteredVikritiData?.forEach(item => {
            item?.symptoms?.split(',')?.forEach(symptom => {
                symptomsSet?.add(symptom?.trim()?.toLowerCase());
            });
        });
        return Array.from(symptomsSet);
    };

    const uniqueSymptoms = getUniqueSymptoms();

    const handleAutocompleteChange = (event, value) => {
        if (value != null) {
            const symptomValue = value?.trim();
            const existingSymptom = filteredVikritiData?.find(item => item.symptoms.toLowerCase() === symptomValue.toLowerCase());

            if (symptomValue && !selectedSymptoms?.some((item) => item.symptoms === symptomValue) && !selectedCustomSymptoms.some((item) => item.symptoms === symptomValue)) {
                let newSymptomObj;

                if (existingSymptom) {
                    newSymptomObj = {
                        ...existingSymptom,
                        sub_category: existingSymptom.sub_category || 'na',
                        category: existingSymptom.category || 'na',
                        dhatu_increase: existingSymptom.dhatu_increase || 'na',
                        dhatu_decrease: existingSymptom.dhatu_decrease || 'na',
                        dosha_increase: existingSymptom.dosha_increase || 'na',
                        dosha_decrease: existingSymptom.dosha_decrease || 'na',
                        mala_increase: existingSymptom.mala_increase || 'na',
                        mala_decrease: existingSymptom.mala_decrease || 'na'
                    };
                    setSelectedSymptoms([...selectedSymptoms, newSymptomObj]);
                    setMainSelectedSymptoms([...selectedMainSymptoms, newSymptomObj]);
                } else {
                    newSymptomObj = {
                        dosha_decrease: "na",
                        symptoms: symptomValue,
                        sub_category: "na",
                        category: "na",
                        dhatu_increase: "na",
                        dosha_increase: "na",
                        id: Math.random().toString(36).substr(2, 9),
                        mala_increase: "na",
                        mala_decrease: "na",
                        dhatu_decrease: "na"
                    };

                    setSelectedCustomSymptoms([...selectedCustomSymptoms, newSymptomObj]);
                    setMainSelectedSymptoms([...selectedMainSymptoms, newSymptomObj]);
                }

                setInputValue('');
            }
        }
    };

    const handleItemClick = (symptomObj) => {
        if (!selectedSymptoms.some((item) => item.symptoms === symptomObj.symptoms)) {
            setSelectedSymptoms([...selectedSymptoms, symptomObj]);
            setMainSelectedSymptoms([...selectedMainSymptoms, symptomObj]);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const symptomValue = event.target.value.trim();
            const existingSymptom = filteredVikritiData?.find(item => item.symptoms.toLowerCase() === symptomValue.toLowerCase());

            if (symptomValue && !selectedSymptoms.some((item) => item.symptoms === symptomValue) && !selectedCustomSymptoms.some((item) => item.symptoms === symptomValue)) {

                let newSymptomObj;

                if (existingSymptom) {
                    newSymptomObj = {
                        ...existingSymptom,
                        sub_category: existingSymptom.sub_category || 'na',
                        category: existingSymptom.category || 'na',
                        dhatu_increase: existingSymptom.dhatu_increase || 'na',
                        dhatu_decrease: existingSymptom.dhatu_decrease || 'na',
                        dosha_increase: existingSymptom.dosha_increase || 'na',
                        dosha_decrease: existingSymptom.dosha_decrease || 'na',
                        mala_increase: existingSymptom.mala_increase || 'na',
                        mala_decrease: existingSymptom.mala_decrease || 'na'
                    };
                    setSelectedSymptoms([...selectedSymptoms, newSymptomObj]);
                    setMainSelectedSymptoms([...selectedMainSymptoms, newSymptomObj]);
                }

                else {
                    newSymptomObj = {
                        dosha_decrease: "na",
                        symptoms: symptomValue,
                        sub_category: "na",
                        category: "na",
                        dhatu_increase: "na",
                        dosha_increase: "na",
                        id: Math.random().toString(36).substr(2, 9),
                        mala_increase: "na",
                        mala_decrease: "na",
                        dhatu_decrease: "na"
                    };
                    setSelectedCustomSymptoms([...selectedCustomSymptoms, newSymptomObj]);
                    setMainSelectedSymptoms([...selectedMainSymptoms, newSymptomObj]);
                }

                setInputValue('');
            }
        }
    };

    const handleDelete = (symptomToDelete) => {
        setSelectedSymptoms(selectedSymptoms?.filter((symptom) => symptom?.symptoms !== symptomToDelete?.symptoms));
        setSelectedCustomSymptoms(selectedCustomSymptoms?.filter((symptom) => symptom?.symptoms !== symptomToDelete?.symptoms));
    };


    useEffect(() => {
        getVikritidata()
    }, [])

    return <div className='Symptoms-page-section'>
        <div className='' >
            <div className='flex items-center justify-between' >
                <div style={{ visibility: "hidden" }}>
                    Skip
                </div>
                <div className="Welcome-section-animation">
                    <Lottie animationData={WelcomeScreenanimation} />
                </div>
                <div className='skip-btn'
                    onClick={() => {
                        navigate('/diseases')
                    }}>
                    <p>Skip</p>
                </div>
            </div>

            <div style={{ alignSelf: "center", textAlign: "center" }} className='Symptoms-page-section-title'>
                <p>What health issues are you facing <br /> right now?</p>
            </div>

            <div className='mt-3'>
                <Autocomplete
                    freeSolo
                    options={uniqueSymptoms
                        ?.filter(symptom =>
                            !selectedSymptoms?.find(selected =>
                                selected?.symptoms?.toLowerCase() === symptom
                            )
                        )
                        ?.filter(symptom =>
                            symptom?.toLowerCase()?.includes(inputValue?.toLowerCase())
                        )?.map((e) => e?.charAt(0).toUpperCase() + e?.slice(1))
                    }

                    value={inputValue}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                    onChange={handleAutocompleteChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            value={inputValue}
                            placeholder="Enter your Symptoms"
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    )}
                />

                {(selectedSymptoms?.length > 0 || selectedCustomSymptoms?.length > 0) && (
                    <div className='selected-symptoms' style={{ marginTop: '20px' }}>
                        {/* <p>Complaints</p> */}
                        {[...selectedSymptoms, ...selectedCustomSymptoms]?.map((symptomObj, index) => (
                            <Chip
                                key={index}
                                label={symptomObj.symptoms}
                                onDelete={() => handleDelete(symptomObj)}
                                style={{ margin: '5px' }}
                            />
                        ))}
                    </div>
                )}
            </div>

            {selectedSymptoms.length > 0 &&
                <div className='mt-5' onClick={() => {
                    navigate('/diseases')
                }} style={{ alignSelf: "center", justifySelf: "flex-end", marginTop: "auto", width: "3rem", cursor: "pointer" }} >
                    <img src={vikritinext}></img>
                </div>}

        </div>

    </div>
}

export default Symptoms