import React from 'react'
import create from 'zustand'
import { shopModel } from '../Axios/AxiosConfig';
import { getUserDetails } from '../Auth/AuthUtil';
import axios from 'axios';

const ShopStore = create((set, get) => ({
    Products: [],
    Top_Products: [],
    Product: null,
    TeaProducts: [],
    recommedatedproducts: [],
    isLoading: false,
    isRecommendatedProductloading: true,
    SearchResults: [],
    Orders: [],
    isOrdersLoading: true,
    isOrderLoading: true,
    Order: null,
    RecentSearches: [],
    page: 1,
    limit: 10,

    getSearchedResults: async (search) => {
        try {
            let res = await shopModel.post('/SearchProducts', {
                search
            })

            if (res?.status === 200 && res.data) {
                set({
                    SearchResults: res?.data
                })
            }

        }
        catch (err) {
            console.log(err?.response?.data)
        }
    },

    getrecommedatedProducts: async () => {
        try {
            const userId = getUserDetails()?.UID
            const res = await shopModel.get(`/getRecommendedProduct/${userId}`);
            if (res.status == 200) {
                set({
                    recommedatedproducts: res.data,
                    isRecommendatedProductloading: false
                })
            }
        }
        catch (err) {
            console.log(err.response.data)
        }

    },

    getProducts: async () => {
        const { page, limit, } = get();
        try {
            set({ isLoading: true });

            const res = await shopModel.get(`/ExploreAll?page=${page}&limit=${limit}`);

            if (res.status === 200) {
                const newProducts = res.data;

                set((state) => ({
                    Products: [
                        ...state.Products,
                        ...newProducts.filter(
                            newProduct =>
                                !state.Products.some(existingProduct => existingProduct.id === newProduct.id)
                        ),
                    ],

                    isLoading: false,
                }));

            } else {
                console.error('Products not found');
                set({ isLoading: false });
            }
        } catch (err) {
            console.error(err.response?.data || err.message);
            set({ isLoading: false });
        }
    },

    getOneProduct: async (id) => {
        try {
            set({ isLoading: true });

            const res = await shopModel.post(`getOneProduct`, {
                id
            });

            if (res.status === 200) {
                const newProduct = res?.data[0];

                set({
                    Product: newProduct,
                    isLoading: false,
                });

            } else {
                console.error('Products not found');
                set({ isLoading: false });
            }
        } catch (err) {
            console.error(err.response?.data || err.message);
            set({ isLoading: false });
        }
    },

    getProductsByType: async (type, category) => {
        try {
            const res = await shopModel.post('/getProductbytype',
                {
                    type,
                    category
                }
            )
            if (res.status === 200) {
                const newProduct = res.data
                set({ Products: newProduct, isLoading: false })
            }

        }
        catch (err) {
            console.log(err.response?.data || err.message);
        }
    },

    getProductsTeaProducts: async () => {
        try {
            const res = await shopModel.post('/getProductbytype',
                {
                    type: "Tea",
                    category: ""
                }
            )
            if (res.status === 200) {
                const newProduct = res.data
                set({ TeaProducts: newProduct, isLoading: false })
            }

        }
        catch (err) {
            console.log(err.response?.data || err.message);
        }
    },

    setPage: () => {
        set((state) => ({
            page: state.page + 1,
        }));
    },

    setLoading: () => {
        set({ isLoading: true });
    },

    getTopProducts: async () => {
        try {
            const res = await shopModel.get(`/topProducts`);
            if (res.status == 200) {
                set({ Top_Products: res.data })
            }
        }
        catch (err) {
            console.error(err.response?.data || err.message);
        }
    },

    getAllOrders: async () => {
        try {
            const userDetails = getUserDetails()
            const res = await shopModel.post(`/getOrders`, {
                UID: userDetails?.UID
            });
            if (res.status == 200) {
                set({ Orders: res.data, isOrdersLoading: false })
            }
        }
        catch (err) {
            console.error(err.response?.data || err.message);
        }
    },

    getOneOrder: async (id) => {
        try {
            const res = await shopModel.post(`/getOneOrder`, {
                id
            });
            if (res.status == 200) {
                set({ Order: res.data, isOrderLoading: false })
            }
        }
        catch (err) {
            console.error(err.response?.data || err.message);
        }
    },

    setRecentSearches: (search) => {
        set((state) => {

            const exists = state?.RecentSearches?.some(item => item?.id === search[0]?.id);

            if (!exists) {

                const updatedSearches = [...search, ...state.RecentSearches];

                return { RecentSearches: updatedSearches };
            }

            return { RecentSearches: state?.RecentSearches };
        });
    },

    setRemoveRecentSearches: (id) => {
        set((state) => {
            const updatedSearches = state.RecentSearches.filter(item => item.id !== id);
            return { RecentSearches: updatedSearches };
        });
    },



}));

export default ShopStore;
