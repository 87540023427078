import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment, IconButton, Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Email from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Lock from '@mui/icons-material/Lock';
import LoginIcon from '@mui/icons-material/Login';
import { toast, Zoom } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import axiosInstance, { profileModel } from '../Axios/AxiosConfig';
import { setTokens } from './AuthUtil';
import OTPInput from 'react-otp-input';
import { Auth_URL } from '../Axios/config';

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [SignupMethod, setSignUpMethod] = useState('Email');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAutenicationModal, setIsAutenicationModal] = useState(false);
  const [isOTPModal, setOTPModal] = useState(false);
  const [OTPSession, SetOTPSession] = useState('');
  const [isPasswordModal, setPasswordModal] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isOTPloading, setOTPLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  function CheckEmailOrPhone(input) {
    if (!isNaN(input) && input.trim() !== '') {
      return "Phone";
    }
    return "Email";
  }

  function addCountryCodeIfNumber(input) {
    if (!isNaN(input) && input.trim() !== '') {
      return '+91' + input;
    }
    return input;
  }

  const validate = () => {
    let tempErrors = {};
    const emailRegex = /^\S+@\S+\.\S+$/;
    const phoneRegex = /^[0-9]{10}$/;
    if (!emailRegex.test(user) && !phoneRegex.test(user)) {
      tempErrors.emailOrPhone = 'Please enter a valid email address or phone number';
    }
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === '');
  }

  const handleSubmit = (LoginMethod) => {
    if (LoginMethod === "LoginWithPassword") {
      setPasswordModal(true);
      setError('');
    }
    else {
      handlePasswordlessLogin();
      setError('');
    }
  };



  const validateInput = () => {
    if (otp.length === 6) {
      return true;
    }
    else {
      setError('Invalid input. Please enter a valid 6 digit OTP');
      return false;
    }
  }


  const getProfile = async (id) => {
    try {
      let res = await profileModel.get(`/Profile/${id}`);
      if (res.status === 200) {
        const profile = res.data;
        // saveuserDetails(profile[0]?.firstname, profile[0]?.lastname, profile[0]?.email, profile[0]?.phone, id)
      }
    }
    catch (err) {
      console.log(err?.response?.data);
    }
  }

  const handleVerify = async () => {
    if (!validateInput()) return;

    try {
      let res = await axios.post(`${Auth_URL}verify-Passwordless-signin`, {
        user: addCountryCodeIfNumber(user),
        session: OTPSession,
        otp
      });

      if (res.status === 200) {
        const { accessToken, refreshToken, id } = res.data;
        setTokens(accessToken, refreshToken);
        getProfile(id);
        navigate('/');
      }
    }
    catch (err) {
      if (err.response.status === 500) {
        if (err?.response?.data?.error === "NotAuthorizedException") {
          toast.error("Please enter the correct OTP", {
            position: 'top-center',
            limit: 1,
            transition: Zoom,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          });
        }
        else {
          toast.error(err?.response?.data?.name, {
            position: 'top-center',
            limit: 1,
            transition: Zoom,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          });
          setError(err?.response?.data?.error);
        }
      }
    }
  }

  const handleVerifyAccount = async () => {
    try {
      let res = await axios.post(`${Auth_URL}VerfiyAccount`, {
        user: addCountryCodeIfNumber(user),
        code: otp,
        password
      });

      if (res.status == 200) {
        handleVerifyLogin()
        setIsAutenicationModal(false)
      }
    }

    catch (err) {
      if (err.response.status === 500) {
        setError(err?.response?.data?.error)
        console.log(err?.response?.data)
      }
    }
  }

  const toggleModal = () => {
    setIsAutenicationModal(!isAutenicationModal);
  };

  const handleResend = async () => {
    try {
      let res = await axios.post(`${Auth_URL}Resend-Confirmation-code`, {
        user: addCountryCodeIfNumber(user),
      });

      if (res.status === 200) {
        setIsWaiting(true);
        setTimer(30);
        setOtp('');
        setError('');
      }
    }
    catch (err) {
      if (err.response.status === 500) {
        setError(err?.response?.data?.error);
        console.log(err?.response?.data);
      }
    }
  }

  const handleLogin = async () => {
    try {
      let res = await axios.post(`${Auth_URL}Signin`, {
        email: addCountryCodeIfNumber(user),
        password
      });

      if (res.status === 200) {
        const { accessToken, refreshToken, id } = res.data;
        setTokens(accessToken, refreshToken);
        getProfile(id);
        navigate('/');
      }
    } catch (err) {
      if (err.response.status === 500) {
        setError(err?.response?.data?.error);
        if (err?.response?.data?.name === "NotAuthorizedException") {
          toast.error("Please enter the correct password.", {
            position: 'top-center',
            limit: 1,
            transition: Zoom,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          });
        }
        else {
          toast.error(err?.response?.data?.name, {
            position: 'top-center',
            limit: 1,
            transition: Zoom,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          });
        }
      }
    }
  };

  const handleVerifyLogin = async () => {
    try {
      let res = await axios.post(`${Auth_URL}Signin`, {
        email: addCountryCodeIfNumber(user),
        password
      });

      if (res.status === 200) {
        const { accessToken, refreshToken, id } = res.data;
        setTokens(accessToken, refreshToken);
        getProfile(id);
        navigate('/Profile')
      }
    } catch (err) {
      if (err.response.status === 500) {
        setError(err?.response?.data?.error);
        if (err?.response?.data?.name === "NotAuthorizedException") {
          toast.error("Please enter the correct password.", {
            position: 'top-center',
            limit: 1,
            transition: Zoom,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          });
        }
        else {
          toast.error(err?.response?.data?.name, {
            position: 'top-center',
            limit: 1,
            transition: Zoom,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          });
        }
      }
    }
  };

  const handlePasswordlessLogin = async () => {
    try {
      setOTPLoading(true);
      let res = await axios.post(`${Auth_URL}Passwordless-signin`, {
        user: addCountryCodeIfNumber(user),
      });

      if (res.status == 200) {
        SetOTPSession(res.data.session);
        setOTPLoading(false);
        setOTPModal(true);
        setIsWaiting(true);
        setTimer(30);
        setOtp('');
        setError('');
      }
    }
    catch (err) {
      if (err.response.status === 500) {
        if (err?.response?.data?.error === "NotAuthorizedException") {
          toast.error("Please enter the correct password.", {
            position: 'top-center',
            limit: 1,
            transition: Zoom,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          });
        }
        else {
          toast.error(err?.response?.data?.name, {
            position: 'top-center',
            limit: 1,
            transition: Zoom,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          });
          setError(err?.response?.data?.error);
        }
      }
    }
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrors({ ...errors, password: '' });
  };

  const handleEmailOrPhoneChange = (e) => {
    setUser(e.target.value);
    setErrors({ ...errors, emailOrPhone: '' });
  };

  const toggleModal1 = () => {
    setIsModalVisible(!isModalVisible);
  };

  const toggleModal2 = () => {
    setPasswordModal(!isPasswordModal);
  };

  const toggleModal3 = () => {
    setOTPModal(!isOTPModal);
  };

  useEffect(() => {
    let countdown;
    if (isWaiting && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0 && isWaiting) {
      setIsWaiting(false);
    }
    return () => clearInterval(countdown);
  }, [timer, isWaiting]);

  useEffect(() => {
    let ac = new AbortController();
    const timeout = setTimeout(() => ac.abort(), 30000);

    if (isOTPModal && 'OTPCredential' in window) {
      navigator.credentials
        .get({ otp: { transport: ['sms'] }, signal: ac.signal })
        .then((otpCredential) => {
          if (otpCredential) {
            setOtp(otpCredential.code);
            handleVerify();
          }
        })
        .catch((err) => console.log(`OTP Error: ${err}`));
    }

    return () => {
      clearTimeout(timeout);
      ac.abort();
    };
  }, [isOTPModal]);

  const validateuser = async (method) => {
    if (validate()) {
      try {
        let res = await axios.post(`${Auth_URL}validate-user`, {
          user: addCountryCodeIfNumber(user)
        })

        if (res.status === 200) {
          handleSubmit(method)
        }
      }
      catch (err) {
        toast.error(err?.response?.data?.error, {
          position: 'top-center',
          limit: 1,
          transition: Zoom,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        });

        if (err?.response?.data?.error === 'User Account not verified') {
          setIsAutenicationModal(true)
        }
      }
    }

  }

  useEffect(() => {
    if (otp.length === 6) {
      handleVerify();
    }
  }, [otp]);

  const maskFirstChars = (str, numChars) => {
    if (!str || str.length <= numChars) return '*'.repeat(str.length);
    return '*'.repeat(numChars) + str.slice(numChars);
  };

  return (
    <div className="SignUp-page">
      <div>
        <div className="Sigup-heading">
          <p>Login</p>
          <p>Welcome Back</p>
        </div>
        <div className="flex flex-col gap-5">
          <TextField
            value={user}
            onChange={handleEmailOrPhoneChange}
            placeholder={"Enter mobile number/ Mail id"}
            error={!!errors.emailOrPhone}

            style={{ width: "20rem" }}
            helperText={errors.emailOrPhone}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LoginIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="flex flex-col gap-5 Welcome-action-btn">
          <div>
            <Button
              style={{
                width: '100%',
                textTransform: 'none',
                borderRadius: '0',
                backgroundColor: '#DA6E0B',
                color: 'white',
                border: 'none',
                boxSizing: 'border-box',
                borderRadius: '5px',
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 500,
                padding: '10px'
              }}
              onClick={() => {
                validateuser('LoginWithPassword');
              }}
            >
              Login with Password
            </Button>
          </div>

          <div>
            <Button
              style={{
                width: '100%',
                textTransform: 'none',
                borderRadius: '0',
                border: '1px solid #DA6E0B ',
                color: '#DA6E0B',
                boxSizing: 'border-box',
                borderRadius: '5px',
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 500,
                padding: '10px'
              }}
              onClick={() => {
                validateuser('LoginWithOTP');
              }}
            >
              {"Login with OTP"}
            </Button>
          </div>

          <div className="flex flex-row justify-center items-center gap-5 Signuptermsandcondition">
            <p style={{ color: '#75623F' }}>If you didn't have an account</p>
            <p
              style={{
                fontWeight: '500',
                color: '#75623F',
                borderBottom: '1px solid #75623F',
                cursor: 'pointer'
              }}
              onClick={() => { navigate('/Signup'); }}
            >
              Sign Up
            </p>
          </div>
        </div>

        {/* Authentication Code Modal */}
        {isModalVisible && (
          <div className="modal-overlay" onClick={toggleModal1}>
            <div className="bottom-sheet" onClick={(e) => e.stopPropagation()}>
              <div className="handle"></div>
              <div className="bottom-sheet-content">
                <h2>Authentication Code</h2>
                <p>{CheckEmailOrPhone(user) === "Email" ?
                  `Enter the 6 digit code sent to your respective mail id ends with ${maskFirstChars(user, 6)}` :
                  `Enter the 6 digit code sent to your respective mobile number ${maskFirstChars(user, 6)}`}</p>
                <div className='login-otp-text-input'>
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}

                    inputStyle={{
                      border: "1px solid transparent",
                      borderRadius: "8px",
                      width: "54px",
                      height: "54px",
                      fontSize: "16px",
                      color: "#000",
                      fontWeight: "500",
                      outline: "1px solid silver",
                      caretColor: "blue"
                    }}
                    renderSeparator={<span style={{ visibility: "hidden" }}>--</span>}
                    renderInput={(props) => <input {...props} autoComplete="one-time-code" inputmode="numeric" />}
                  />
                  <div>
                    <p style={
                      isWaiting ? { visibility: 'visible' } : { visibility: "hidden" }
                    }>{timer} s</p>

                    <p onClick={handleResend}>Resend Code</p>
                  </div>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  <div>
                    <Button
                      style={{
                        width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: '5px',
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: '10px'
                      }}
                      onClick={handleVerify}
                    >
                      Verify Account
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}


        {isPasswordModal && (
          <div className="modal-overlay" onClick={toggleModal2}>
            <div className="bottom-sheet" onClick={(e) => e.stopPropagation()}>
              <div className="handle"></div>
              <div className="bottom-sheet-content">
                <h2>Enter Password</h2>
                <p>Please correctly enter the password that you used in sign up</p>
                <div className='login-otp-text-input'>
                  <div>
                    <TextField
                      style={{ margin: "1rem" }}
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Password"
                      type={showPassword ? 'text' : 'password'}
                      error={!!errors.password}
                      helperText={errors.password}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className='login-otp-text-input-div'>
                    <p style={
                      isWaiting ? { visibility: 'visible' } : { visibility: "hidden" }
                    }>{timer} s</p>

                    <p onClick={() => {
                      navigate(`/ForgotPassword/${user}`)
                    }}>Forgot Password</p>
                  </div>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  <div className='login-otp-text-input-div'>
                    <Button
                      style={{
                        width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: '5px',
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: '10px'
                      }}
                      onClick={handleLogin}
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* OTP Verification Modal */}
        {isOTPModal && (
          <div className="modal-overlay" onClick={toggleModal3}>
            <div className="bottom-sheet" onClick={(e) => e.stopPropagation()}>
              <div className="handle"></div>
              <div className="bottom-sheet-content">
                <h2>Authentication Code</h2>
                <p>{CheckEmailOrPhone(user) === "Email" ?
                  `Enter the 6 digit code sent to your respective mail id ends with ${maskFirstChars(user, 6)}` :
                  `Enter the 6 digit code sent to your respective mobile number ${maskFirstChars(user, 6)}`}</p>
                <div className='login-otp-text-input'>
                  <div className='login-otp-text-input-div'>

                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      inputStyle={{
                        border: "1px solid transparent",
                        borderRadius: "8px",
                        width: "54px",
                        height: "54px",
                        fontSize: "16px",
                        color: "#000",
                        fontWeight: "500",
                        outline: "1px solid silver",
                        caretColor: "blue"
                      }}
                      renderSeparator={<span style={{ visibility: "hidden" }}>--</span>}
                      renderInput={(props) => <input {...props} autoComplete="one-time-code" inputmode="numeric" />} // Added autoComplete
                    />

                  </div>

                  <div className='login-otp-text-input-div'>
                    <p style={
                      isWaiting ? { visibility: 'visible' } : { visibility: "hidden" }
                    }>{timer} s</p>

                    <p onClick={handlePasswordlessLogin}>Resend Code</p>
                  </div>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  <div className='login-otp-text-input-div'>
                    <Button
                      style={{
                        width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: '5px',
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: '10px'
                      }}
                      onClick={handleVerify}
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}


        {isAutenicationModal && (
          <div className="modal-overlay" onClick={toggleModal}>
            <div className="bottom-sheet" onClick={(e) => e.stopPropagation()}>
              <div className="handle"></div>
              <div className="bottom-sheet-content">
                <h2>Verify Account</h2>
                <p>{SignupMethod === "Email" ?
                  `Enter the 6 digit code send to your respective mail id ends with ${maskFirstChars(user, 6)}` :
                  `Enter the 6 digit code send to your respective mobile number ${maskFirstChars(user, 6)}`}</p>
                <div className='login-otp-text-input'>

                  <div>
                    <TextField
                      style={{ margin: "1rem" }}
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Password"
                      type={showPassword ? 'text' : 'password'}
                      error={!!errors.password}
                      helperText={errors.password}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className='login-otp-text-input-div'>
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      inputStyle={{
                        border: "1px solid transparent",
                        borderRadius: "8px",
                        width: "54px",
                        height: "54px",
                        fontSize: "16px",
                        color: "#000",
                        fontWeight: "500",
                        outline: "1px solid silver",
                        caretColor: "blue"
                      }}

                      renderSeparator={<span style={{ visibility: "hidden" }}>--</span>}
                      renderInput={(props) => <input {...props} inputmode="numeric" />}
                    />
                  </div>

                  <div className='login-otp-text-input-div'>
                    <p style={
                      isWaiting ? { visibility: 'visible' } : { visibility: "hidden" }
                    }>{timer} s</p>

                    <p onClick={handleResend}>Resend Code</p>
                  </div>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  <div className='login-otp-text-input-div'>
                    <Button
                      style={{
                        width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: '5px',
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: '10px'
                      }}
                      onClick={handleVerifyAccount}
                    >
                      Verify
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  );

}

export default Login;
