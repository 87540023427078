import React, { useState, useEffect } from 'react'
import { IconButton, Badge, Box, TextField } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getUserDetails } from '../Auth/AuthUtil';
import AppointmentStore from '../DataRepo/AppointmentStore';
import videoCallgrn from '../Assets/images/Video Call Orange Color.png'
import audiocallgrn from '../Assets/images/Call Icon Orange Color.png'
import calendaicon from '../Assets/images/Calender.png'
import clock from '../Assets/images/clock.png'
function Appointments() {

    const navigate = useNavigate('')
    const [value, setValue] = useState(0);
    const { Appointments, isAppointmentsLoading, getAppointments } = AppointmentStore()

    const userDetails = getUserDetails()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    useEffect(() => {
        getAppointments(userDetails?.UID)
    }, [])

    const filterAppointments = () => {
        switch (value) {
            case 0:
                return Appointments.filter(e => e.appointment_status === 'upcoming' ||  e.appointment_status === 'confirmed');
            case 1:
                return Appointments.filter(e => e.appointment_status === 'completed');
            case 2:
                return Appointments.filter(e => e.appointment_status === 'cancelled');
            default:
                return Appointments;
        }
    };

    const filteredAppointments = filterAppointments();

    console.log(filteredAppointments)

    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate('/TeleConsultation')}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>My Appointments</p>
            </div>
            <div>
            </div>
        </nav>
        <div className='All-appointment-main-container mt-5'>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                sx={{
                    '& .MuiTabs-indicator': {
                        display: 'none', // Hides the bottom indicator
                    },
                    '& .MuiTab-root': {
                        textTransform: 'none', // Optional: Keeps the text case as provided
                        padding: '1rem',
                        borderRadius: '4px',
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        border: '1px solid #ccc', // Border to make it look like a button
                        margin: '0 4px', // Adds space between the "buttons"
                        minWidth: 0,
                        minHeight: 0,
                        backgroundColor: '#F8F8F3',
                        color: "#A5A39D",
                        '&.Mui-selected': {
                            backgroundColor: '#75623F', // Selected tab color
                            color: '#FFFFFF', // Selected tab text color
                        },
                    },
                }}
            >
                <Tab label="Upcoming" />
                <Tab label="Completed" />
                <Tab label="Cancelled" />
            </Tabs>
            <div className='All-appointments-container'>
                {
                    filteredAppointments.sort((a, b) => {
                        const dateA = a?.timestamp ? new Date(Number(a?.timestamp)) : new Date(0); 
                        const dateB = b?.timestamp ? new Date(Number(b?.timestamp)) : new Date(0);
                        return dateB - dateA;
                      }).map((e, i) => {
                        return <div className='Appointments-container'
                            key={i}
                            onClick={() => {
                                navigate(`/Appointment?id=${e.Appointment_id}&doc_id=${e.doctor_uid}`)
                            }}>
                            <div>
                                <div>
                                    <img src={e?.doctor_photo}></img>
                                </div>
                                <div>
                                    <p>{e?.doctor_name}</p>
                                    <p>{e?.doctor_speciality}</p>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <img src={calendaicon} />
                                    <p>{e?.appointment_date?.split('-')?.reverse()?.join('-')}</p>
                                </div>
                                <div>
                                    <img src={clock} />
                                    <p>{e?.appointment_time}</p>
                                </div>
                                <div>
                                    <img src={e?.consultation_type === "Video" ? videoCallgrn : audiocallgrn} />
                                    <p>{e?.consultation_type} Consultation</p>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </div>
}

export default Appointments