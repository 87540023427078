import React from 'react';
import scrIconBlk from '../Assets/images/search icon-black.png';
import groupDoc from '../Assets/images/doctors icon in search.png';
import Myappointments from '../Assets/images/My Appointment.png';
import MyPrescrition from '../Assets/images/My Prescription.png';
import Myreports from '../Assets/images/My reports.png';
import leftvec from '../Assets/images/left vector.png';
import rightvec from '../Assets/images/right vector.png';
import Rating from '@mui/material/Rating';
import { useEffect, useState } from 'react';
import TeleConsultationStore from '../DataRepo/TeleConsultationStore';
import { Box, Skeleton, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

function Tele() {

    const testimonials = [
        {
            name: "Venkatesh Vedagiri",
            img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/TestimonialImages/venkatesh_photo.png",
            rating: 4.5,
            review: "I've struggled with sleep for four years, spending much on treatments that never worked. Since joining the wellness program, I now sleep over 8 hours thanks to the AyurAI health coach who really listened and provided a solution that worked. Thank you so much!"
        },
        {
            name: "Vikasini",
            img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/TestimonialImages/vikasini_photo.png",
            rating: 4.5,
            review: "I've been greatly benefitted after following the simple remedies and lifestyle practices advised by Doctor from Aiwell for intermittent dry cough and cramps."
        }
    ];

    const [search, setsearch] = useState('')

    const { Doctors, getDoctors, isDoctorsLoading } = TeleConsultationStore();

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);


    const navigate = useNavigate()

    useEffect(() => {
        if (!isHovered) {
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) =>
                    prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
                );
            }, 3000);

            return () => clearInterval(interval);
        }

    }, [isHovered]);

    useEffect(() => {
        getDoctors();
    }, []);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    return (
        <div className='Tele-consultation-main-page mb-10'>
            <div className='Tele-consultation-container'>
                <div>
                    <p>Hello,</p>
                    <p>Welcome Back!</p>
                </div>
                <div className="Tele-consultation-search-bar">
                    <img src={scrIconBlk} alt="Search" className="search-icon start-icon" />
                    <input type="text" placeholder="Search for doctors" className="search-input" onChange={(e) => {
                        setsearch(e?.target?.value)
                    }} />
                    <img src={groupDoc} alt="Doctors" className="search-icon end-icon" />
                </div>
            </div>
            <div className='Tele-consultation-section-page'>
                <div onClick={() => {
                    navigate('/Appointments')
                }}>
                    <img src={Myappointments} alt="My Appointments" />
                    <p>My Appointments</p>
                </div>
                <div onClick={() => {
                    navigate('/Reports')
                }}>
                    <img src={Myreports} alt="My Reports" />
                    <p>My Reports</p>
                </div>
                <div onClick={() => {
                    navigate('/Prescriptions')
                }}>
                    <img src={MyPrescrition} alt="My Prescriptions" />
                    <p>My Prescriptions</p>
                </div>
            </div>

            <div className='Tele-consultation-doctor-section mt-3'>
                <div>
                    <img src={leftvec} alt="Left Arrow" />
                    <p>Our Doctors</p>
                    <img src={rightvec} alt="Right Arrow" />
                </div>
                {
                    isDoctorsLoading ? <div className='Doctors-Container'>
                        <Box
                            display="flex"
                            alignItems="center"
                            padding="20px"

                            borderRadius="10px"
                            width="100%"

                        >
                            <Skeleton variant="rectangular" width={'10rem'} height={100} borderRadius="10px" />
                            <Box marginLeft="20px" width="100%">
                                <Skeleton variant="text" width="60%" height={30} />
                                <Skeleton variant="text" width="40%" height={20} />
                                <Box display="flex" alignItems="center" marginTop="10px">
                                    <Skeleton variant="circular" width={20} height={20} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            padding="20px"
                            backgroundColor="#f0f0f0"
                            borderRadius="10px"
                            width="100%"
                            maxWidth="500px"
                        >
                            <Skeleton variant="rectangular" width={'10rem'} height={100} borderRadius="10px" />
                            <Box marginLeft="20px" width="100%">
                                <Skeleton variant="text" width="60%" height={30} />
                                <Skeleton variant="text" width="40%" height={20} />
                                <Box display="flex" alignItems="center" marginTop="10px">
                                    <Skeleton variant="circular" width={20} height={20} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                </Box>
                            </Box>
                        </Box>
                    </div> :
                        <div className='Doctors-Container'>
                            {Doctors &&
                                Doctors?.filter((e) => {
                                    if (search === '') {
                                        return true;
                                    } else if (e.name.toLowerCase().includes(search.toLowerCase())) {
                                        return true;
                                    }
                                    return false;
                                }).map((e, index) => (
                                    <div key={index} style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            navigate(`/BookAppointment?id=${e.PID}`)
                                        }}>
                                        <div className='doc-img-card'>
                                            <img src={e?.img} alt={e?.name} />
                                        </div>
                                        <div>
                                            <p>{e?.name}</p>
                                            <p>{e?.speciality}</p>
                                            <p>{e?.course_details}</p>
                                            <Rating name="half-rating-read" value={Number(parseInt(e?.rating))} precision={0.5} readOnly />
                                            <div style={{ width: "100%" }}>
                                                <Button style={{
                                                    width: '100%',
                                                    textTransform: 'none',
                                                    backgroundColor: '#DA6E0B',
                                                    color: '#FDFDFD',
                                                    border: '1px solid #DA6E0B',
                                                    boxSizing: 'border-box',
                                                    paddingRight: "3rem",
                                                    paddingLeft: "3rem",
                                                    borderRadius: "5px",
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontWeight: 500,
                                                    padding: "5px"
                                                }}>
                                                    Book Now
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                        </div>
                }

            </div>

            <div className="testimonial-carousel-container mt-10">
                <p>&#x275D;</p>
                <div className="testimonial-carousel"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` ,cursor:"default"}}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    {testimonials.map((testimonial, index) => (
                        <div
                            key={index}
                            className="testimonial-card"
                        >
                            <div className="testimonial-content">
                                <p>{testimonial.review}</p>
                                <h3>{testimonial.name}</h3>
                            </div>
                            <img src={testimonial.img} alt={testimonial.name} className="testimonial-img" />
                        </div>
                    ))}
                </div>
                <p style={{ textAlign: "right" }}>&#x275E;</p>

            </div>

            <div className='Tele-consultation-end-section'>
                <p>200+ users <br />
                    have benefited <br />
                    from our health coach
                </p>
            </div>

        </div>
    );
}

export default Tele;
