import React from 'react'
import Lottie from 'lottie-react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import WelcomeScreenanimation from '../Assets/animations/welcomscreenrotation.json'
function WelcomeScreen() {
    const navigate = useNavigate()

    return <div className='Welcome-page-section'>
        <div className='Welcome-section'>
            <div className='Welcome-section-animation'>
                <Lottie animationData={WelcomeScreenanimation} />
            </div>
            
            <div>
                <p>Your wellness companion,<br />
                    integrating Ayurveda &<br /> Modern Science</p>
            </div>

            <div className='flex flex-col gap-2 Welcome-action-btn'>
                
                <div>
                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            backgroundColor: '#DA6E0B', /* Background color */
                            color: 'white', /* Text color for better contrast */
                            border: 'none', /* Remove default border */
                            boxSizing: 'border-box',
                            borderRadius: "5px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: "10px"
                        }}

                        onClick={() => {
                            navigate('/Onboarding')
                        }
                        }
                    >
                        Get Started
                    </Button>
                </div>

                <div>
                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            border: "1px solid #DA6E0B ",
                            color: '#DA6E0B', /* Text color for better contrast */
                            boxSizing: 'border-box',
                            borderRadius: "5px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: "10px"
                        }}
                        onClick={() => {
                            navigate('/Login')
                        }}
                    >
                        Already Have an Account
                    </Button>
                </div>

            </div>
        </div>
    </div>
}

export default WelcomeScreen