import React from 'react'
import DietPlanIcon from '../Assets/images/Diet Plan Icon.png'
import lifestyleicon from '../Assets/images/Lifestyle Practices Icon.png'
import TeleIcon from '../Assets/images/Teleconsultation Icon.png'
import ChatIcon from '../Assets/images/Chat Icon.png'
import consultationIcon from '../Assets/images/Group 8844.png'
import RecipesIcon from '../Assets/images/Recipes Icon.png'
import HerbalProductsIcon from '../Assets/images/Herbal Products Icon.png'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

function PackageListing() {

    const holistic_care = [{
        img: DietPlanIcon,
        name: "Diet Plans"
    }, {
        img: RecipesIcon,
        name: "Recipes"
    },
    {
        img: lifestyleicon,
        name: "Lifestyle Practices"
    }, {
        img: HerbalProductsIcon,
        name: "Herbal Products"
    }]

    const navigate = useNavigate()

    return (
        <div className='Packages-listing-page-section'>
            <div className='Packages-listing-section'>
                <div>
                    <p>Transform your wellness journey with <br /> expert guidance & holistic care</p>
                   
                    <div className="package-arrow mt-5 flex items-center justify-evenly">
                        <p>₹499</p>
                        <p><del>₹999</del></p>
                        <p>3months</p>
                    </div>

                    <div className='flex items-center p-5 m-2 mt-5 gap-5'
                        style={{ backgroundColor: "#F1EFEC", borderRadius: "5px" }}>
                        <img src={TeleIcon} style={{ width: "3rem" }}>
                        </img>
                        <p>Free Tele-Consultations </p>
                    </div>

                    <div className='holistic-care mt-5'>
                        <p>Expert Recommendations</p>
                        <div className='mt-2'>
                            {
                                holistic_care.map((e) => {
                                    return <div>
                                        <img src={e.img}></img>
                                        <p>{e.name}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <div className='mt-5'>
                        <Button
                            style={{
                                width: '100%',
                                textTransform: 'none',
                                borderRadius: '0',
                                backgroundColor: '#DA6E0B',
                                color: 'white',
                                border: 'none',
                                boxSizing: 'border-box',
                                borderRadius: "5px",
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: "10px"
                            }}

                            onClick={() => {
                                navigate('/Signup?Link=Wellness-Package')
                            }
                            }
                        >
                            Sign Up to Proceed
                        </Button>
                    </div>

                </div>
                <div className='Select-tele-section'>
                    
                    <img src={consultationIcon}></img>
                    
                    <p>If you are <span> not sure about <br />the Wellness Packages </span> we kindly <br />suggesting you to consult an <br />Ayurveda Physician </p>
                    
                    <div className="package-arrow mt-5 flex items-center justify-evenly">
                        <p>Just at ₹400/ 30 minutes</p>
                    </div>

                    <div className='mt-5' style={{ justifySelf: "flex-end", marginTop: "auto" }}>
                        <Button
                            style={{
                                width: '100%',
                                textTransform: 'none',
                                borderRadius: '0',
                                border: "1px solid #DA6E0B ",
                                color: '#DA6E0B',
                                boxSizing: 'border-box',
                                borderRadius: "5px",
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: "10px"
                            }}
                            onClick={() => {
                                navigate('/Signup?Link=Tele-Consultation')
                            }}
                        >
                            Consult Now
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PackageListing