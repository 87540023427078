import React, { useState, useEffect } from 'react';
import { IconButton, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import Carousel from 'react-bootstrap/Carousel';
import ShopStore from '../DataRepo/ShopStore';
import loadingimg from '../Assets/images/loading image 1.png'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CircularProgress } from '@mui/material';
import { Container, Title, Accordion, rem, createStyles } from '@mantine/core';
import ImageZoom from './ImageZoom';
import useCartStore from '../DataRepo/CartStore';
import { getUserDetails } from '../Auth/AuthUtil';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Badge from '@mui/material/Badge';
import addtocart from '../Assets/images/add_to _bag_brown.png'
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import WishlistStore from '../DataRepo/WishlistStore';


function ProductPage() {
    const navigate = useNavigate();
    const { getOneProduct, Product, isLoading } = ShopStore();

    const { addItem, cartItems, updateQuantity, decreaseQuantity } = useCartStore();
    const { addWishlist, WishlistItems, isWishlistLoading, removeWishlist, getWishlistItems } = WishlistStore()

    const userDetails = getUserDetails();
    const [selectVariant, setVariant] = useState(0)
    const location = useLocation();
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [backgroundPosition, setBackgroundPosition] = useState('0% 0%');

    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = ((e.pageX - left) / width) * 100;
        const y = ((e.pageY - top) / height) * 100;
        setBackgroundPosition(`${x}% ${y}%`);
    };

    const useStyles = createStyles((theme) => ({
        wrapper: {
            paddingTop: `calc(${theme.spacing.xl} * 2)`,
            paddingBottom: `calc(${theme.spacing.xl} * 2)`,
            minHeight: 650,
        },

        title: {
            marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
        },

        item: {
            borderRadius: theme.radius.md,
            marginBottom: theme.spacing.lg,
            border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
                }`,
        },
    }));


    const { classes } = useStyles();

    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css';
        document.head.appendChild(link);
        return () => {
            document.head.removeChild(link);
        };
    }, []);


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id') || '';
        getOneProduct(id);
    }, [location]);

    useEffect(() => {
        if (Product?.general?.images && Product?.general?.images?.length > 0) {
            setSelectedImageIndex(0); // Start with the first image
        } else {
            setSelectedImageIndex(-1); // Use imgurl if no images are available
        }
    }, [Product]);

    const detaildesc = Product?.general?.detaildesc || '';

    const hasNumberedPoints = /\d+\.\s+/.test(detaildesc);
    const hasSlashes = /\/\//.test(detaildesc);

    let content;

    if (hasSlashes) {
        const listItems = detaildesc.split('//').filter(Boolean).map((item, index) => (
            <li key={index}>
                {item.trim()}
            </li>
        ));

        content = (
            <ul style={{ listStyle: "disc", padding: "1rem", paddingTop: '5px' }}>
                {listItems}
            </ul>
        );
    } else if (hasNumberedPoints) {

        const listItems = detaildesc.split(/(\d+\.\s+)/).filter(Boolean).map((item, index, array) => {
            if (item.match(/^\d+\.\s+$/)) {
                return (
                    <li key={index}>
                        {array[index + 1] ? array[index + 1].trim() : ''}
                    </li>
                );
            }
            return null;
        }).filter(Boolean);

        content = (
            <ul style={{ listStyle: "disc", padding: "1rem", paddingTop: '5px' }}>
                {listItems}
            </ul>
        );
    } else {
        content = <p>{detaildesc}</p>;
    }


    const addtoCart = (item, id) => {

        const price = Product?.other_info?.variants && Product?.other_info?.variants ?
            parseInt(Product?.other_info?.variants?.[selectVariant]?.price) : parseInt(item?.price)

        const desc = Product?.other_info?.variants && Product?.other_info?.variants && Product?.other_info?.variants.length != 1 ?
            Product?.other_info?.variants?.[selectVariant]?.title : item?.desc

        const qty = Product?.other_info?.variants && Product?.other_info?.variants ?
            Product?.other_info?.variants?.[selectVariant]?.grams?.toString() : item?.qty

        let product = {
            id: id,
            productName: item?.name,
            quantity: 1,
            price,
            desc,
            companyid: item?.company_id,
            deliverycharges: item?.deliverycharges,
            src: item?.imgurl,
            actualQty: qty,
            sku: item?.sku,
            company: item?.company
        };

        addItem(product, userDetails?.UID)

        navigate('/Checkout')
    }

    const product_id = Product?.other_info?.variants && Product?.other_info?.variants ?
        Product?.other_info?.variants?.[selectVariant]?.id.toString() : Product?.id

    const existingItem = cartItems?.find(cartItem => cartItem?.id === product_id);

    return (
        <div className='All-products-section-main'>
            <nav className='flex flex-row p-2 items-center justify-between'>
                <div className='flex flex-row items-center'>
                    <IconButton onClick={() => navigate(-1)}>
                        <ChevronLeft />
                    </IconButton>
                    <p className='mb-0'>Product</p>
                </div>
                <div>
                    <Badge badgeContent={cartItems?.length}
                        onClick={() => {
                            navigate('/Checkout')
                        }}
                        boxSizing="10"
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: 'red',
                                color: 'red',
                            },
                        }}>
                        <div className='BagIcon'>
                            <img src={addtocart}></img>
                        </div>
                    </Badge>
                </div>
            </nav>

            {isLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                    <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                    </div>
                </div>
            </div>
                :
                <div className='Product-page-container'>
                    <div className='Product-page-img-container'>
                        {Product?.general?.images && Product?.general?.images.length > 0 ? (
                            <Carousel
                                activeIndex={selectedImageIndex}
                                onSelect={(index) => setSelectedImageIndex(index)}
                                interval={null}
                                controls={false}
                                indicators={false}
                            >
                                {Product?.general?.images?.map((image, index) => (
                                    <Carousel.Item key={index}>
                                        <ImageZoom imageUrl={image?.src}>

                                        </ImageZoom>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        ) : (
                            <ImageZoom imageUrl={Product?.general?.imgurl}>
                            </ImageZoom>
                        )}

                        {Product?.general?.images && Product?.general?.images.length > 0 && (
                            <div className="carousel-indicators-custom">
                                {Product?.general?.images.map((_, index) => (
                                    <span
                                        key={index}
                                        className={`indicator-dot ${selectedImageIndex === index ? 'active' : ''}`}
                                        onClick={() => setSelectedImageIndex(index)}
                                    ></span>
                                ))}
                            </div>
                        )}
                    </div>




                    <div className='Product-page-main-container'>
                        <div className='Product-general-info mb-0'>
                            <div className='flex flex-row justify-between items-center'>
                                
                                <div>
                                    <p className='product-title'>{Product?.general?.name}</p>
                                </div>

                                <div>
                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            {
                                                WishlistItems.some((item) => item.id === Product?.id) ? (
                                                    removeWishlist(Product?.id)
                                                ) : (
                                                    addWishlist(Product?.id)
                                                )
                                            }
                                        }}
                                    >
                                        {WishlistItems.some((item) => item.id === Product?.id) ? (
                                            <FavoriteIcon style={{
                                                color: "#DA6E0B"
                                            }} />
                                        ) : (
                                            <FavoriteBorderIcon />
                                        )}

                                    </IconButton>
                                </div>

                            </div>

                            <p className='mt-2 mb-0'>{Product?.general?.desc}</p>
                            {Product?.other_info?.variants && Product?.other_info?.variants.length != 0 ?
                                <p className='mt-2 mb-0'>QTY : <span>{Product?.other_info?.variants[selectVariant].grams}</span></p> :
                                <p className='mt-2 mb-0'>QTY : <span>{Product?.general?.qty}</span></p>
                            }

                            {Product?.other_info?.variants && Product?.other_info?.variants.length > 0 ? (
                                <div className="Product-section-price-details mt-3">
                                    {Product?.other_info?.variants[selectVariant]?.compare_at_price > 0 &&
                                        Product?.other_info?.variants[selectVariant]?.compare_at_price >
                                        Product?.other_info?.variants[selectVariant]?.price &&
                                        <p className="mb-0 mr-5" style={{
                                            fontWeight: 400, color: "#625E55", fontSize: "24px"
                                        }}>
                                            <del>
                                                ₹ {Product?.other_info?.variants[selectVariant]?.compare_at_price}
                                            </del>
                                        </p>}
                                    <p className="mb-0" style={{
                                        fontWeight: 500, color: "#3B362A", fontSize: "24px"
                                    }}>₹ {Product?.other_info?.variants[selectVariant]?.price}</p>
                                </div>
                            ) : (
                                <div className="Product-section-price-details mt-3">
                                    <p className="mb-0">
                                        <del>
                                            {Product?.general?.compare_at_price > 0 && `₹ ${Product?.general?.compare_at_price}`}
                                        </del>
                                    </p>
                                    <p className="mb-0">₹ {Product?.general?.price}</p>
                                </div>
                            )}

                        </div>

                        {
                            Product?.other_info.variants && Product.other_info.variants.length > 1 ? <div className='Product-general-info mb-0'>
                                <p>Variants</p>
                                <div className='variants-container'>
                                    {
                                        Product?.other_info?.variants?.map((e, i) => {

                                            return <div style={i === selectVariant ? { border: "2px solid #DA6E0B" } : {}} onClick={() => {
                                                setVariant(i)
                                            }}>
                                                <div>
                                                    <p>{e?.option1}</p>
                                                </div>
                                                <div>
                                                    <div className='Product-section-price-details mt-3'>
                                                        <p className='mb-0'><del>{e?.orgprice > 0 && `₹ ${e?.orgprice}`}</del></p>
                                                        <p className='mb-0'>₹ {e?.price}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                                : <></>}


                        <div className='Product-about-info mb-0'>
                            <p>About</p>
                            {Product?.html && <div className="about-html"
                                dangerouslySetInnerHTML={{ __html: Product?.html }}></div>}
                            <div className="about-html">
                                {content}
                            </div>
                        </div>

                        <div className='Product-other-info'>
                            {
                                Product?.keyingredients &&
                                <div>
                                    <p>Ingredients</p>
                                    <div className='ingredients'>
                                        {Product?.keyingredients &&
                                            Product?.keyingredients.map((ingredient, key) => {
                                                return <div >
                                                    <div key={key} className='ingredient'>
                                                        <img src={ingredient?.img}></img>
                                                        <p className='mb-0'>{ingredient?.name}</p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                Product?.keybenefits &&
                                <div>
                                    <p>Benefits</p>
                                    <div className='keybenefits-container'>
                                        {
                                            Product?.keybenefits.map((e, i) => {
                                                return <div className='keybenefits-card'>
                                                    <div>
                                                        <img src={e?.img}></img>
                                                    </div>
                                                    <div><p>{e?.name}</p></div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            }


                            {Product?.faq &&
                                <div>
                                    <p>Frequently Asked Questions</p>
                                    <Accordion variant="separated" id="Accordion" >
                                        {Product?.faq?.map((faq, index) => {
                                            return (
                                                <Accordion.Item style={{ backgroundColor: "#F1EFEC", color: "#7C7870" }} className={classes.item} key={index} value={faq.question}>
                                                    <Accordion.Control style={{ color: "#7C7870" }}>{faq?.question}</Accordion.Control>
                                                    <Accordion.Panel>{faq?.answer}</Accordion.Panel>
                                                </Accordion.Item>
                                            )
                                        })}
                                    </Accordion>
                                </div>
                            }

                        </div>

                        {
                            Product?.general?.onsale && <div className='d-flex justify-content-around align-items-center btm-action-btn'>

                                <div>
                                    <Button
                                        style={{
                                            width: '100%',
                                            textTransform: 'none',
                                            borderRadius: '0',
                                            backgroundColor: '#DA6E0B',
                                            color: 'white',
                                            border: 'none',
                                            boxSizing: 'border-box',
                                            borderRadius: '5px',
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 500,
                                            padding: '10px'
                                        }}

                                        onClick={() => {
                                            const id = Product?.other_info?.variants && Product?.other_info?.variants ?
                                                Product?.other_info?.variants?.[selectVariant]?.id.toString() : Product?.id
                                            addtoCart(Product?.general, id)
                                        }}
                                    >
                                        Buy now
                                    </Button>
                                </div>

                                <div>
                                    <Button
                                        
                                        style={{
                                            width: '100%',
                                            textTransform: 'none',
                                            borderRadius: '0',
                                            border: '1px solid #DA6E0B ',
                                            color: '#DA6E0B',
                                            boxSizing: 'border-box',
                                            backgroundColor: "#FDFDFD",
                                            borderRadius: '5px',
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 500,
                                            padding: '10px'
                                        }}

                                        onClick={(e) => {
                                            e.preventDefault()
                                            const id = Product?.other_info?.variants && Product?.other_info?.variants ?
                                                Product?.other_info?.variants?.[selectVariant]?.id.toString() : Product?.id
                                            existingItem ? updateQuantity(id, userDetails.UID) : addtoCart(Product?.general, id)
                                        }}
                                    >
                                        {existingItem ? (
                                            <div style={{ display: 'flex', alignItems: 'center', width: "10rem", justifyContent: "space-evenly" }}>
                                                <RemoveIcon style={{ cursor: 'pointer' }} onClick={(e) => {
                                                    e.stopPropagation();
                                                    const id = Product?.other_info?.variants && Product?.other_info?.variants ?
                                                        Product?.other_info?.variants?.[selectVariant]?.id.toString() : Product?.id
                                                    decreaseQuantity(id, userDetails?.UID);
                                                }}>-</RemoveIcon>
                                                <span style={{ fontSize: "16px" }}>{existingItem.quantity}</span>
                                                <AddIcon style={{ cursor: 'pointer' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        const id = Product?.other_info?.variants && Product?.other_info?.variants ?
                                                            Product?.other_info?.variants?.[selectVariant]?.id.toString() : Product?.id
                                                        updateQuantity(id, userDetails.UID)
                                                    }}>+</AddIcon>
                                            </div>
                                        ) : (
                                            "Add to Bag"
                                        )}
                                    </Button>

                                </div>
                                
                            </div>
                        }
                    </div>
                </div>
            }

        </div>
    );
}

export default ProductPage;
