import React from 'react'
import { Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogTitle, DialogActions } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import noReports from '../Assets/images/No report image.png'
import ReportStore from '../DataRepo/ReportStore';
import { CircularProgress } from '@mui/material';
import loadingimg from '../Assets/images/loading image 1.png'
import { getUserDetails } from '../Auth/AuthUtil';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Reports() {
    const [open, setOpen] = useState(false);
    const [reports, setReports] = useState([])
    const [openDialog, setOpenDialog] = useState(false); // Dialog state
    const [selectedReportId, setSelectedReportId] = useState(null); // Store the report ID for deletion

    const handleClickOpenDialog = (reportId) => {
        setSelectedReportId(reportId);
        setOpenDialog(true); // Open the confirmation dialog
    };

    const handleCloseDialog = () => {
        setOpenDialog(false); // Close the dialog without deleting
    };

    const confirmDelete = () => {
        handleDelete(selectedReportId); // Call delete handler with the selected report ID
        handleCloseDialog(); // Close the dialog after deletion
    };

    const { getReports, Reports, isReportsLoading, deletereport } = ReportStore()


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate()


    const handleDelete = async (id) => {
        deletereport(id)
    };

    useEffect(() => {
        getReports()
    }, [])

    const handleReportClick = (report) => {
        navigate(`/ViewReportList/${report?.id}`)
    };

    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Reports</p>
            </div>
        </nav>
        {
            isReportsLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                    <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                    </div>
                </div>
            </div> :
                <div className='Reports-main-page'>
                    {Reports.length > 0 ?
                        <>
                            <div className='ListReports'>
                                {Reports.map((e, i) => {
                                    return <div className="ListReportCard" onClick={() => handleReportClick(e)}>
                                        <IconButton style={{ alignSelf: "flex-start" }} onClick={(el) => {
                                            el.stopPropagation()
                                            handleClickOpenDialog(e?.ReportId);
                                        }
                                        }>
                                            <DeleteIcon fontSize='small' />
                                        </IconButton>
                                        <div>
                                            <p>{e.ReportType}</p>
                                            <p>{e.ReportUploadedby === getUserDetails().UID
                                                ? "Uploaded by you" : "Uploaded by patient"}</p>
                                            <p>{e.CreatedAt.split("GMT")[0]}</p>
                                        </div>
                                        <div>
                                            {e.filetype === 'images' ? (
                                                <img src='/icons/Image icon-1.png' alt='Image icon' />
                                            ) : (
                                                <img src='/icons/pdf icon.png' alt='PDF icon' />
                                            )}
                                        </div>
                                    </div>
                                })
                                }
                            </div>
                            <div className='uploadReportbtn flex itmes-center gap-5'>
                                <div>
                                    <Button
                                        style={{
                                            width: '100%',
                                            textTransform: 'none',
                                            borderRadius: '0',
                                            backgroundColor: '#DA6E0B',
                                            color: 'white',
                                            border: 'none',
                                            boxSizing: 'border-box',
                                            borderRadius: "5px",
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 500,
                                            padding: "10px"
                                        }}
                                        onClick={handleClickOpen}
                                    >
                                        Add a report
                                    </Button>
                                </div>

                                <div>
                                    <Button
                                        style={{
                                            width: '100%',
                                            textTransform: 'none',
                                            borderRadius: '0',
                                            border: '1px solid #DA6E0B ',
                                            color: '#DA6E0B',
                                            boxSizing: 'border-box',
                                            backgroundColor: "#FDFDFD",
                                            borderRadius: '5px',
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 500,
                                            padding: '10px'
                                        }}
                                        onClick={(e) => {
                                            navigate('/prakriti')
                                        }}
                                    >
                                        Skip to Prakriti
                                    </Button>
                                </div>

                            </div>
                        </>
                        :
                        <div className='No-reports'>
                            <img src={noReports}></img>
                            <p>No health reports found</p>
                            <p>Please upload the patient's report to assist us in maintaining accurate and comprehensive patient records.</p>
                           
                            <div className='uploadReportbtn flex itmes-center gap-5'>
                                <div>
                                    <Button
                                        style={{
                                            width: '100%',
                                            textTransform: 'none',
                                            borderRadius: '0',
                                            backgroundColor: '#DA6E0B',
                                            color: 'white',
                                            border: 'none',
                                            boxSizing: 'border-box',
                                            borderRadius: "5px",
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 500,
                                            padding: "10px"
                                        }}
                                        onClick={handleClickOpen}
                                    >
                                        Add a report
                                    </Button>
                                </div>

                                <div>
                                    <Button
                                        style={{
                                            width: '100%',
                                            textTransform: 'none',
                                            borderRadius: '0',
                                            border: '1px solid #DA6E0B ',
                                            color: '#DA6E0B',
                                            boxSizing: 'border-box',
                                            backgroundColor: "#FDFDFD",
                                            borderRadius: '5px',
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 500,
                                            padding: '10px'
                                        }}
                                        onClick={(e) => {
                                            navigate('/prakriti')
                                        }}
                                    >
                                        Skip to Prakriti
                                    </Button>
                                </div>

                            </div>
                        </div>
                    }

                    <Dialog

                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent id="dialog">
                            <div className='dialog-content'>
                                <p>Select your report type</p>
                                <div className='dialog-action-items'>

                                    <div>
                                        <Button
                                            style={{
                                                width: '100%',
                                                textTransform: 'none',
                                                borderRadius: '0',
                                                border: "1px solid #3B362A",
                                                backgroundColor: "#EFE3C2",
                                                color: '#3B362A',
                                                boxSizing: 'border-box',
                                                borderRadius: "5px",
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: 500,
                                                padding: "10px"
                                            }}

                                            onClick={() => {
                                                navigate(`/UploadReports/images`)
                                            }}
                                        >
                                            <div className='btn-content'>
                                                <img src='/icons/Image icon.png'></img>
                                                <p>Images</p>
                                            </div>
                                        </Button>
                                    </div>

                                    <div>
                                        <Button
                                            style={{
                                                width: '100%',
                                                textTransform: 'none',
                                                borderRadius: '0',
                                                border: "1px solid #3B362A",
                                                backgroundColor: "#EFE3C2",
                                                color: '#3B362A',
                                                boxSizing: 'border-box',
                                                borderRadius: "5px",
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: 500,
                                                padding: "10px"
                                            }}

                                            onClick={() => {
                                                navigate(`/UploadReports/Document`)
                                            }}
                                        >
                                            <div className='btn-content'>
                                                <img src='/icons/pdf icon.png'></img>
                                                <p>Document</p>
                                            </div>
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </DialogContent>

                    </Dialog>

                    <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Delete Report?"}</DialogTitle>
                        <DialogContent>
                            <p>Are you sure you want to delete this report?</p>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={confirmDelete} color="primary" autoFocus>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
        }

    </div>
}

export default Reports