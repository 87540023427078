import React, { useState, useEffect } from 'react';
import { IconButton, Badge, Box, TextField, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import useCartStore from '../DataRepo/CartStore';
import TeleConsultationStore from '../DataRepo/TeleConsultationStore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import calendaicon from '../Assets/images/Calender.png'
import clock from '../Assets/images/clock.png'
import offerimg from '../Assets/images/offer.png'
import appointmentconfetti from '../Assets/animations/appointmentconfetti.json'
import videocall from '../Assets/images/Video Call Orange Color.png'
import audiocall from '../Assets/images/Call Icon Orange Color.png'
import offerApplied from '../Assets/animations/offerapplied.json'
import Lottie from 'lottie-react';
import tickimg from '../Assets/images/Group 15940 (1).png'
import freetag from '../Assets/images/free-tag.png'
import { Button } from '@mui/material';
import { getUserDetails } from '../Auth/AuthUtil';
import axios from 'axios';
import { teleConsultationModel } from '../Axios/AxiosConfig';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { profileModel } from '../Axios/AxiosConfig';
import uploadReportsimg from '../Assets/images/Frame 427319952.png'
import pauymentsuccess from '../Assets/Raw/paymentsuccess_ding.mp3';
import success from '../Assets/animations/success lottie.json';
import Switch from '@mui/material/Switch';
import SubscriptionStore from '../DataRepo/SubscriptionStore';

function ConfirmSlot() {
    const { AppointmentDetails, setAppointmentDetails } = TeleConsultationStore();

    const { Subscriptiondetails, Subscriptiondetailsloading, getSubscriptiondetails, isSubscribed } = SubscriptionStore()

    const [checked, setChecked] = useState(false);

    const handleCheck = (event) => {
        setChecked(event.target.checked);

        if (event.target.checked) {
            setCoupon({
                couponName: "Free Consultation",
                discount: 100,
            })
        }

        else {
            setCoupon(null)
        }
    };

    const { cartItems, getCouponDetails, Coupons, CouponDetails, setCoupon } = useCartStore();

    const [hash, sethash] = useState('')

    const [msg, setmsg] = useState('na')

    const [coupon, setcoupon] = useState('')

    const [couponError, setCouponError] = useState('')

    const [Transcation_id, setTranscation] = useState('')

    const userDetails = getUserDetails()

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const [open2, setOpen2] = useState(false);

    const [open3, setOpen3] = useState(false);

    const [isprofileloading, setprofileloading] = useState(true)

    const [ProfileData, setProfileData] = useState([])

    const [errors, setErrors] = useState({
        email: '',
        phone: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setProfileData((prevState) => ({
            ...prevState,
            [name]: value
        }));


        setErrors((prevState) => ({
            ...prevState,
            [name]: ''
        }));
    };

    const validateInputs = () => {
        let isValid = true;
        const newErrors = {};

        if (!ProfileData.email) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(ProfileData.email)) {
            newErrors.email = 'Please enter a valid email';
            isValid = false;
        }

        if (!ProfileData.phone) {
            newErrors.phone = 'Phone number is required';
            isValid = false;
        } else if (!/^(?:\+91|91)?\d{10}$/.test(ProfileData.phone)) {
            newErrors.phone = 'Please enter a valid phone number';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const ringtone = new Audio(pauymentsuccess)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleClose3 = () => {
        setOpen3(false);
    };

    const getProfile = async (id) => {
        try {
            let res = await profileModel.get(`/Profile/${id}`)
            if (res.status === 200) {
                const profile = res?.data
                setProfileData(profile)
                setprofileloading(false)
            }
        }
        catch (err) {
            console.log(err?.response?.data)
        }
    }

    useEffect(() => {
        if (!AppointmentDetails) {
            navigate(-1)
        }
    }, [AppointmentDetails])

    useEffect(() => {
        getCouponDetails()
        getSubscriptiondetails()
        getProfile(userDetails?.UID)
    }, [])

    const handleCoupon = () => {
        let ValidCoupon = Coupons.find((e) => e?.couponName?.toLowerCase()?.trim() === coupon?.toLowerCase()?.trim());
        if (ValidCoupon) {
            setCoupon(ValidCoupon);
        } else {
            setCouponError("Please enter a valid coupon code");
        }
    };

    const Bookappointment = async () => {
        try {
            const res = await teleConsultationModel.post('/UpdateAppointment', {
                appointment_status: "upcoming",
                consultation_status: "pending",
                appointment_date: AppointmentDetails.selectedDay,
                consultation_type: AppointmentDetails.selectedMode,
                appointment_time: AppointmentDetails.selectedTime,
                history_appointment_time: AppointmentDetails.selectedTime,
                history_appointment_date: AppointmentDetails.selectedDay,
                doctor_id: AppointmentDetails.DoctorDetails.PID,
                Paid: ((price) - ((price / 100) * discount)),
                free_consultation: checked,
                doctor_uid: AppointmentDetails.DoctorDetails.UID,
                patient_id: userDetails?.UID,
                payment_status: "Paid",
                doctor_status: "pending",
            })

            if (res.status === 200) {
                handleClickOpen2()

                ringtone.play()

                setCoupon(null)

                // setTimeout(() => {
                //     navigate('/Appointments')
                // }, 2500)
            }
        }

        catch (err) {
            console.log(err.response)
        }
    }

    const checkout = () => {

        var data = {
            key: 'Ey2EbY',
            hash: hash,
            txnid: Transcation_id,
            amount: ((price) - ((price / 100) * discount)).toString(),
            firstname: ProfileData?.firstname,
            email: ProfileData?.email,
            phone: ProfileData?.phone || "123456790",
            productinfo: 'Aiwell Tele',
            surl: 'http://thirdparty.com/testresponse.php',
            furl: 'http://thirdparty.com/testresponse.php',
        };

        var handlers = {
            responseHandler: function (BOLT) {
                if (BOLT.response.txnStatus == "SUCCESS") {
                    Bookappointment()
                    console.log('Your payment has been successful');
                }
                if (BOLT.response.txnStatus == "FAILED") {
                    console.log('Payment failed. Please try again.');
                }
                if (BOLT.response.txnStatus == "CANCEL") {
                    console.log('Payment failed. Please try again.');
                }
            },
            catchException: function (BOLT) {
                console.log('Payment failed. Please try again.');
            }
        };


        window.bolt.launch(data, handlers);
    }

    const initiatePayment = async () => {
        if (ProfileData?.email != '' && ProfileData?.phone != '') {
            if (((price) - ((price / 100) * discount)) > 0) {
                try {
                    const response = await axios.post('https://api.ayurai.in/api/data/payment/makepayment',
                        {
                            amount: ((price) - ((price / 100) * discount)).toString(),
                            txnid: Date.now(),
                            phone: ProfileData?.phone || "123456790",
                            email: ProfileData?.email,
                            productinfo: "Aiwell Tele",
                            firstname: ProfileData?.firstname,
                        });

                    if (response.status === 200) {
                        sethash(response.data.hash)
                        setTranscation(response?.data?.TranscationID)
                        handleClickOpen()
                    }
                } catch (error) {
                    console?.error('Error initiating payment:', error?.response);
                }
            }
            else {
                Bookappointment()
            }
        }
        else {
            handleClickOpen3()
        }

    };

    const handleSaveProfile = async () => {
        if (validateInputs()) {
            try {
                let res = await profileModel.post(`/Profile`, { ...ProfileData });
                if (res.status === 200) {
                    handleClose3()
                    initiatePayment()
                }
            } catch (err) {
                console.log(err?.response?.data);
            }
        }
    };


    const price = parseInt(AppointmentDetails?.price);
    const discount = CouponDetails?.discount || 0;

    console.log(Subscriptiondetails)

    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Confirm Appointment</p>
            </div>
        </nav>

        <div className='Appointment-confirm-page'>
            <div className='Appointment-confirm-page-doctor-details'>
                <div>
                    <img src={AppointmentDetails?.DoctorDetails?.img}></img>
                    <div className='Appointment-confirm-page-doctor-info-details'>
                        <div>
                            <p>{AppointmentDetails?.DoctorDetails?.name}
                                &nbsp;
                                <span>{AppointmentDetails?.DoctorDetails?.course_details}</span>
                            </p>
                            <p>{AppointmentDetails?.DoctorDetails?.speciality}</p>
                        </div>

                        <div>
                            <p>Appointment details</p>
                            <div className='Appointment-selected-info'>
                                <div>
                                    <div className='icons'> <img src={calendaicon} ></img></div>
                                    <div><p>{AppointmentDetails?.selectedDay}</p></div>
                                </div>

                                <div>
                                    <div className='icons'>
                                        <img src={clock} ></img>
                                    </div>
                                    <div >
                                        <p>{AppointmentDetails?.selectedTime}</p>
                                    </div>

                                </div>
                                <div>
                                    <div className='icons'>
                                        <img src={AppointmentDetails?.selectedMode === 'Video' ? videocall : audiocall}></img>
                                    </div>
                                    <div>
                                        <p>{AppointmentDetails?.selectedMode}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='Appointment-confirm-page-info mb-10'>
                <div>
                    <p>Any Message for Doctor</p>
                    <div className='mt-3'>
                        <TextField
                            id="outlined-multiline-static"
                            label="Enter your message"
                            multiline
                            fullWidth
                            rows={4}
                            onChange={(e) => {
                                setmsg(e.target.value)
                            }}
                        />
                    </div>
                </div>

                {isSubscribed && Subscriptiondetails?.teleconsultationlimit > 0 &&
                    <div className='mt-5'>
                        <div style={{
                            backgroundColor: checked ? "#7FD67F" : "#D2D2D2",
                            borderRadius: "10px"
                        }}>
                            <div className='flex justify-between p-5'
                                style={{
                                    backgroundColor: checked ? "#DBF9DB" : "#EFEFE7",
                                    borderRadius: "10px"
                                }}
                            >
                                <div className='flex items-center gap-5 freetag'>
                                    <img src={freetag} />
                                    <div>
                                        <p>Enable your free consultation </p>
                                        <p>{Subscriptiondetails?.teleconsultationlimit - 1 + 1} out of 3 Free Tele-Consultations</p>
                                    </div>
                                </div>
                                <div>
                                    <Switch
                                        checked={checked}
                                        size='large'
                                        sx={{

                                            "& .MuiSwitch-switchBase.Mui-checked": {
                                                color: "#7FD67F", // Color of the switch when checked
                                            },
                                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                                backgroundColor: "#7FD67F", // Track color when checked
                                            },
                                            "& .MuiSwitch-track": {
                                                backgroundColor: "#bdbdbd", // Track color when unchecked
                                            },
                                        }}
                                        onChange={handleCheck}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </div>

                            </div>
                            <div className='p-5 text-center freetaginfo'>
                                <p>As part of your subscription, you are entitled to <span>
                                    3 free tele consultations</span>
                                </p>
                            </div>
                        </div>
                    </div>
                }

                {!CouponDetails &&
                    <div className="coupon-container mt-5">
                        <input type="text" placeholder="Enter your coupon code" className="coupon-input" onChange={(e) => {
                            setcoupon(e.target.value)
                            setCouponError('')
                        }} />
                        <button className="apply-button" onClick={handleCoupon} >Apply</button>
                    </div>
                }

                {CouponDetails &&
                    <div className="CouponAppliedContainer mt-5">
                        <div className="lottie-background">
                            <Lottie animationData={offerApplied} />
                        </div>
                        <div className="content">
                            <img src={offerimg} alt="Offer" />
                            <div>
                                <p>{CouponDetails?.couponName} Applied</p>
                                <p>You have saved ₹{(price / 100) * discount} by applying a {discount}% discount for this order</p>
                            </div>
                        </div>


                        <IconButton style={{ alignSelf: "flex-start", justifySelf: "flex-end", marginLeft: 'auto' }} onClick={() => {
                            setCoupon(null)
                            setChecked(false)
                        }}>
                            <HighlightOffIcon />
                        </IconButton>

                    </div>
                }

                {
                    couponError != '' &&
                    <p className='couponError'>{couponError}</p>
                }


                <div className='Cart-total-container mt-5 mb-5'>
                    <div>
                        <p>Consultation fees</p>
                    </div>
                    <div>
                        <div>
                            <p>Sub Total</p>
                            <p>{`₹ ${price}`}</p>
                        </div>

                        {
                            CouponDetails &&
                            <div>
                                <p>Coupon Discount</p>
                                <p>- ₹{(price / 100) * discount}</p>
                            </div>
                        }
                    </div>
                    <div>
                        <p>Total : <span>₹ {((price) - ((price / 100) * discount))}</span></p>
                    </div>
                </div>

            </div>
        </div>

        <div style={{ padding: "10px", position: "fixed", bottom: 0, width: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#fdfdfd" }}>
            <div>
                <Button
                    style={{
                        width: '100%',
                        textTransform: 'none',
                        backgroundColor: '#DA6E0B',
                        color: '#FDFDFD',
                        border: '1px solid #DA6E0B',
                        boxSizing: 'border-box',
                        paddingRight: "3rem",
                        paddingLeft: "3rem",
                        borderRadius: "5px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: "10px"
                    }}
                    onClick={() => initiatePayment()}
                >
                    Pay and Confirm
                </Button>
            </div>
        </div>

        <Dialog
            open={open3}
            onClose={handleClose3}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Contact details"}
            </DialogTitle>

            <DialogContent>

                <div className='mt-5'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Email"
                                variant="outlined"
                                fullWidth
                                name="email"
                                value={ProfileData.email}
                                onChange={handleChange}
                                error={!!errors.email}
                                helperText={errors.email}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Phone"
                                variant="outlined"
                                fullWidth
                                name="phone"
                                value={ProfileData.phone}
                                onChange={handleChange}
                                error={!!errors.phone}
                                helperText={errors.phone}
                            />
                        </Grid>
                    </Grid>
                </div>

                <p className='mt-2'
                    style={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 400,
                        fontSize: "12px"
                    }}
                >

                    Please fill in your email and phone number before continuing with the appointment.</p>

            </DialogContent>

            <DialogActions>
                <Button
                    style={{
                        textTransform: 'none',
                        backgroundColor: '#DA6E0B',
                        color: '#FDFDFD',
                        border: '1px solid #DA6E0B',
                        boxSizing: 'border-box',
                        borderRadius: "5px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: "10px"
                    }}

                    onClick={handleSaveProfile} autoFocus>
                    Save
                </Button>
            </DialogActions>

        </Dialog>

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Confirm Appointment"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to proceed to payment?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={checkout} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={open2}
            onClose={handleClose2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <div className="Lottie-background">
                    <Lottie animationData={appointmentconfetti}></Lottie>
                </div>
                <div className='flex flex-col items-center gap-5'>

                    <img src={tickimg} style={{ width: '5rem' }}>
                    </img>

                    <div className='uploadimgreportinfo text-center'>
                        <p >Your appointment with {AppointmentDetails?.DoctorDetails?.name} has been successfully booked</p>
                    </div>

                    <div className='text-center p-5 selectedDatetime'>
                        <p>On {AppointmentDetails?.selectedDay.split('-').reverse().join('-')}</p>
                        <p className='mt-1'>{AppointmentDetails?.selectedTime}</p>
                    </div>

                    <div className='flex p-3 gap-5 flex-col md:flex-row items-center' style={{ border: "1px dashed #75623F" }}>
                        <div className='uploadimgreport'>
                            <img src={uploadReportsimg} />
                        </div>
                        <div className='uploadimgreportinfo'>
                            <p>Upload Reports</p>
                            <p>Please upload relevant medical reports, lab tests, or treatment details for the doctor’s review</p>
                            <div>
                                <div className='flex flex-col md:flex-row gap-5 justify-content-around align-items-center mt-2'>
                                    <div style={{ width: '100%' }}>
                                        <Button
                                            style={{
                                                width: '100%',
                                                textTransform: 'none',
                                                borderRadius: '0',
                                                border: '1px solid #DA6E0B ',
                                                color: '#DA6E0B',
                                                boxSizing: 'border-box',
                                                backgroundColor: "#FDFDFD",
                                                borderRadius: '5px',
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: 500,
                                                padding: '10px'
                                            }}
                                            onClick={(e) => {
                                                navigate('/prakriti')
                                            }}
                                        >
                                            Skip to Prakriti
                                        </Button>
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <Button
                                            style={{
                                                width: '100%',
                                                textTransform: 'none',
                                                borderRadius: '0',
                                                backgroundColor: '#DA6E0B',
                                                color: 'white',
                                                border: 'none',
                                                boxSizing: 'border-box',
                                                borderRadius: '5px',
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: 500,
                                                padding: '10px'
                                            }}

                                            onClick={() => {
                                                navigate('/Reports')
                                            }}

                                        >
                                            Add Reports
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </DialogContent>
        </Dialog>

    </div>
}

export default ConfirmSlot