import create from 'zustand';
import { profileModel, reportsModel } from '../Axios/AxiosConfig';
import { getUserDetails } from '../Auth/AuthUtil';

const CreditsStore = create((set, get) => ({
    Credits:null,
    isCreditsLoading: true,
    CreditsHistory: [],
    isCreditsHistoryLoading: true,

    getCreditshistory: async (date, month) => {
        try {
            const userDetails = getUserDetails();

            const res = await profileModel.get(`/credits/dailyActivity/${userDetails?.UID}/`, {
                params: {
                    month: month || undefined,
                    date: date || undefined
                }
            })

            if (res?.status === 200) {
                set({
                    CreditsHistory: res?.data,
                    isCreditsHistoryLoading: false
                })
            }
        }
        catch (err) {
            set({
                CreditsHistory: [],
            })

            console.log(err?.response?.data)
        }
    },

    getCredits: async () => {
        try {
            const userDetails = getUserDetails();
            let res = await profileModel.get(`/credits/details/${userDetails?.UID}`)
            if (res.status === 200) {
                set({
                    Credits: res.data,
                    isCreditsLoading: false
                })
            }
        }
        catch (err) {
            console.log(err?.response?.data)
        }
    }
    
}))

export default CreditsStore
