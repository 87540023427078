import React from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import TeleConsultationStore from '../DataRepo/TeleConsultationStore';
import { useEffect, useState, useRef } from 'react';
import { Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import aiwelllogo from '../Assets/images/Aiwell Logo 1 1.png'
import { CircularProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import loadingimg from '../Assets/images/loading image 1.png';

function ViewPrescription() {
    const { getOnePrescription, PrescriptionOneData, isPrescriptionOneLoading } = TeleConsultationStore();
    const { id } = useParams();
    const navigate = useNavigate();
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleDownload = (s3Link) => {
        const link = document.createElement('a');
        link.href = s3Link;
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handlePlayPause = () => {
        const audio = audioRef.current;
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        getOnePrescription(id)
    }, []);


    console.log(PrescriptionOneData?.Practices)

    return (
        <div className='All-products-section-main'>

            <nav className='flex flex-row p-2 items-center justify-between'>
                <div className='flex flex-row items-center'>
                    <IconButton onClick={() => navigate(-1)}>
                        <ChevronLeft />
                    </IconButton>
                    <p className='mb-0'>Prescription</p>
                </div>
            </nav>

            <div className='Reports-main-page'>
                <div className='Reports-text-box'>
                    <div className='flex flex-row justify-between item-center m-2'>
                        <p>{PrescriptionOneData?.Date}</p>
                        <div>
                            <audio ref={audioRef}>
                                <source src={PrescriptionOneData?.Audio_Prescription} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                            <IconButton onClick={handlePlayPause} style={{ color: "#DA6E0B" }}>
                                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>
                            <p>Audio Prescription</p>
                        </div>
                    </div>

                    <div className='flex flex-row justify-center align-items-center' >
                        {isPrescriptionOneLoading ? (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                                <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                                    <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            // <iframe
                            //     src={`https://docs.google.com/gview?embedded=true&url=${selectedPrescription?.Prescription}`}
                            //     width="100%"
                            //     height="100%"
                            //     style={{ border: "none" }}
                            // ></iframe>

                            <div>
                                <div className="header">
                                    <img
                                        src={aiwelllogo}
                                        alt="Aiwell Logo"
                                    />
                                    <div className="prescription-doctor-details">
                                        <p>{PrescriptionOneData?.Doctor_details?.name} &nbsp;
                                            <span style={{ fontSize: "12px" }}>{PrescriptionOneData?.Doctor_details?.course_details}</span></p>
                                        <p style={{ fontSize: "12px" }}>
                                            Register No: {PrescriptionOneData?.Doctor_details?.regno || 'Aa27999'}</p>
                                        <p >{PrescriptionOneData?.Doctor_details?.speciality}</p>
                                        <p >Ayurvedic Physician</p>
                                    </div>
                                </div>

                                <div className="prescription-container">

                                    <div>
                                        <p className="Prescription-sh-h">Patient Details</p>
                                        <div className="Patient-details">
                                            <div>
                                                <p>Name:</p>
                                                <p>
                                                    {PrescriptionOneData?.Patient_details?.firstname}{" "}
                                                    {PrescriptionOneData?.Patient_details?.lastname}
                                                </p>
                                            </div>
                                            <div>
                                                <p>Age:</p>
                                                <p>{PrescriptionOneData?.Patient_details?.dob}</p>
                                            </div>
                                            <div>
                                                <p>Gender:</p>
                                                <p>{PrescriptionOneData?.Patient_details?.gender}</p>
                                            </div>
                                            <div>
                                                <p>Height:</p>
                                                <p>{PrescriptionOneData?.Patient_details?.height}</p>
                                            </div>
                                            <div>
                                                <p>Weight:</p>
                                                <p>{PrescriptionOneData?.Patient_details?.weight}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <p className="Prescription-sh-h">Medical Details</p>
                                        <div className="Medical-details">
                                            <div>
                                                <p>Complaints:</p>
                                                <p>
                                                    {PrescriptionOneData?.Medical_details?.Complaints}

                                                </p>
                                            </div>
                                            <div>
                                                <p>Examination / Lab findings:</p>
                                                <p>{PrescriptionOneData?.Medical_details?.lab}</p>
                                            </div>
                                            <div>
                                                <p>Medical History:</p>
                                                <p>{PrescriptionOneData?.Medical_details?.history}</p>
                                            </div>
                                            <div>
                                                <p>Diagnosis</p>
                                                <p>{PrescriptionOneData?.Medical_details?.diagnosis}</p>
                                            </div>
                                        </div>
                                        {/* <table>
                                            <thead>
                                                <tr>
                                                    <th>Complaints</th>
                                                    <th>Examination / Lab findings</th>
                                                    <th>Medical History</th>
                                                    <th>Diagnosis</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{PrescriptionOneData?.Medical_details?.Complaints}</td>
                                                    <td>{PrescriptionOneData?.Medical_details?.lab}</td>
                                                    <td>{PrescriptionOneData?.Medical_details?.history}</td>
                                                    <td>{PrescriptionOneData?.Medical_details?.diagnosis}</td>
                                                </tr>
                                            </tbody>
                                        </table> */}

                                    </div>

                                    {PrescriptionOneData?.Supplements &&
                                        <div>
                                            <p className="Prescription-sh-h">Medicines</p>
                                            <div className='Supplement-medicine-card-container'>
                                                {PrescriptionOneData?.Supplements &&
                                                    PrescriptionOneData?.Supplements.filter(e => e?.id !== '')?.map((e, i) => {
                                                        return <div className='Supplement-medicine-card' onClick={() => {
                                                            e.id != '' &&
                                                                navigate(`/Product?id=${e.id}`)
                                                        }}>
                                                            <div className='flex flex-row gap-10'>

                                                                <div className='Supplement-medicine-card-image'>
                                                                    {e.id != '' ?
                                                                        <img src={e?.imgurl} alt="Medicine Image" ></img>
                                                                        :
                                                                        <img src="https://aiwellassets.s3.ap-south-1.amazonaws.com/Product+place+holder.png" alt="Medicine Image" />
                                                                    }
                                                                </div>

                                                                <div className='Supplement-medicine-card-info'>
                                                                    <p>{e.medicine}</p>

                                                                    <div>
                                                                        <p><span>Dose</span>: {e?.dose}</p>
                                                                        <p><span>Order Quantity</span>: {e?.quantity}</p>
                                                                        <p><span>Duration</span> : {e?.duration}</p>
                                                                        <p><span>Instruction</span> : {e?.instruction}</p>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                            {e.id === '' && <p style={{ fontSize: "8px" }}>
                                                                This product is not available on our platform.</p>}

                                                        </div>
                                                    })
                                                }
                                            </div>


                                            <div className='flex flex-row items-center justify-center mt-5'>
                                                <div className='prescription-buy-now'>
                                                    <Button
                                                        style={{
                                                            width: '100%',
                                                            textTransform: 'none',
                                                            borderRadius: '0',
                                                            backgroundColor: '#DA6E0B',
                                                            color: 'white',
                                                            border: 'none',
                                                            boxSizing: 'border-box',
                                                            borderRadius: '5px',
                                                            fontFamily: "'Poppins', sans-serif",
                                                            fontWeight: 500,
                                                            padding: '10px'
                                                        }}

                                                        onClick={() => {
                                                            navigate('/Checkout')
                                                        }}
                                                    >
                                                        Buy now
                                                    </Button>
                                                </div>

                                            </div>

                                        </div>

                                    }

                                    {PrescriptionOneData?.Supplements &&
                                        PrescriptionOneData?.Supplements.some(e => e?.id === '') ?
                                        <div>
                                            <p className="Prescription-sh-h">Other Medicines</p>
                                            <div className='Supplement-medicine-card-container'>
                                                {PrescriptionOneData?.Supplements &&
                                                    PrescriptionOneData?.Supplements.filter(e => e?.id === '')?.map((e, i) => {
                                                        return <div className='Supplement-medicine-card' onClick={() => {
                                                            e.id != '' &&
                                                                navigate(`/Product?id=${e.id}`)
                                                        }}>
                                                            <div className='flex flex-row gap-10'>

                                                                <div className='Supplement-medicine-card-image'>
                                                                    {e.id != '' ?
                                                                        <img src={e?.imgurl} alt="Medicine Image" ></img>
                                                                        :
                                                                        <img src="https://aiwellassets.s3.ap-south-1.amazonaws.com/Product+place+holder.png" alt="Medicine Image" />
                                                                    }
                                                                </div>

                                                                <div className='Supplement-medicine-card-info'>
                                                                    <p>{e.medicine}</p>

                                                                    <div>
                                                                        <p><span>Dose</span>: {e?.dose}</p>
                                                                        <p><span>Order Quantity</span>: {e?.quantity}</p>
                                                                        <p><span>Duration</span> : {e?.duration}</p>
                                                                        <p><span>Instruction</span> : {e?.instruction}</p>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                            {e.id === '' && <p style={{ fontSize: "8px" }}>
                                                                This product is not available on our platform.</p>}

                                                        </div>
                                                    })
                                                }
                                            </div>

                                        </div> : <></>

                                    }

                                    {PrescriptionOneData?.Diet?.included?.length > 0 ||
                                        PrescriptionOneData?.Diet?.avoided?.length > 0 ? (
                                        <div className="after-page-break">
                                            <p className="Prescription-sh-h">Diet</p>
                                            <div className="container">

                                                {PrescriptionOneData?.Diet?.included?.length > 0 && (
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className="icon">✅</div>
                                                            <p>Food to be Included</p>
                                                        </div>
                                                        <div className="card-content">
                                                            {PrescriptionOneData?.Diet?.included?.map(
                                                                (e, i) => (
                                                                    <div key={i}>
                                                                        <img
                                                                            src={e?.imageLink}
                                                                            alt={e?.englishName}
                                                                        />
                                                                        <p>{e?.englishName}</p>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                )}

                                                {PrescriptionOneData?.Diet?.avoided?.length > 0 && (
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className="icon">❌</div>
                                                            <p>Food to be Avoided</p>
                                                        </div>
                                                        <div className="card-content">
                                                            {PrescriptionOneData?.Diet?.avoided?.map(
                                                                (e, i) => (
                                                                    <div key={i}>
                                                                        <img
                                                                            src={e?.imageLink}
                                                                            alt={e?.englishName}
                                                                        />
                                                                        <p>{e?.englishName}</p>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : null}

                                    {PrescriptionOneData?.Recipes?.length > 0 && (
                                        <div className="after-page-break">
                                            <p className="Prescription-sh-h">Recipes</p>
                                            <div className="item-container">
                                                {PrescriptionOneData?.Recipes?.map((e, i) => (
                                                    <div key={i} className="item">
                                                        <img
                                                            src={e.imageLink}
                                                            alt={e.recipe}
                                                        />
                                                        <p>{e.recipe}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {PrescriptionOneData?.Practices && (
                                        <div className="after-page-break">
                                            <p className="Prescription-sh-h">Lifestyle Practices</p>
                                            <div className="lifestyle-container">
                                                {PrescriptionOneData?.Practices?.yoga?.length > 0 && (
                                                    <div className="lifestyle-card">
                                                        <div className="lifestyle-card-header">Yoga</div>
                                                        <div className="lifestyle-card-content">
                                                            <ul>
                                                                {PrescriptionOneData?.Practices?.yoga?.map((e, i) => (
                                                                    <li key={i} onClick={() => {
                                                                        navigate(`/Yoga?id=${e?.id}`)
                                                                    }}>

                                                                        {e.title}

                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}

                                                {PrescriptionOneData?.Practices?.meditation?.length > 0 && (
                                                    <div className="lifestyle-card">
                                                        <div className="lifestyle-card-header">Meditation</div>
                                                        <div className="lifestyle-card-content">
                                                            <ul>
                                                                {PrescriptionOneData?.Practices?.meditation?.map((e, i) => (
                                                                    <li key={i} onClick={() => {
                                                                        navigate(`/Meditation?id=${e?.Id}`)
                                                                    }}>

                                                                        {e.title}

                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}

                                                {PrescriptionOneData?.Practices?.pranayama?.length > 0 && (
                                                    <div className="lifestyle-card">
                                                        <div className="lifestyle-card-header">Pranayama</div>
                                                        <div className="lifestyle-card-content">
                                                            <ul>
                                                                {PrescriptionOneData?.Practices?.pranayama?.map((e, i) => (
                                                                    <li key={i} onClick={() => {
                                                                        navigate(`/Pranayama?id=${e?.id}`)
                                                                    }}>
                                                                        {e.title}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {PrescriptionOneData?.LifeStyle_changes?.length > 0 && (
                                        <div className="after-page-break">
                                            <p className="Prescription-sh-h">LifeStyle Changes</p>
                                            <div className="LifeStyle-changes-Container">
                                                <ul>
                                                    {PrescriptionOneData?.LifeStyle_changes.map((e, i) => (
                                                        <li key={i}>{e}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}

                                    <div className='flex flex-row items-center justify-center mt-10 mb-10'>
                                        <div className='prescription-buy-now'>
                                            <Button
                                                style={{
                                                    width: '100%',
                                                    textTransform: 'none',
                                                    borderRadius: '0',
                                                    backgroundColor: '#DA6E0B',
                                                    color: 'white',
                                                    border: 'none',
                                                    boxSizing: 'border-box',
                                                    borderRadius: '5px',
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontWeight: 500,
                                                    padding: '10px'
                                                }}

                                                onClick={() => handleDownload(PrescriptionOneData?.Prescription)}
                                            >
                                                <DownloadIcon />&nbsp; Download Prescription
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewPrescription;
