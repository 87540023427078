import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import WishlistStore from '../DataRepo/WishlistStore';
import { CircularProgress } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { IconButton, Button } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import loadingimg from '../Assets/images/loading image 1.png'
import nofav from '../Assets/images/No favourite vector.png'

function Wishlist() {
    const navigate = useNavigate()
    const { addWishlist, WishlistItems, isWishlistLoading, removeWishlist, getWishlistItems } = WishlistStore()

    useEffect(() => {
        getWishlistItems()
    }, [])

    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2'>
            <IconButton onClick={() => navigate(-1)}>
                <ChevronLeft />
            </IconButton>
            <p className='mb-0'>Wishlist</p>
        </nav>
        {isWishlistLoading ?
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                    <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                    </div>
                </div>
            </div> :
            WishlistItems?.length > 0 ?
                <div className='wishlist-section'>{
                    WishlistItems?.map((e, i) => (
                        <div key={i} className='Product-section-card' onClick={() => navigate(`/Product?id=${e.id}`)}>
                            <div className="Product-card-header">
                                <div className="Product-discount-badge">
                                    {e.orgprice > 0 && e.orgprice != e.price ? (
                                        <p>
                                            {(((parseFloat(e.orgprice) - parseFloat(e.price)) / parseFloat(e.orgprice)) * 100).toFixed(0) + "% off"}
                                        </p>
                                    ) : null}
                                </div>
                                <div className="Product-wishlist-icon">
                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation();

                                            removeWishlist(e.id)

                                        }}
                                    >
                                        <HighlightOffIcon />
                                    </IconButton>
                                </div>
                            </div>

                            <div>
                                <img src={e.imgurl} alt={e.name} />
                            </div>

                            <div className='Product-section-card-title'>
                                <p>{e?.name}</p>
                                <p>{e?.desc}</p>
                            </div>

                            <div className='Addtobagbutton'>
                                <div className='Product-section-price-details'>
                                    <p><del>{e?.orgprice > 0 && e?.orgprice != e?.price ? `₹ ${e?.orgprice}` : ""}</del></p>
                                    <p>₹ {e?.price}</p>
                                </div>
                                <div className='w-100'>
                                    <Button
                                        style={{
                                            width: '100%',
                                            textTransform: 'none',
                                            borderRadius: '0',
                                            backgroundColor: '#75623F',
                                            color: 'white',
                                            border: 'none',
                                            boxSizing: 'border-box',
                                            borderRadius: "5px",
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 500,
                                            padding: "10px"
                                        }}
                                        onClick={() => {
                                            // navigate('/SignUp')
                                        }}
                                    >
                                        <div className='addtobagctn'>
                                            <p>{e.onsale === true ? 'Add to bag' : "Coming Soon"}</p>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))
                }
                </div>
                : <div className='EmptyCart'>
                    <img src={nofav}></img>
                    <p>Your Wishlist is Empty.</p>
                    <p>"Boost your wellness—shop your favorites now!"</p>
                    <div>
                        <Button
                            style={{
                                width: '100%',
                                textTransform: 'none',
                                backgroundColor: '#FDFDFD',
                                color: '#DA6E0B',
                                border: '1px solid #DA6E0B',
                                boxSizing: 'border-box',
                                borderRadius: "5px",
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: "10px"
                            }}
                            onClick={() => navigate('/Shop')}
                        >
                            View Products
                        </Button>
                    </div>
                </div>
        }

    </div>
}

export default Wishlist