import React, { useState, useEffect } from 'react';
import WellnessStore from '../DataRepo/WellnessStore';
import { IconButton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress } from '@mui/material';
import loadingimg from '../Assets/images/loading image 1.png'
import yogalandingimg from '../Assets/images/pranayama 1.png'

function PranayamaPage() {
    const navigate = useNavigate()
    const { getPranayama, selectedPranayama, unselectedPranayama, isPranayamaLoading } = WellnessStore();

    useEffect(() => {
        getPranayama()
    }, [])

    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Pranayama</p>
            </div>
        </nav>
        {
            isPranayamaLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                    <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                    </div>
                </div>
            </div>
                : <div className='Yoga-page-section'>
                    <div >
                        <div>
                            <img src={yogalandingimg}></img>
                        </div>
                        <div>
                            <p>Pranayama</p>
                            <p>“Unites body and mind through the breath”</p>
                        </div>
                    </div>
                    <div className='Yoga-content-section'>
                        {
                            selectedPranayama?.length &&
                            <div>
                                <p>Recommended for you</p>
                                <div className='mt-2 Yogalist-container'>
                                    {
                                        selectedPranayama.map((e, i) => {
                                            return <div className='yogacard' onClick={()=>{
                                                navigate(`/Pranayama?id=${e?.id}`)
                                            }}>
                                                <div>
                                                    <p>{e?.title}</p>
                                                    <p>{e?.subtitle}</p>
                                                </div>
                                                <div>
                                                    <img src={e?.image} />
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        }
                        {selectedPranayama?.length &&
                            <div className='mt-3'>
                                <p>Explore other Pranayama</p>
                                <div className='mt-2 Yogalist-container'>
                                    {
                                        unselectedPranayama.map((e, i) => {
                                            return <div className='yogacard' onClick={()=>{
                                                navigate(`/Pranayama?id=${e?.id}`)
                                            }}>
                                                <div>
                                                    <p>{e?.title}</p>
                                                    <p>{e?.subtitle}</p>
                                                </div>
                                                <div>
                                                    <img src={e?.image} />
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>}
                    </div>
                </div>
        }
    </div>
}

export default PranayamaPage