import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ShopStore from '../DataRepo/ShopStore';
import { CircularProgress, Button } from '@mui/material';
import loadingimg from '../Assets/images/loading image 1.png';
import Orderdeliveredicon from '../Assets/images/order_delivered.png';
import Ordercancancelledicon from '../Assets/images/order_cancelled.png';
import Orderplacedicon from '../Assets/images/order_in_process (1).png';
import emptyCart from '../Assets/images/no order.png';

function Orders() {
    const { getAllOrders, Orders, isOrdersLoading } = ShopStore();

    const navigate = useNavigate();

    useEffect(() => {
        getAllOrders();
    }, []);

    if (isOrdersLoading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                    <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                    </div>
                </div>
            </div>
        );
    }

    if (Orders?.length === 0) {
        return (
            <div className='EmptyCart'>
                <img src={emptyCart} alt="Empty Cart" />
                <p>No orders placed</p>
                <p>"Enhance your well-being—shop  today!"</p>
                <div>
                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            backgroundColor: '#FDFDFD',
                            color: '#DA6E0B',
                            border: '1px solid #DA6E0B',
                            boxSizing: 'border-box',
                            borderRadius: "5px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: "10px"
                        }}
                        onClick={() => navigate('/Shop')}
                    >
                        View Products
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className='Orders-all-section'>
            {Orders.sort((a, b) => {
                const dateA = new Date(a?.Data?.dateandtime);
                const dateB = new Date(b?.Data?.dateandtime);
                return dateA - dateB;
            }).map((e, i) => (
                <div key={i} onClick={() => navigate(`/Order?id=${e?.id}`)}>
                    <div>
                        <div>
                            <div>
                                <img src={
                                    e?.Data?.OrderStatus === 'delivered' ? Orderdeliveredicon :
                                        e?.Data?.OrderStatus === 'cancelled' ? Ordercancancelledicon :
                                            Orderplacedicon
                                } alt={e?.Data?.OrderStatus} />
                            </div>
                            <p style={{
                                color: `${e?.Data?.OrderStatus === 'delivered' ? '#7FD67F' :
                                    e?.Data?.OrderStatus === 'cancelled' ? "#F15050" : '#DA6E0B'}`
                            }}>
                                {e?.Data?.OrderStatus.charAt(0).toUpperCase() + e?.Data?.OrderStatus.slice(1)}
                            </p>
                        </div>
                        <div>
                            <p>Order id : <span>{e?.Data?.Orderid}</span></p>
                            <p className='mt-2'>Ordered On : <span>{e?.Data?.dateandtime}</span></p>
                        </div>
                    </div>

                    <div>
                        {e?.Data?.cartitems.map((s, index) => (
                            <div key={index}>
                                <img src={s?.image} alt={`Item ${index + 1}`} />
                            </div>
                        ))}
                    </div>

                    <div>
                        <p>Total Purchase  : ₹ <span>{parseInt(e?.Data?.total) + parseInt(e?.Data?.delivery_charges)}</span></p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Orders;
