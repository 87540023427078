import React, { useState, useEffect } from 'react';
import WellnessStore from '../DataRepo/WellnessStore';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress } from '@mui/material';
import meditationLandingimg from '../Assets/images/meditation.png'
import loadingimg from '../Assets/images/loading image 1.png'


function MeditationPage() {

    const { isMeditationdataLoading, getMedidationData, Meditationdata } = WellnessStore();


    const navigate = useNavigate();

    useEffect(() => {
        getMedidationData()
    }, [])


return <div className='All-products-section-main'>
<nav className='flex flex-row p-2 items-center justify-between'>
    <div className='flex flex-row items-center'>
        <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
        </IconButton>
        <p className='mb-0'>Meditation</p>
    </div>
</nav>

{isMeditationdataLoading ?
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
        <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
            <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
            <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
            </div>
        </div>
    </div>
    : <div className='Meditation_page'>
        <div >
            <div>
                <img src={meditationLandingimg}></img>
            </div>
            <div>
                <p>Meditation</p>
                <p>“Art of finding peace within oneself, where the mind and spirit unite in tranquility”</p>
            </div>
        </div>
        
        <div>
            {
                Meditationdata.map((e, i) => {
                    return <div className='Meditation-Card' key={i} onClick={()=>{
                        navigate(`/Meditation?id=${e.Id}`)
                    }}>
                        <p>{e?.title}</p>
                        <img src={e?.image}></img>
                    </div>
                })
            }
        </div>
    </div>
}
</div>
}

export default MeditationPage