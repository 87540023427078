import React, { useState, useEffect } from 'react';
import { CircularProgress, Button, Card, Divider, IconButton, Modal, Typography, Grid, Box, Slider, Fade } from '@mui/material';
import { ArrowDropDown, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProgressBar from "react-customizable-progressbar";
import { getUserDetails } from '../Auth/AuthUtil';
import Dialog from '@mui/material/Dialog';
import { profileModel } from '../Axios/AxiosConfig';
import DialogTitle from '@mui/material/DialogTitle';

const StrengthSlider = ({ dietList }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % dietList.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [dietList]);

    const currentDiet = dietList[currentIndex];

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div className='Strength-page'>
            <div className='flex flex-row justify-between items-center Strength-page-title'>
                <p>STRENGTH</p>
                <IconButton onClick={handleOpen}>
                    <KeyboardArrowDownOutlinedIcon />
                </IconButton>
            </div>
            <div className='Strength-card'>
                <div>
                    <img src={currentDiet?.imgsrc} alt={currentDiet?.name} />
                </div>
                <div>
                    <p>{currentDiet?.name}</p>
                </div>
            </div>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <div className='Strength-popup'>
                    <p>Strength</p>
                    <div >
                        {dietList.map((diet, index) => (
                            <div key={index} className='Strength-popup-item flex flex-col items-center justify-center'>
                                <div>
                                    <img src={diet.imgsrc} alt={diet.name} />
                                </div>
                                <div><p>{diet?.name}</p></div>
                            </div>
                        ))}
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

const WeaknessSlider = ({ dietList }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % dietList.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [dietList]);

    const currentDiet = dietList[currentIndex];
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [open, setOpen] = useState(false);

    return (
        <div className='Strength-page' >
            <div className='flex flex-row justify-between items-center Strength-page-title'>
                <p>WEAKNESS</p>
                <IconButton onClick={handleOpen}><KeyboardArrowDownOutlinedIcon /></IconButton>
            </div>
            <div className='Strength-card'>
                <div>
                    <img src={currentDiet?.imgsrc} alt={currentDiet?.name} />
                </div>
                <div >
                    <p>{currentDiet?.name}</p>
                </div>
            </div>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <div className='Strength-popup'>
                    <p>Weakness</p>
                    <div >
                        {dietList.map((diet, index) => (
                            <div key={index} className='Strength-popup-item flex flex-col items-center justify-center'>
                                <div><img src={diet.imgsrc} alt={diet.name} /></div>
                                <div><p>{diet.name}</p></div>
                            </div>
                        ))}
                    </div>
                </div>
            </Dialog>

        </div>
    );
};

const BulletTextGrid = ({ textList }) => (
    <div className='Susceptible_disorders'>
        <div>
            <p>Susceptible disorders</p>
        </div>
        <ul className='m-5'>
            {textList.map((text, index) => (
                <li>{text}</li>
            ))
            }
        </ul>
    </div>
);

const PrakritiResult = ({ prakritiState, prakritiResultState }) => {
    const [showButton, setShowButton] = useState(false);
    const [strengthSheetOpen, setStrengthSheetOpen] = useState(false);
    const [weaknessSheetOpen, setWeaknessSheetOpen] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [prakritiData, setPrakritiData] = useState([])
    const [prakritiresultData, setPrakritiResultData] = useState([])
    const navigate = useNavigate();
    const userDetails = getUserDetails();

    const fetchPrakritiResult = async (result) => {
        try {
            let res = await profileModel.post(`/fetchPrakritiResult`, {
                result
            });

            if (res.status === 200) {
                setPrakritiResultData(res.data)
                setLoading(false)
            }

        } catch (err) {
            console.log(err?.response?.data);
        }
    }

    const getPrakritiData = async () => {
        try {
            let res = await profileModel.get(`/GetPrakritidata/${userDetails.UID}`);
            if (res.status === 200) {
                fetchPrakritiResult(res.data.result)
                setPrakritiData(res.data)
            }
        } catch (err) {
            console.log(err?.response?.data);
        }
    };

    useEffect(() => {
        getPrakritiData()
    }, []);

    const handleScroll = (event) => {
        setShowButton(event.target.scrollTop > 100);
    };

    const findDominantDosha = (prakritiState) => {
        if (prakritiState) {
            if (prakritiState.vata >= Math.max(prakritiState.pitta, prakritiState.kapha)) {
                return ['Vata', "Dominant", `${prakritiState.vata.toFixed(0)}%`];
            } else if (prakritiState.pitta >= Math.max(prakritiState.vata, prakritiState.kapha)) {
                return ['Pitta', "Dominant", `${prakritiState.pitta.toFixed(0)}%`];
            } else {
                return ['Kapha', "Dominant", `${prakritiState.kapha.toFixed(0)}%`];
            }
        }
        return ['', "Dominant", , 0];
    };


    return <div className='Wellness-Info-section flex flex-col justify-center align-center'>

        {isLoading ? (
            <div className='Prakriti-container flex flex-col items-center justify-center' style={{ height: "30rem", justifySelf: "center" }}>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress style={{ color: "#DA6E0B" }} />
                </Box>
            </div>
        ) :
            (<div className='Prakriti-result-container mt-3'>
                <div className='Prakriti-result-section'>
                    <div className='Prakriti-result-item'>
                        <p>Your unique Phenotype</p>
                        <div className="circleIndicatorContainer">
                            <div style={{ position: 'relative', width: 250, height: 250 }}>
                                <CircularProgressbar
                                    value={prakritiData?.vata}
                                    className="first-circle"
                                    strokeWidth={1}
                                    styles={buildStyles({
                                        pathColor: "#75623F",
                                        trailColor: "transparent",
                                        textColor: "#ffffff",
                                    })}
                                />

                                <div style={{ position: 'absolute', top: '-5%', transform: 'translateY(-0%)', right: '50%', textAlign: 'right' }}>
                                    <span className='prakritilabel'>Vata</span>
                                </div>

                                <div style={{ position: 'absolute', top: 10, left: 10, width: 230, height: 230 }}>
                                    <CircularProgressbar
                                        value={prakritiData?.pitta}
                                        className="second-circle"
                                        strokeWidth={1}
                                        styles={buildStyles({
                                            pathColor: "#FF5C00",
                                            trailColor: "transparent",
                                            textColor: "#ffffff",
                                        })}
                                    />
                                </div>

                                <div style={{ position: 'absolute', top: '-1%', transform: 'translateY(-0%)', right: '50%', textAlign: 'right' }}>
                                    <span className='prakritilabel' >Pitta</span>
                                </div>

                                <div style={{ position: 'absolute', top: 20, left: 20, width: 210, height: 210 }}>
                                    <CircularProgressbar
                                        value={prakritiData?.kapha}
                                        className="third-circle"
                                        strokeWidth={1}
                                        styles={buildStyles({
                                            pathColor: "#6CBA6C",
                                            trailColor: "transparent",
                                            textColor: "#ffffff",
                                        })}
                                    />
                                </div>


                                <div style={{ position: 'absolute', top: '2%', transform: 'translateY(-0%)', right: '50%', textAlign: 'right', }}>
                                    <span className='prakritilabel'>Kapha</span>
                                </div>

                                <div
                                    className="inner-circle-content"
                                    style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}
                                >
                                    <span className="inner-circle-text">
                                        <div className="dominant-prakriti-doshas">
                                            {findDominantDosha(prakritiData).map((e) => {
                                                return <p key={e}>{e}</p>;
                                            })}
                                        </div>
                                    </span>

                                </div>

                            </div>

                        </div>

                        <div className='PrakritiPhenotype'>
                            <p>{prakritiData?.result?.split('')?.join(' ')?.toUpperCase()} Phenotype Characteristics</p>
                        </div>

                    </div>
                    <div className='Prakriti-detail-item'>

                        <div className='PhenotypeChar'>
                            <StrengthSlider dietList={prakritiresultData?.Strength}></StrengthSlider>
                            <WeaknessSlider dietList={prakritiresultData?.Weakness}></WeaknessSlider>
                        </div>

                        {/* <div className='disorders-section'>
                            <BulletTextGrid textList={prakritiresultData?.diseases} />
                        </div> */}

                        <div className='Skip-section'>
                            <div className='flex items-center justify-center'>
                                <div style={{ width: "200px", }}>
                                    <Button
                                        style={{
                                            width: '100%',
                                            textTransform: 'none',
                                            borderRadius: '0',
                                            backgroundColor: '#DA6E0B',
                                            color: 'white',
                                            border: 'none',
                                            boxSizing: 'border-box',
                                            borderRadius: '5px',
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 500,
                                            padding: '10px'
                                        }}
                                        onClick={() => {
                                            navigate('/')
                                        }}
                                    >
                                        Finish
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }

    </div>






}
export default PrakritiResult;
