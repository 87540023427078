import React, { useState, useEffect } from 'react';
import { IconButton, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Carousel from 'react-bootstrap/Carousel';
import ShopStore from '../DataRepo/ShopStore';
import loadingimg from '../Assets/images/loading image 1.png'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CircularProgress } from '@mui/material';
import { Container, Title, Accordion, rem, createStyles } from '@mantine/core';
import ImageZoom from './ImageZoom';
import useCartStore from '../DataRepo/CartStore';
import { getUserDetails } from '../Auth/AuthUtil';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Badge from '@mui/material/Badge';
import { Skeleton } from '@mui/material';
import addtocart from '../Assets/images/add_to _bag_brown.png'
import WishlistStore from '../DataRepo/WishlistStore';
import FavoriteIcon from '@mui/icons-material/Favorite';
function ReccomedatedProducts() {
    const navigate = useNavigate();
    
    const { addWishlist, WishlistItems, isWishlistLoading, removeWishlist, getWishlistItems } = WishlistStore()
    const { addItem, cartItems, updateQuantity, decreaseQuantity } = useCartStore();
    const { recommedatedproducts, getTopProducts, isRecommendatedProductloading, getrecommedatedProducts, isLoading, Top_Products, getProductsTeaProducts, TeaProducts } = ShopStore();

    useEffect(() => {             
        getrecommedatedProducts()
        getWishlistItems()
    }, [])

    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Recommended Products</p>
            </div>
            <div>
                <Badge badgeContent={cartItems?.length}
                    onClick={() => {
                        navigate('/Checkout')
                    }}
                    boxSizing="10"
                    sx={{
                        '& .MuiBadge-badge': {
                            backgroundColor: 'red',
                            color: 'red',
                        },
                    }}>
                    <div className='BagIcon'>
                        <img src={addtocart}></img>
                    </div>
                </Badge>
            </div>
        </nav>
        <div className='AllRecommendedProduct-section'>
            <div className='RecommendedProduct-section'>

                <div className='RecommendedProduct-container'>
                    {isRecommendatedProductloading ? (

                        Array.from({ length: 6 }).map((_, i) => (
                            <div key={i} className='Product-section-card'>
                                <Skeleton variant="rectangular" width={210} height={118} />
                                <Skeleton width="60%" />
                                <Skeleton width="80%" />
                                <Skeleton width="40%" />
                            </div>
                        ))
                    ) : (
                        recommedatedproducts?.map((e, i) => (
                            <div key={i} className='Product-section-card' onClick={() => navigate(`/Product?id=${e.id}`)}>
                                <div className="Product-card-header">
                                    <div className="Product-discount-badge">
                                        {e.orgprice > 0 && e.orgprice != e.price ? (
                                            <p>
                                                {(((parseFloat(e.orgprice) - parseFloat(e.price)) / parseFloat(e.orgprice)) * 100).toFixed(0) + "% off"}
                                            </p>
                                        ) : null}
                                    </div>
                                    <div className="Product-wishlist-icon">
                                    <IconButton
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                {
                                                    WishlistItems.some((item) => item.id === e?.id) ? (
                                                        removeWishlist(e.id)
                                                    ) : (
                                                        addWishlist(e.id)
                                                    )
                                                }
                                            }}
                                        >
                                            {WishlistItems.some((item) => item.id === e?.id) ? (
                                                <FavoriteIcon style={{
                                                    color: "#DA6E0B"
                                                }} />
                                            ) : (
                                                <FavoriteBorderIcon />
                                            )}
                                        </IconButton>
                                    </div>
                                </div>

                                <div>
                                    <img src={e.imgurl} alt={e.name} />
                                </div>

                                <div className='Product-section-card-title'>
                                    <p>{e?.name}</p>
                                    <p>{e?.desc}</p>
                                </div>

                                <div className='Addtobagbutton'>
                                    <div className='Product-section-price-details'>
                                        <p className='mr-5'><del>{e?.orgprice > 0 && e?.orgprice != e?.price ? `₹ ${e?.orgprice}` : ""}</del></p>
                                        <p>₹ {e?.price}</p>
                                    </div>
                                    <div className='w-100'>
                                        <Button
                                            style={{
                                                width: '100%',
                                                textTransform: 'none',
                                                borderRadius: '0',
                                                backgroundColor: '#75623F',
                                                color: 'white',
                                                border: 'none',
                                                boxSizing: 'border-box',
                                                borderRadius: "5px",
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: 500,
                                                padding: "10px"
                                            }}
                                            onClick={() => {
                                                // navigate('/SignUp')
                                            }}
                                        >
                                            <div className='addtobagctn'>
                                                <p>{e.onsale === true ? 'Add to bag' : "Coming Soon"}</p>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>

    </div>
}

export default ReccomedatedProducts