import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { profileModel } from "../Axios/AxiosConfig";
import { getUserDetails } from "../Auth/AuthUtil";

const firebaseConfig = {
  apiKey: "AIzaSyCoqdf4BKDKbVsvsN7xsqnn16mJlf5tR9k",
  authDomain: "aiwell-v2.firebaseapp.com",
  projectId: "aiwell-v2",
  storageBucket: "aiwell-v2.appspot.com",
  messagingSenderId: "521225602262",
  appId: "1:521225602262:web:666128b9f80e7de5cdf577",
  measurementId: "G-353TNH9G01"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging if supported
export let messaging;

if (await isSupported()) {
  messaging = getMessaging(app);
}

export const generateToken = async () => {
  if (!messaging) {
    console.log("Firebase messaging is not supported in this environment.");
    return;
  }

  const permission = await Notification.requestPermission();

  if (permission === 'granted') {
    try {
      const token = await getToken(messaging, {
        vapidKey: "BFaKGHmLPitMXVShX2UT5iFY9ceZQIHummbHUlWFmAXYdHigtb8C_cedrjJZX0dFLJwMULci6BjswY_B8g3n8c8"
      });
      if (token) {
        try {
          const userDetails = getUserDetails()
          const res = await profileModel.post('/AddFCMToken', {
            Device_type: "Web",
            UID: userDetails?.UID,
            token
          })
          if (res?.status === 200) {
            console.log(res.data)
          }
        }
        catch (err) {
          console.log(err?.response?.data)
        }
      } else {
        console.log("Failed to generate the token.");
      }
    } catch (error) {
      console.error("An error occurred while retrieving the token: ", error);
    }
  } else {
    console.log("Notification permission was not granted.");
  }
};
