import React from 'react';
import PhenoTypeAssesment from '../Assets/images/phenotype assessment.png';
import DoneIcon from '@mui/icons-material/Done';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import outerCircle from '../Assets/images/vector.png';
import PrakritiRotation from '../Assets/animations/Prakriti rotation 2.json';
import uq from '../Assets/images/unique positive traits icon.png'
import imp from '../Assets/images/Areas of improvement.png'
import dis from '../Assets/images/Disease predisposition.png'
import Lottie from 'lottie-react';
import { Button } from '@mui/material';
import ChatBot from '../Assets/images/Prakriti screen green circle.png'
import { useNavigate } from 'react-router-dom';
import { toast, Zoom } from 'react-toastify';

function PrakritiIntro() {
    let navigate = useNavigate()

    return (
        <div className='Profile-page-section'>
            <div className='Profile-img-section'>
                <div>Phenotype Assessment</div>
                <div><img src={PhenoTypeAssesment} alt="Personal Information" /></div>
            </div>

            <div className='Prakriti-intro-main-section'>

                <div className='Prakriti-into-title'>

                    <div>
                        <img src={ChatBot}></img>
                    </div>

                    <div>
                        <p>
                            I am your Personalized Coach
                        </p>
                    </div>

                    <div style={{
                        justifySelf: "flex-end",
                        cursor: 'pointer',
                        borderBottom: "1px solid silver",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 400,
                    }} onClick={() => {
                        navigate('/')
                    }}>
                        <p>Skip now</p>
                    </div>

                </div>

                <div className='Prakriti-into-title-discription'>
                    <p>Phenotype</p>
                    <p>Phenotype refers to an individual's unique constitution of <br />
                        physical, mental, and emotional traits, which influences <br />
                        their health, and overall well-being.</p>
                </div>

                <div className='Prakriti-into-detailed-discription'>
                    <p>Through this assessment, discover your</p>
                    <div>

                        <div>
                            <img src={uq}></img>
                            <p>Unique Positive Traits</p>

                        </div>

                        <div>
                            <img src={imp}></img>
                            <p>Areas of Improvement</p>

                        </div>

                        <div>
                            <img src={dis}></img>
                            <p>Disease Predisposition</p>
                        </div>
                    </div>

                </div>

                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center", width: '100%'
                }}>
                    <div className='phenotypebtn'>
                        <p>Let’s do your unique <b>One Time Phenotype <br />Assessment</b></p>
                    </div>
                    <Button
                        style={{
                            textTransform: 'none',
                            borderRadius: '0',
                            backgroundColor: '#DA6E0B',
                            color: 'white',
                            border: 'none',
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: '10px',
                            paddingRight: "2rem",
                            paddingLeft: "2rem"
                        }}

                        onClick={() => {
                            navigate('/Prakriti')
                            toast.info("Please answer the following questions based on your health and lifestyle as a 15-20-year-old", {
                                position: 'top-center',
                                limit: 1,
                                transition: Zoom,
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: 'dark'
                            });
                        }}

                    >
                        Start Your Assesment
                    </Button>
                </div>
                <div className='background-animation'>
                    <Lottie
                        animationData={PrakritiRotation}
                        className='PrakritiRotationAnimation'
                    />
                </div>
            </div>
        </div>
    );
}

export default PrakritiIntro;
