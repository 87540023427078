import React, { useEffect } from 'react'
import { IconButton } from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { useNavigate, useLocation } from 'react-router-dom'
import ShopStore from '../DataRepo/ShopStore'
import { CircularProgress } from '@mui/material';
import loadingimg from '../Assets/images/loading image 1.png';
import OrderHistoryStepper from './OrderStatus'
import CustomizedSteppers from './OrderStatus'


function OrdersPage() {
    const navigate = useNavigate()
    const location = useLocation()

    const { getOneOrder, Order, isOrderLoading } = ShopStore()

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id') || '';
        getOneOrder(id);
    }, [location]);

    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate('/MyOrders')}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Order details</p>
            </div>
        </nav>
        {isOrderLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
            <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                </div>
            </div>
        </div> :


            <div className='Order-details-page-section'>
                <div>
                    <div className='orderidsection'>
                        <p>Order id : <span>{Order?.Data?.Orderid}</span></p>
                    </div>
                    <div className='CustomizedSteppers'>
                        <p>Order Status</p>
                        <CustomizedSteppers statusData={Order?.Data?.OrderHistoryStatus} />
                    </div>
                    <div>
                        {
                            Order?.Data?.cartitems?.map((e) => (
                                <div>
                                    <div>
                                        <img src={e?.image} />
                                    </div>
                                    <div>
                                        <p>{e?.name}</p>
                                        <div className='mt-3 qtyprice'>
                                            <p >
                                                <span style={{ padding: '2px 10px', backgroundColor: "#CAF6CA", borderRadius: "5px" }}>{e.qty}</span> x <span>₹ {e.actualprice}</span></p>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }
                    </div>
                </div>

                <div>
                    <div className='shpadd'>
                        <p>Shipping Address</p>
                        <div className='Address-item flex flex-row justify-between mb-3 mt-3'>
                            <div className='flex flex-row justify-start'>
                                <div className='Address-item-content'>
                                    <span>{'Home'}</span>
                                    <p>{Order?.Data?.customerDetails?.shipping?.name}</p>
                                    <p>{Order?.Data?.customerDetails?.shipping?.addressLine1}</p>
                                    <p>{Order?.Data?.customerDetails?.shipping?.email}</p>
                                    <p>{Order?.Data?.customerDetails?.shipping?.contact}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='Cart-total-container'>
                        <div>
                            <p>Order Details</p>
                        </div>
                        <div>
                            <div>
                                <p>Sub Total</p>
                                <p>{`₹ ${Order?.Data?.subTotal}`}</p>
                            </div>
                            <div>
                                <p>Delivery Charges</p>
                                <p>{`₹ ${Order?.Data?.delivery_charges}`}</p>
                            </div>
                        </div>
                        <div>
                            <p>Total : <span>₹ {Order?.Data?.total}</span></p>
                        </div>
                    </div>
                </div>

            </div>
        }
    </div>
}

export default OrdersPage