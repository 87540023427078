import React, { useState, useEffect } from 'react';
import { CircularProgress, Button, Card, Divider, IconButton, Modal, Typography, Grid, Box, Fade } from '@mui/material';
import { ArrowDropDown, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProgressBar from "react-customizable-progressbar";
import { getUserDetails } from '../Auth/AuthUtil';
import Dialog from '@mui/material/Dialog';
import { profileModel } from '../Axios/AxiosConfig';
import WellnessStore from '../DataRepo/WellnessStore';
import DialogTitle from '@mui/material/DialogTitle';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fibpro from '../Assets/images/flbpro.png'
import { HomeIcon } from 'lucide-react';
import bspro from '../Assets/images/bspro.png'
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Text
} from 'recharts';
import DoshaBarChart from './VikritiChart';

const VikritiResult = () => {
    const [showButton, setShowButton] = useState(false);
    const [strengthSheetOpen, setStrengthSheetOpen] = useState(false);
    const [weaknessSheetOpen, setWeaknessSheetOpen] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [vikritiData, setvikritiData] = useState([])
    const [vikritiresultData, setVikritiResultData] = useState([])
    const navigate = useNavigate();
    const userDetails = getUserDetails();
    const { Selected_Wellness, getSelected_Wellness } = WellnessStore();

    function calculateDoshaValues(vikritiResponse) {
        let v = 0;
        let p = 0;
        let k = 0;

        const uniqueCategories = new Set();

        vikritiResponse?.vikritiList?.forEach((vikriti) => {
            vikriti.dosha_increase.split(",").forEach((dosha) => {
                switch (dosha.trim().toLowerCase()) {
                    case "v":
                        v++;
                        break;
                    case "p":
                        p++;
                        break;
                    case "k":
                        k++;
                        break;
                }
            });

            vikriti.dosha_decrease.split(",").forEach((dosha) => {
                switch (dosha.trim().toLowerCase()) {
                    case "v":
                        v--;
                        break;
                    case "p":
                        p--;
                        break;
                    case "k":
                        k--;
                        break;
                }
            });

            uniqueCategories.add(vikriti.category);
        });

        const totalDoshas = vikritiResponse?.vikritiList?.length;

        const vPercent = (v / totalDoshas) * 100;
        const pPercent = (p / totalDoshas) * 100;
        const kPercent = (k / totalDoshas) * 100;

        return {
            doshaValues: { v: vPercent, p: pPercent, k: kPercent },
            uniqueCategories: Array.from(uniqueCategories)
        };
    }

    function calculatePercentages(vikritiResponse) {

        const { doshaValues, uniqueCategories } = calculateDoshaValues(vikritiResponse);

        const vataFloat = doshaValues?.v;
        const pittaFloat = doshaValues?.p;
        const kaphaFloat = doshaValues?.k;

        const vataPulse = vikritiResponse?.vataPulseScore || 0;
        const pittaPulse = vikritiResponse?.pittaPulseScore || 0;
        const kaphaPulse = vikritiResponse?.kaphaPulseScore || 0;

        const totalPulse = Math.abs(vataPulse) + Math.abs(pittaPulse) + Math.abs(kaphaPulse);
        let vataFloatPulse = 0, pittaFloatPulse = 0, kaphaFloatPulse = 0;

        if (totalPulse !== 0) {
            vataFloatPulse = (vataPulse * 100) / totalPulse;
            pittaFloatPulse = (pittaPulse * 100) / totalPulse;
            kaphaFloatPulse = (kaphaPulse * 100) / totalPulse;
        }

        const vataScore = vataFloat || 0;
        const vataPulseScore = vataFloatPulse || 0;
        const pittaScore = pittaFloat || 0;
        const pittaPulseScore = pittaFloatPulse || 0;
        const kaphaScore = kaphaFloat || 0;
        const kaphaPulseScore = kaphaFloatPulse || 0;

        const vPercent = (0.7 * vataScore + 0.3 * vataPulseScore);
        const pPercent = (0.7 * pittaScore + 0.3 * pittaPulseScore);
        const kPercent = (0.7 * kaphaScore + 0.3 * kaphaPulseScore);

        const doshaPercentages = { v: Math.floor(vPercent), p: Math.floor(pPercent), k: Math.floor(kPercent) };


        return { doshaPercentages, uniqueCategories };
    }

    const getvikritiData = async () => {
        try {
            let res = await profileModel.get(`/Vikriti/${userDetails.UID}`);
            if (res.status === 200) {
                setVikritiResultData(calculatePercentages(res.data))
                setvikritiData(res.data)
                setLoading(false)
            }
        } catch (err) {
            console.log(err?.response?.data);
        }
    };

    useEffect(() => {
        getvikritiData()
        getSelected_Wellness()
    }, []);

    const handleScroll = (event) => {
        setShowButton(event.target.scrollTop > 100);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    const testKitSlider = [
        {
            id: fibpro,
            title: 'Track your Glucose effortlessly with our user-friendly CGM',
            description: 'Free style libre pro',
            code: '9545784',
        },
        {
            id: bspro,
            title: 'Discover Your Health Answers with our innovative kit!',
            description: 'BugSpeaks',
            code: '9545783',
        }
    ];

    const doshanames = {
        V: "Vata",
        P: "Pitta",
        K: "Kapha"
    }

    return <div className='Wellness-Info-section flex flex-col justify-center align-center'>

        {isLoading ? (
            <div className='Prakriti-container flex flex-col items-center justify-center' style={{ height: "30rem", justifySelf: "center" }}>
                
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress style={{ color: "#DA6E0B" }} />
                </Box>

            </div>
        ) : (
            <div className='Prakriti-result-container mt-3'>
                <div className='Prakriti-result-section'>
                    
                    {vikritiData.Result != '' &&
                        <div className='Prakriti-result-item'>
                            <p>Homeostasis Imbalance Result</p>
                            <div className="VikritiChart">
                                <DoshaBarChart vikritiresultData={vikritiresultData} style={{ border: "1px solid red" }} />
                            </div>
                            <div className='PrakritiPhenotype'>
                                <p> Your {vikritiData.Result.split('').map((e) => doshanames[e]).join(' ')} is aggravated</p>
                            </div>
                        </div>
                    }

                    <div className='vikriti-detail-item'>
                        <div className='RWP relative'>
                            <p>Recommended Wellness Packages for You</p>
                            <div className='shop-selected-wellness-categories mt-2'>
                                {
                                    Selected_Wellness.filter((s) => vikritiresultData.uniqueCategories.some((e) => e === s.name)).map((category, index) => {
                                        return <div
                                            key={index}
                                            className={`WellnessCard`}
                                            onClick={() => {
                                                navigate(`/Products?category=${category?.name}`)
                                            }}
                                        >
                                            <div className={`WellnessImg2`}>
                                                <img src={category.imageUrl} alt={category.name} />
                                            </div>
                                            <p className='mt-2 Wellnessp2'>
                                                {category.name}
                                            </p>
                                        </div>
                                    })
                                }
                            </div>
                            <div className='absolute right-0 top-0'>
                                <IconButton onClick={() => {
                                    navigate('/')
                                }}>
                                    <HomeIcon />
                                </IconButton>
                            </div>
                        </div>

                        <div className='cldrcard'>
                            <div>
                                <p>Personalized recommendations to bring you <br />
                                    back to homeostasis</p>
                            </div>

                            <div>
                                <Button
                                    style={{
                                        width: '100%',
                                        textTransform: 'none',
                                        borderRadius: '0',
                                        fontSize: "12px",
                                        backgroundColor: '#8CEB8C', /* Background color */
                                        color: '#3B362A', /* Text color for better contrast */
                                        border: 'none', /* Remove default border */
                                        boxSizing: 'border-box',
                                        borderRadius: "5px",
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        padding: "10px"
                                    }}

                                    onClick={() => {
                                        navigate('/Diet')
                                    }
                                    }
                                >
                                    Discover here
                                </Button>
                            </div>
                        </div>

                        <div style={{ width: "100%", display: "none" }} >
                            <Slider {...settings}>
                                {testKitSlider.map(item => (
                                    <div className='testkitslidecard2'>
                                        <div>
                                            <img src={item.id}></img>
                                        </div>
                                        <div className='testkitslidecard-info2'>
                                            <p>{item.title}</p>
                                            <div>
                                                <Button
                                                    style={{
                                                        width: '100%',
                                                        textTransform: 'none',
                                                        borderRadius: '0',
                                                        fontSize: "12px",
                                                        backgroundColor: '#8CEB8C', /* Background color */
                                                        color: '#3B362A', /* Text color for better contrast */
                                                        border: 'none', /* Remove default border */
                                                        boxSizing: 'border-box',
                                                        borderRadius: "5px",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontWeight: 500,
                                                        padding: "10px"
                                                    }}

                                                    onClick={() => {
                                                        //   navigate('/SignUp')
                                                    }
                                                    }
                                                >
                                                    Explore here
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>

                        <div className='flex flex-row vkdoc-card'>
                            <div className='vk-doc-img-card'>
                                <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/Prathiban_2-removebg-preview+2.png'>
                                </img>
                            </div>

                            <div style={{ alignSelf: "center", justifySelf: "center" }}>
                                <p>To make your </p>
                                <p><b>Health journey</b> much easier</p>
                            </div>

                            <div style={{ alignSelf: "flex-end" }} >
                                <Button
                                    style={{
                                        width: '100%',
                                        textTransform: 'none',
                                        borderRadius: '0',
                                        fontSize: "12px",
                                        backgroundColor: '#DA6E0B', /* Background color */
                                        color: '#FFFFFF', /* Text color for better contrast */
                                        border: 'none', /* Remove default border */
                                        boxSizing: 'border-box',
                                        borderRadius: "5px",
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        padding: "10px"
                                    }}

                                    onClick={() => {
                                        navigate('/BookAppointment?id=AYUR_DOC_3')
                                    }
                                    }
                                >
                                    Consult Now
                                </Button>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        )
        }

    </div >






}
export default VikritiResult;
