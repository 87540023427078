import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
    const { value } = props;
    const [displayValue, setDisplayValue] = useState(0); // Start with 0 to trigger the animation

    useEffect(() => {
        setDisplayValue(value); // Update the display value to trigger the CSS animation
    }, [value]);

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                value={100}
                size={100}
                thickness={2.5}
                sx={{
                    color: '#e0e0e0',
                }}
            />
            <CircularProgress
                variant="determinate"
                value={displayValue}
                size={100}
                thickness={2.5}
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    color: '#DA6E0B',
                    transition: 'all 1.5s ease-in-out',
                }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <p className='level-text-percentage'>
                    {`${Math.round(displayValue)}%`}
                </p>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

export default CircularProgressWithLabel;
