import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useMediaQuery, useTheme, Box, Typography } from '@mui/material';
import Check from '@mui/icons-material/Check';
import completedicon from '../Assets/images/complete_order_icon.png';
import incompleteIcon from '../Assets/images/incomplete_order_icon.png';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 20px)',
        right: 'calc(50% + 20px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#B2F2B2',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#B2F2B2',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#E8E1D6',
        borderTopWidth: 5,
        borderRadius: 1,
        ...theme.applyStyles('dark', {
            borderColor: theme.palette.grey[800],
        }),
    },
}));

const MobileStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#B2F2B2',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#B2F2B2',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#E8E1D6',
        borderLeftWidth: 3,
        height: '100%',
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme }) => ({
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    ...theme.applyStyles('dark', {
        color: theme.palette.grey[700],
    }),
    variants: [
        {
            props: ({ ownerState }) => ownerState.active,
            style: {
                color: '#784af4',
            },
        },
    ],
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <img src={completedicon} alt="Completed" style={{ width: "2rem" }} />
            ) : (
                <img src={incompleteIcon} alt="Incomplete" style={{ width: "2rem" }} />
            )}
        </QontoStepIconRoot>
    );
}

const steps = ['Placed', 'Confirmed', 'Dispatched', 'Delivered'];

const getActiveSteps = (statusData, steps) => {
    if (!Array.isArray(statusData) || !Array.isArray(steps)) {
        console.error('Invalid input data');
        return [];
    }

    const activeSteps = steps.map((step, index) => {
        const isActive = statusData.some((status) =>
            step.toLowerCase().includes(status?.status?.split(' ')[1]?.toLowerCase())
        );
        return isActive ? index : -1;
    }).filter(index => index !== -1);

    return activeSteps;
};

const getTimestamp = (statusData, step) => {
    if (!Array.isArray(statusData)) {
        console.error('Invalid status data');
        return null;
    }

    const status = statusData.find(
        (status) => step.toLowerCase().includes(status?.status?.split(' ')[1]?.toLowerCase())
    );

    return status ? new Date(status.timestamp).toLocaleString() : 'Not available';
};

export function WebStepper({ activeSteps, statusData }) {
    return (
        <Stack sx={{ width: '100%' }}>
            <Stepper alternativeLabel activeStep={Math.max(...activeSteps)} connector={<QontoConnector />}>
                {steps.map((label, index) => (
                    <Step key={label} completed={activeSteps.includes(index)}>
                        <StepLabel StepIconComponent={QontoStepIcon}>
                            <div>
                                <p>{label}</p>
                                {activeSteps.includes(index) && (
                                    <Typography variant="caption" color="textSecondary">
                                        {getTimestamp(statusData, label)}
                                    </Typography>
                                )}
                            </div>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    );
}

export function MobileStepper({ activeSteps, statusData }) {
    return (
        <Box>
            <Stepper activeStep={Math.max(...activeSteps)} orientation="vertical" connector={<MobileStepConnector />}>
                {steps.map((label, index) => (
                    <Step key={label} completed={activeSteps.includes(index)}>
                        <StepLabel StepIconComponent={QontoStepIcon}>
                            <div>
                                <p>{label}</p>
                                {activeSteps.includes(index) && (
                                    <Typography variant="caption" color="textSecondary">
                                        {getTimestamp(statusData, label)}
                                    </Typography>
                                )}
                            </div>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}

export default function ResponsiveStepper({ statusData }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const activeSteps = getActiveSteps(statusData, steps);

    return (
        <div>
            {isMobile ? (
                <MobileStepper activeSteps={activeSteps} statusData={statusData} />
            ) : (
                <WebStepper activeSteps={activeSteps} statusData={statusData} />
            )}
        </div>
    );
}
