import { useEffect, useState } from "react";
import loadingImg from '../Assets/images/loading image 1.png'

function AnswerCard({ answer, handleAnswerSelect }) {
    const [isImgLoaded, setIsImgLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.onload = () => setIsImgLoaded(true);
        img.onerror = () => setIsImgLoaded(true);
        img.src = answer?.image;
    }, [answer?.image]);

    return (
        <div
            className={`Answer-card ${answer.isSelected ? 'selected' : ''}`}
            onClick={() => handleAnswerSelect(answer)}
        >
            <img src={isImgLoaded ? answer?.image : loadingImg} alt={answer?.Answer} />
            <p  style={{wordBreak:"break-word"}}>{answer?.Answer}</p>
        </div>
    );
}

export default AnswerCard