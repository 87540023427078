import React, { useState, useEffect } from 'react';
import WellnessStore from '../DataRepo/WellnessStore';
import { IconButton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress } from '@mui/material';
import cautionimg from '../Assets/images/Caution icon 1.png'
import loadingimg from '../Assets/images/loading image 1.png'

function RecipePage() {
    const navigate = useNavigate()
    const { getRecipes, Recipes, isRecipeLoading } = WellnessStore();
    const location = useLocation();

    useEffect(() => {
        if (Recipes.length === 0) {
            getRecipes()
        }
    }, [])

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id') || '';

    const filteredRecipes = Recipes.filter((e) => e.id === id)?.[0]

    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Recipes</p>
            </div>
        </nav>

        {
            isRecipeLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                    <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                    </div>
                </div>
            </div> :

                <div className='Recipe-page-section'>
                    <div className='RecipeLandingCard'>
                        <div>
                            <p>{filteredRecipes?.recipe}</p>
                            <p>{filteredRecipes?.info}</p>
                        </div>
                        <div>
                            <img src={filteredRecipes?.imageLink}></img>
                        </div>
                    </div>
                    <div >
                        <div className='Prepcooking'>
                            <div>
                                <p>Prep + Cooking time</p>
                                <p>{filteredRecipes?.cookingTime}</p>
                            </div>
                            <div>
                                <p>Benefits</p>
                                <p>{filteredRecipes?.benefits}</p>
                            </div>
                        </div>
                        <div className='RecipesInstructionandingreditens'>
                            <div>
                                <div className='instTitle'>
                                    <p>Ingredients</p>
                                </div>
                                <div>
                                    <ul>
                                        {
                                            filteredRecipes?.ingredients.map((e) => <li>{e}</li>)
                                        }
                                    </ul>

                                </div>
                            </div>
                            <div>
                                <div className='instTitle'><p>Instructions</p></div>
                                <div>
                                    <ul>
                                        {
                                            filteredRecipes?.instructions?.map((e) => <li>{e}</li>)
                                        }
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }

        <div className='m-10'>
            <div className='Slot-errreponse'>

                <div className='flex flex-col items-center'>
                    <img src={cautionimg}></img>
                    <p style={{ fontWeight: 500, fontSize: 'large' }}>Disclaimer</p>
                </div>
                <p>Recommendations are approved by our Ayurveda physician Dr.Prathiban. The assessment and suggestions provided here are for health and wellness purposes only. Please do not self-medicate. Consult an Ayurveda practitioner or medical professional for accurate guidance.</p>
            </div>
        </div>

    </div>
}

export default RecipePage