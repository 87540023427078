import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import DoneIcon from '@mui/icons-material/Done';
import outerCircle from '../Assets/images/vector.png';
import wellnessCategoriesImg from '../Assets/images/wellness categories.png';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import vikritinext from '../Assets/images/vikritinext.png'
import WelcomeScreenanimation from '../Assets/animations/welcomscreenrotation.json';
import axios from 'axios';
import Lottie from 'lottie-react';
import { getUserDetails } from '../Auth/AuthUtil';
import { profileModel } from '../Axios/AxiosConfig';

function Wellness2() {

    const navigete = useNavigate()
    const userDetails = getUserDetails()

    const wellnessItems = [
        {
            name: "Manage\nWeight",
            imageUrl: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/wellness+category/balance_weight.png',
            selected: false,
        },
        {
            name: "Better\nSleep",
            imageUrl: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/wellness+category/better_sleep.png',
            selected: false,
        },
        {
            name: "Digestive\nHealth",
            imageUrl: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/wellness+category/gastro_wellness.png',
            selected: false,
        },
        {
            name: "General\nWellness",
            imageUrl: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/wellness+category/general_wellness.png',
            selected: false,
        },
        {
            name: "Hair \nHealth",
            imageUrl: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/wellness+category/hair_health.png',
            selected: false,
        },
        {
            name: "Mental\nHealth",
            imageUrl: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/wellness+category/mental_wellness.png',
            selected: false,
        },
        {
            name: "Metabolic\nHealth",
            imageUrl: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/wellness+category/metabolic_wellness.png',
            selected: false,
        },
        {
            name: "Physical \nFitness ",
            imageUrl: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/wellness+category/Physical_wellness.png',
            selected: false,
        },
        {
            name: "Respiratory\nHealth",
            imageUrl: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/wellness+category/respiratory_wellness.png',
            selected: false,
        },
        {
            name: "Sexual\nHealth ",
            imageUrl: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/wellness+category/sexual_health.png',
            selected: false,
        },
        {
            name: "Skin\nHealth",
            imageUrl: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/wellness+category/skin_health.png',
            selected: false,
        },
        {
            name: "Women’s\nHealth",
            imageUrl: "https://aiwellassets.s3.ap-south-1.amazonaws.com/wellness+category/women_wellness.png",
            selected: false,
        }
    ];

    const [allWellnessCategories, setWellnessCategories] = useState(wellnessItems)

    const handleCardClick = (index) => {
        setWellnessCategories(prevCategories => {
            const selectedCount = prevCategories.filter(category => category.selected).length;

            return prevCategories.map((category, i) => {
                if (i === index) {
                    if (selectedCount < 2) {
                        return { ...category, selected: !category.selected };
                    }

                    else {
                        return { ...category, selected: false };
                    }
                }

                return category;
            });
        });
    };


    // const SubmitWellnessInterest = async () => {
    //     try {
    //         let res = await profileModel.post('/AddwellnessCategories', {
    //             UID: userDetails?.UID,
    //             Categories: allWellnessCategories
    //         })

    //         if (res.status === 200) {
    //             navigete('/PrakritiIntro')
    //         }
    //     }
    //     catch (err) {
    //         console.log(err.response.data)
    //     }
    // }

    // const handleCardClick = (index) => {
    //     setWellnessCategories(prevCategories =>
    //         prevCategories.map((category, i) =>
    //             i === index
    //                 ? { ...category, selected: !category.selected }
    //                 : category
    //         )
    //     );
    // };

    return (
        <div className='Welcome-page-section'>
            <div className='Wellness-categories-section'>

                <div className="Welcome-section-animation">
                    <Lottie animationData={WelcomeScreenanimation} />
                </div>

                <div>
                    <p style={{ textAlign: "center", fontWeight: "500" }}>What areas of wellness would you <br />
                        like to focus on?</p>
                </div>

                <div>
                    <div className='Wellness-categories-list'>
                        {allWellnessCategories.map((category, index) => (
                            <div
                                key={index}
                                className={`WellnessCard flex flex-col items-center`}
                                onClick={() => handleCardClick(index)}
                            >
                                <div className={`WellnessImg`} style={category.selected ? { border: '2px solid #DA6E0B' } : {}}>
                                    <img src={category.imageUrl} alt={category.name} />
                                </div>
                                <p className='mt-2'>
                                    {category.name}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    {
                        allWellnessCategories.filter(category => category.selected).length >= 1 && <div onClick={() => {
                            localStorage.setItem('SelectedWellness', JSON.stringify(allWellnessCategories))
                            navigete('/Packages')
                        }} style={{ alignSelf: "center", justifySelf: "center", width: "3rem", cursor: "pointer" }} >
                            <img src={vikritinext}></img>
                        </div>
                    }
                </div>



            </div>
        </div>
    );
}

export default Wellness2;
