import create from 'zustand';
import { teleConsultationModel } from '../Axios/AxiosConfig';
import Appointments from '../Tele_Consultation/Appointments';

const AppointmentStore = create((set, get) => ({
    Appointments: [],
    Appointment_data: null,
    isAppointmentloading: true,
    isAppointmentsLoading: true,
    
    getAppointments: async (UID) => {
        try {
            let res = await teleConsultationModel.get(`/getAllappointments/${UID}`);

            if (res?.status === 200 && res?.data) {
                set({
                    Appointments: res?.data,
                    isAppointmentsLoading: false,
                });
            } else {
                set({
                    isAppointmentsLoading: false,
                });
                console.log('Failed to fetch Appointments. Status code:', res.status);
            }
        } catch (err) {
            console.log(err?.response?.data);
            set({
                isAppointmentsLoading: false,
            });
        }
    },
    
    getOneAppointment: async (id) => {
        try {
            let res = await teleConsultationModel.get(`/getOneappointment/${id}`);

            if (res?.status === 200 && res?.data) {
                set({
                    Appointment_data: res?.data,
                    isAppointmentloading: false,
                });
            } else {
                set({
                    isAppointmentloading: false,
                });
                console.log('Failed to fetch Appointments. Status code:', res.status);
            }
        } catch (err) {
            console.log(err?.response?.data);
            set({
                isAppointmentloading: false,
            });
        }
    }

    
}))

export default AppointmentStore