import React, { useState, useEffect } from 'react';
import { IconButton, Badge, Box, TextField } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import useCartStore from '../DataRepo/CartStore';
import TeleConsultationStore from '../DataRepo/TeleConsultationStore';
import addtocart from '../Assets/images/add_to _bag_brown.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import star from '../Assets/images/Orange Outline star.png';
import Rating from '@mui/material/Rating';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import sethos from '../Assets/images/sethos icon.png';
import heart from '../Assets/images/orange heart icon.png';
import loadingimg from '../Assets/images/loading image 1.png'

import videoCallgrn from '../Assets/images/video call green icon.png'
import audiocallgrn from '../Assets/images/Call  green  icon.png'
import userprofileicon from '../Assets/images/user profile.png'
import videoCallbrn from '../Assets/images/video call white icon.png'
import audiocallbrn from '../Assets/images/Call icon in white color.png'
import cautionimg from '../Assets/images/Caution icon 1.png'
import { CircularProgress } from '@mui/material';
import nexticon from '../Assets/images/next_icon.png'
import { toast, Zoom } from 'react-toastify';

function BookAppointment() {
    const { cartItems } = useCartStore();



    const { getDoctorDetails, DoctorDetails, getSlots, Slots, slotErrResponse, isDoctorsLoading, setAppointmentDetails } = TeleConsultationStore();

    const navigate = useNavigate();
    const location = useLocation();

    const [selectedMode, setSelectedMode] = useState("Video")

    const [selectedDay, setSelectedDay] = useState(dayjs());

    const [selectedTime, setSelectedTime] = useState('')

    const queryParams = new URLSearchParams(location.search);

    const id = queryParams.get('id') || '';

    console.log(selectedDay.format('YYYY-MM-DD'))

    useEffect(() => {
        getDoctorDetails(id);
        getSlots(id, selectedDay.format('YYYY-MM-DD'));
    }, [location]);

    const handleSelectDay = (date) => {
        if (!date) return;
        setSelectedDay(date);
        getSlots(id, date.format('YYYY-MM-DD'));
    };

    const handleAppointment = () => {
        if (selectedTime != "") {
            const data = {
                DoctorDetails,
                selectedDay: selectedDay.format('YYYY-MM-DD'),
                selectedMode,
                selectedTime,
                price: selectedMode === "Video" ? DoctorDetails?.video_consultation_charges : DoctorDetails?.audio_consultation_charges
            }
            setAppointmentDetails(data)
            navigate('/ConfirmSlot')
        }
        else {
            toast.info("Please choose an available time slot", {
                position: 'top-center',
                limit: 1,
                style: {
                    fontSize: '14px', fontFamily: '"Poppins", sans-serif',
                    fontWeight: 500,
                    backgroundColor: '#FDFDFD',
                    color: '#3B362A'
                },
                transition: Zoom,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                closeButton: false,
                theme: 'dark',
                icon: <img src={cautionimg} alt="custom-icon" style={{ width: '2rem' }} /> // Custom icon
            });

        }

    }

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];



    const today = new Date();
    const currentMonth = today.getMonth();
    const currentDate = today.getDate();

    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedDate, setSelectedDate] = useState(currentDate);

    const formatMonth = (monthIndex) => {
        const year = today.getFullYear();
        const monthFormatted = (monthIndex + 1).toString().padStart(2, '0');
        return `${year}-${monthFormatted}`;
    };

    const formatDate = (monthIndex, day) => {
        const year = today.getFullYear();
        const monthFormatted = (monthIndex + 1).toString().padStart(2, '0');
        const dayFormatted = day.toString().padStart(2, '0');
        return `${year}-${monthFormatted}-${dayFormatted}`;
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
        const dateFormatted = formatDate(selectedMonth, date);
        console.log(`Date selected: ${dateFormatted}`);
        // getCreditshistory(dateFormatted, monthFormatted);
    };


    const renderDays = () => {
        const today = new Date();
        const todayDate = today.getDate();
        const selectedMonth = today.getMonth();
        const selectedYear = today.getFullYear();

        const days = [];

        for (let i = 0; i < 14; i++) {
            const nextDay = new Date(today);
            nextDay.setDate(todayDate + i);
            if (nextDay.getMonth() !== selectedMonth) break;
            days.push(nextDay);
        }

        return days.map((day) => (
            <div
                key={day.toString()}
                
                onClick={() => {
                    handleSelectDay(dayjs(day))
                    handleDateClick(day.getDate())
                    setSelectedTime('')
                }}

                style={{
                    cursor: 'pointer',
                    borderRadius: '4px',
                    textAlign: "center",
                    backgroundColor: '#FAF6EC',
                    border: selectedDate === day.getDate() ? '1px solid #d9730d' : 'none',
                }}
            >
                <div style={{
                    padding: '5px 10px',
                    fontWeight: 400,
                    fontSize: '10px',
                    borderRadius: '4px', backgroundColor: "#F4ECD6"
                }}>{months[selectedMonth].toUpperCase()}</div>

                <div style={{
                    padding: '5px 10px',
                    fontWeight: 400,
                    fontSize: '14px',
                }}>{daysOfWeek[day.getDay()]}</div>

                <div>{day.getDate().toString().padStart(2, '0')}</div>
            </div>
        ));
    };

    return (
        <div className='All-products-section-main'>
            <nav className='flex flex-row p-2 items-center justify-between'>
                <div className='flex flex-row items-center'>
                    <IconButton onClick={() => navigate(-1)}>
                        <ChevronLeft />
                    </IconButton>
                    <p className='mb-0'>Book Appointment</p>
                </div>
                <div>

                </div>
            </nav>
            {isDoctorsLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                    <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                    </div>
                </div>
            </div> :

                <div className='Tele-consultation-book-main-container'>
                    <div className='Tele-consultation-doctor-details'>
                        <div>
                            <img
                                src={DoctorDetails?.img || 'placeholder-image-url'}
                                alt={DoctorDetails?.name || 'Doctor Image'}
                            />

                            <div className='doc-info-card'>

                                <div className="icon-text">
                                    <img src={star} alt="Star" />
                                    <p>{DoctorDetails?.rating}</p>
                                </div>

                                <div className="icon-text">
                                    <img src={sethos} alt="Experience" />
                                    <p>{DoctorDetails?.experience}+ years</p>
                                </div>

                                <div className="icon-text">
                                    <img src={heart} alt="Treated Patients" />
                                    <p>{DoctorDetails?.treated}+ treated patients</p>
                                </div>
                            </div>

                        </div>

                        <div></div>

                        <div>
                            <h2>{DoctorDetails?.name} &nbsp;<span>{DoctorDetails?.course_details}&nbsp;|&nbsp;</span>
                                <span>{DoctorDetails?.speciality}</span>
                            </h2>
                            <div className='mt-3'>
                                <p>About</p>
                                <p>{DoctorDetails?.about}</p>
                            </div>
                            <div className='mt-3'>
                                <p>Languages Known</p>
                                <p>{DoctorDetails?.language_known?.split(',').join(' | ')}</p>
                            </div>

                            {DoctorDetails?.User_Rating && <div className='mt-3'>
                                <p>Reviews</p>
                                <div className='Reviews-container'>
                                    {
                                        DoctorDetails?.User_Rating?.map((e, i) => {
                                            return <div className='flex flex-row items-center g-5 Review-card-item'>

                                                <div className='flex flex-col gap-2 items-center'>
                                                    <img src={userprofileicon}></img>
                                                    <p>{e?.name}</p>
                                                </div>

                                                <div>
                                                    <p>{e?.comments}</p>
                                                    <div className='flex flex-row items-center justify-between'>
                                                        <Rating name="half-rating-read" defaultValue={e.rating} precision={0.5} readOnly />
                                                        <p>{e?.date}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            }
                        </div>
                    </div>

                    <div className='Tele-consultation-doctor-slots'>
                        <div className='mt-2'>
                            <p className='choosetimingslottitle'>Select Date</p>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '16px',
                                    // justifyContent: 'center',
                                    overflowX: 'auto',

                                }}
                                className='Date-navigation mt-5'
                            >
                                {renderDays()}
                            </div>
                        </div>


                        {
                            Slots && <div className='mt-5'>
                                <p className='choosetimingslottitle'>Choose Timing Slot</p>
                                <div className="Slot-container">
                                    {Slots &&
                                        Slots.map((slot, index) => (
                                            <div
                                                key={index}
                                                className={`${selectedTime === slot.Time ? 'selected' : ''} ${slot.Status === 'Booked' ? 'booked' : ''}`}
                                                onClick={() => {
                                                    if (slot.Status === 'Free') {
                                                        setSelectedTime(slot.Time);
                                                    }
                                                }}
                                                style={{ pointerEvents: slot.Status === 'Booked' ? 'none' : 'auto', cursor: slot.Status === 'Booked' ? 'not-allowed' : 'pointer' }}
                                            >
                                                <p>{slot?.Time}</p>
                                            </div>
                                        ))

                                    }
                                </div>
                            </div>

                        }

                        {
                            slotErrResponse && <div className='Slot-errreponse'>
                                <img src={cautionimg}></img>
                                <p>{slotErrResponse.message}</p>
                            </div>
                        }

                        {/* {!slotErrResponse && <div className='selectMode'>
                            <p>Select your mode</p>
                            <div className='flex flow-row items-center gap-10 mt-2'>
                                <div style={selectedMode === "Audio" ? { border: "1px solid #7FD67F" } : {}}
                                    onClick={() => {
                                        setSelectedMode('Audio')
                                    }}>
                                    <img src={audiocallgrn}></img>
                                    <p>Audio</p>
                                </div>
                                <div style={selectedMode === "Video" ? { border: "1px solid #7FD67F" } : {}}
                                    onClick={() => {
                                        setSelectedMode('Video')
                                    }}>
                                    <img src={videoCallgrn}></img>
                                    <p>Video</p>
                                </div>
                            </div>
                        </div>
                        } */}

                        {
                            !slotErrResponse && <div className='btmbookbtn'>
                                <div>
                                    <div>
                                        <img src={
                                            selectedMode === "Video" ? videoCallbrn : audiocallbrn
                                        }></img>
                                    </div>
                                    <div>
                                        <p>For {selectedMode} Call</p>
                                        <p>₹ {selectedMode === "Video" ? DoctorDetails?.video_consultation_charges : DoctorDetails?.audio_consultation_charges} / 30 minutes</p>
                                    </div>
                                </div>
                                <div className="bkslotbtn" onClick={handleAppointment}>
                                    <img src={nexticon}></img>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default BookAppointment;
