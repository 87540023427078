import React, { useEffect, useState } from 'react';
import productimg from '../Assets/images/product image 2.png';
import { Button, IconButton, Skeleton } from '@mui/material';
import outerCircle from '../Assets/images/Discount tag.png'
import WellnessStore from '../DataRepo/WellnessStore';
import ShopStore from '../DataRepo/ShopStore';
import { Profile_URL } from '../Axios/config';
import { useNavigate } from 'react-router-dom';
import cautionimg from '../Assets/images/Caution icon 1.png'
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import doom from '../Assets/images/product doom.png'
import productlan1 from '../Assets/images/product1_landingcard_shop.png'
import productlan2 from '../Assets/images/product2_landingcard_shop.png'
import productlan3 from '../Assets/images/product3_landingcard_shop.png'
import productlan4 from '../Assets/images/product4_landingcard_shop.png'
import productlan5 from '../Assets/images/product5_landingcard_shop.png'
import WishlistStore from '../DataRepo/WishlistStore';

function ShopScreen() {
    const { Selected_Wellness, getSelected_Wellness } = WellnessStore();
    const { recommedatedproducts, getTopProducts, isRecommendatedProductloading, getrecommedatedProducts, isLoading, Top_Products, getProductsTeaProducts, TeaProducts } = ShopStore();

    const { addWishlist, WishlistItems, isWishlistLoading, removeWishlist, getWishlistItems } = WishlistStore()

    const navigate = useNavigate()

    useEffect(() => {
        getSelected_Wellness();
    }, []);

    useEffect(() => {
        getrecommedatedProducts()
        getTopProducts()
        getProductsTeaProducts()
        getWishlistItems()
    }, [])

    const landingimg = [
        {
            img: productlan1,
            id: "16516556",
        }
        , {
            img: productlan2,
            id: "16826069"
        }, {
            img: productlan3,
            id: "43923568558304"
        }, {
            img: productlan4,
            id: "622"
        },

        {
            img: productlan5,
            id: "16842249"
        }
    ]

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === landingimg.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000);
        return () => clearInterval(interval);
    }, [landingimg.length]);

    return (
        <div className='Shop-main-section'>
            <div className='Shop-promo-card' onClick={() => { navigate(`/Product?id=${landingimg[currentIndex]?.id}`) }}>
                <div className='Shop-promo-card-title'>
                    <p>Healthier <b>Food;</b></p>
                    <p>Happier <b>Life</b></p>
                    <div>
                        <Button
                            style={{
                                width: '100%',
                                textTransform: 'none',
                                borderRadius: '5px',
                                backgroundColor: '#FDFDFD',
                                color: '#75623F',
                                border: 'none',
                                boxSizing: 'border-box',
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: '10px'
                            }}
                            onClick={() => { navigate(`/Product?id=${landingimg[currentIndex]?.id}`) }}
                        >
                            Buy now
                        </Button>
                    </div>
                </div>

                <div className="outer-circle-container">
                    <div className="outer-circle-wrapper">
                        <div className="outer-circle-image">
                            <img src={outerCircle} alt="Outer Circle" />
                        </div>
                        <div className="text-overlay">
                            <p>Get</p>
                            <p>5%</p>
                            <p>Offer</p>
                        </div>
                    </div>
                </div>

                <div>
                    <img src={landingimg[currentIndex].img}></img>
                    <img src={doom} />
                </div>
            </div>

            {Selected_Wellness?.length > 0 &&
                <div className='Shop-Wellness-Categories mt-3'>
                    <div className='shop-selected-wellness-categories mt-2'>
                        {Selected_Wellness?.map((category, index) => (
                            <div
                                key={index}
                                className={`WellnessCard`}
                                onClick={() => {
                                    navigate(`/Products?category=${category?.name}`)
                                }}
                            >
                                <div className={`WellnessImg`}>
                                    <img src={category.imageUrl} alt={category.name} />
                                </div>
                                <p className='mt-2'>
                                    {category.name}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>}

            {recommedatedproducts.length > 0 && <div className='RecommendedProduct-section'>
                <div className='flex flex-row justify-between items-center w-full'>
                    <p>Recommended Products</p>
                    {recommedatedproducts.length > 6 && <p id='viewAll' onClick={() => {
                        navigate('/RecommedatedProducts')
                    }}>View all</p>}
                </div>

                <div className='RecommendedProduct-container'>
                    {isRecommendatedProductloading ? (

                        Array.from({ length: 6 }).map((_, i) => (
                            <div key={i} className='Product-section-card'>
                                <Skeleton variant="rectangular" width='100%' height={118} />
                                <Skeleton width="100%" />
                                <Skeleton width="80%" />
                                <Skeleton width="40%" />
                            </div>
                        ))
                    ) : (

                        recommedatedproducts?.slice(0, 6).map((e, i) => (
                            <div key={i} className='Product-section-card' onClick={() => navigate(`/Product?id=${e.id}`)}>
                                <div className="Product-card-header">
                                    <div className="Product-discount-badge">
                                        {e.orgprice > 0 && e.orgprice != e.price ? (
                                            <p>
                                                {(((parseFloat(e.orgprice) - parseFloat(e.price)) / parseFloat(e.orgprice)) * 100).toFixed(0) + "% off"}
                                            </p>
                                        ) : null}
                                    </div>
                                    <div className="Product-wishlist-icon">
                                        <IconButton
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                {
                                                    WishlistItems.some((item) => item.id === e?.id) ? (
                                                        removeWishlist(e.id)
                                                    ) : (
                                                        addWishlist(e.id)
                                                    )
                                                }
                                            }}
                                        >
                                            {WishlistItems.some((item) => item.id === e?.id) ? (
                                                <FavoriteIcon style={{
                                                    color: "#DA6E0B"
                                                }} />
                                            ) : (
                                                <FavoriteBorderIcon />
                                            )}
                                        </IconButton>
                                    </div>
                                </div>

                                <div>
                                    <img src={e.imgurl} alt={e.name} />
                                </div>

                                <div className='Product-section-card-title'>
                                    <p>{e?.name}</p>
                                    <p>{e?.desc}</p>
                                </div>

                                <div className='Addtobagbutton'>
                                    <div className='Product-section-price-details'>
                                        <p className='mr-3'><del>{e?.orgprice > 0 && e?.orgprice != e?.price ? `₹ ${e?.orgprice}` : ""}</del></p>
                                        <p>₹ {e?.price}</p>
                                    </div>
                                    <div className='w-100'>
                                        <Button
                                            style={{
                                                width: '100%',
                                                textTransform: 'none',
                                                borderRadius: '0',
                                                backgroundColor: '#75623F',
                                                color: 'white',
                                                border: 'none',
                                                boxSizing: 'border-box',
                                                borderRadius: "5px",
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: 500,
                                                padding: "10px"
                                            }}
                                            onClick={() => {
                                                // navigate('/SignUp')
                                            }}
                                        >
                                            <div className='addtobagctn'>
                                                <p>{e.onsale === true ? 'Add to bag' : "Coming Soon"}</p>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>}

            <div>
                <div className='TeaProductsHeading'>
                    <p>Immune Boosters</p>
                </div>
                <div className='TeaProductsContainer'>
                    {
                        TeaProducts.map((e, i) => {
                            return <div >
                                <div style={{ backgroundColor: "#red" }}>
                                    <img src={e.imgurl} style={{ mixBlendMode: "multiply", backgroundColor: "red" }}></img>
                                </div>
                                <div className='TeaPID' onClick={() => {
                                    navigate(`/Product?id=${e?.id}`)
                                }}>
                                    <div className='flex flex-row items-center justify-between'>
                                        <div>
                                            <p>{e.name}</p>
                                        </div>

                                        <div className="Product-wishlist-icon">
                                            <IconButton
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    {
                                                        WishlistItems.some((item) => item.id === e?.id) ? (
                                                            removeWishlist(e.id)
                                                        ) : (
                                                            addWishlist(e.id)
                                                        )
                                                    }
                                                }}
                                            >
                                                {WishlistItems.some((item) => item.id === e?.id) ? (
                                                    <FavoriteIcon style={{
                                                        color: "#DA6E0B"
                                                    }} />
                                                ) : (
                                                    <FavoriteBorderIcon />
                                                )}
                                            </IconButton>
                                        </div>

                                    </div>

                                    <div>
                                        <p>QTY : {e.qty}</p>
                                    </div>

                                    <div>
                                        <p>{e?.desc}</p>
                                    </div>

                                    <div>
                                        <div className='Product-section-price-details'>
                                            <p className='mr-3'><del>{e?.orgprice > 0 && `₹ ${e?.orgprice}`}</del></p>
                                            <p>₹ {e?.price}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        })

                    }
                </div>
            </div>

            <div className='mt-5 Top-Products-landing-section'>
                <div>
                    <p>Top Products</p>
                    <p style={{ cursor: "pointer" }} onClick={() => {
                        navigate('/Products?type=All')
                    }}>View all</p>
                </div>
                <div>
                    {
                        Top_Products.map((e, i) => {
                            return <div className='Top-Products-landing-sectionpcard' key={i} onClick={() => {
                                navigate(`/Product?id=${e?.id}`)
                            }}>
                                <div>
                                    <img src={e?.imgurl}></img>
                                </div>

                                <div>
                                    <p>{e?.name}</p>
                                    <p>{e?.desc}</p>

                                    <div className='Product-section-price-details'>
                                        <p className='mr-3'><del>{e?.orgprice > 0 && `₹ ${e?.orgprice}`}</del></p>
                                        <p>₹ {e?.price}</p>
                                    </div>

                                </div>

                            </div>
                        })
                    }
                </div>
            </div>

            <div className='mt-10'>
                <div className='Slot-errreponse'>

                    <div className='flex flex-col items-center'>
                        <img src={cautionimg}></img>
                        <p style={{ fontWeight: 500, fontSize: 'large' }}>Disclaimer</p>
                    </div>
                    <p>The assessment and suggestions provided here are for health and wellness purposes only. Please do not self-medicate. Consult an Ayurveda practitioner or medical professional for accurate guidance.</p>
                </div>
            </div>

        </div >
    );
}

export default ShopScreen;
