import React from 'react'
import GoalsStore from '../DataRepo/GoalsStore'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import WellnessStore from '../DataRepo/WellnessStore';

function GoalResult() {
    const navigate = useNavigate();

    const { Diets, getDiet, isDietLoading } = WellnessStore();
    const { getYoga, selectedYogas, unselectedYogas, isYogaLoading } = WellnessStore();
    const { isMeditationdataLoading, getMedidationData, Meditationdata } = WellnessStore();

    const { getGoal, Goals, isGoalsLoading, error } = GoalsStore();

    useEffect(() => {
        getGoal();
        getYoga();
        getMedidationData();
    }, []);

    useEffect(() => {

        if (Goals?.Followup || error?.data === "No Goal Available") {
            navigate("/goals");
        }

    }, [Goals?.Followup, error?.data, navigate]);

    console.log(error)



    useEffect(() => {
        getDiet()
    }, [])

    const groupBy = (array, key) => {
        if (!array) return {};
        return array.reduce((result, currentValue) => {
            const groupKey = currentValue[key];
            if (!result[groupKey]) {
                result[groupKey] = [];
            }
            result[groupKey].push(currentValue);
            return result;
        }, {});
    };

    const DietsSuitable = groupBy(Diets?.Suitable, 'categoryEng');
    const DietsNot_Suitable = groupBy(Diets?.Not_Suitable, 'categoryEng');

    const predefinedOrder = [
        'Cereals & Millets',
        'Fruits',
        'Vegetables',
        'Pulses',
        'Spices',
        'Sugar Products',
        'Milk products',
        'Oil and Fats',
        'General',
        'Alcoholic Beverages'
    ];

    const landingImages = {
        'Cereals & Millets': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Cereals+%26+Millets.png',
        'Fruits': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Fruits.png',
        'Vegetables': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Vegetables.png',
        'Pulses': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Pulses.png',
        'Spices': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Spices.png',
        'Sugar Products': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Sugar+Products.png',
        'Milk products': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Milk+Products.png',
        'Oil and Fats': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Oil+%26+Fats.png',
        'General': "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/General.png",
        'Alcoholic Beverages': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/dieticons/Alcoholic+Beverages.png'
    };

    const uniqueCategories = predefinedOrder.filter(category =>
        Object.keys(DietsSuitable).includes(category) || Object.keys(DietsNot_Suitable).includes(category)
    );

    return <div className='m-5'>
        <div className='Goals-result-page'>

            <div className='Goals-total-container'>

                <div>
                    <p>{Goals?.Total}</p>
                </div>

                <p>Your Health Score</p>

            </div>

            <div className='Goals-recommendations'>

                <p>Recommendations</p>

                {uniqueCategories.length > 0 && <div className='mt-5'>
                    <p>Diet</p>
                    <div className='rmdfoods mt-1'>
                        {uniqueCategories?.map((category, index) => (
                            <div onClick={() => {
                                navigate(`/Diet?categoryname=${category}`)
                            }}>
                                <div >
                                    <img src={landingImages[category]}></img>
                                </div>
                                <div>
                                    <p>{category}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}

                {
                    selectedYogas.length > 0 &&
                    <div className='mt-5'>
                        <p>Yoga</p>
                        <div>
                            <div className='mt-2 Yogalist-container'>
                                {
                                    selectedYogas.map((e, i) => {
                                        return <div className='yogacard' onClick={() => {
                                            navigate(`/yoga?id=${e?.id}`)
                                        }}>
                                            <div>
                                                <p>{e?.title}</p>
                                                <p>{e?.pose}</p>
                                            </div>
                                            <div>
                                                <img src={e?.image} />
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>

                    </div>
                }

                <div className='mt-5'>
                    <p>Meditation</p>
                    <div className='Yogalist-container'>

                        {
                            Meditationdata.map((e, i) => {
                                return <div className='Meditation-Card' key={i} onClick={() => {
                                    navigate(`/Meditation?id=${e.Id}`)
                                }}>
                                    <p>{e?.title}</p>
                                    <img src={e?.image}></img>
                                </div>
                            })
                        }

                    </div>

                </div>

                <div>

                </div>
            </div>

        </div>
    </div>
}

export default GoalResult