import React, { useEffect, useState } from 'react';
import { IconButton, Button, Badge, TextField, Radio, FormControlLabel, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import useCartStore from '../DataRepo/CartStore';
import useAddressStore from '../DataRepo/AddressStore'; // Import the Zustand store
import addtocart from '../Assets/images/add_to _bag_brown.png';
import locIcon from '../Assets/images/orange_location_icon.png';
import elocIcon from '../Assets/images/edit_location_icon.png';
import deleteIcon from '../Assets/images/delete icon.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { shopModel } from '../Axios/AxiosConfig';
import { getUserDetails } from '../Auth/AuthUtil';

function Address() {
    const navigate = useNavigate();
    const { cartItems } = useCartStore();

    const { addAddress, deleteAddress, editAddress, addresses, setCurrentAddress, currentAddress, getAddress } = useAddressStore();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editId, setEditId] = useState(null);
    const [deleteId, setdeleteId] = useState(null)

    const [formData, setFormData] = useState({
        address_type: 'Home',
        Email: '',
        Name: '',
        Phone: '',
        address_details: '',
        City: '',
        State: '',
        Country: '',
        Pincode: '',
    });

    const [errors, setErrors] = useState({
        address_type: '',
        Email: '',
        Name: '',
        Phone: '',
        address_details: '',
        City: '',
        State: '',
        Country: '',
        Pincode: '',
    });

    const toggleModal1 = () => {
        setIsModalVisible(!isModalVisible);
        resetFormData();
    };

    const toggleEditModal = (id) => {
        const addressToEdit = addresses.find((address) => address.id === id);
        if (addressToEdit) {
            setFormData({ ...addressToEdit });
            setEditId(id);
        }
        setIsEditModalVisible(!isEditModalVisible);
    };

    const validateInput = (name, value) => {
        let error = '';
        switch (name) {
            case 'Name':
                if (!value.trim()) error = 'Please enter name is required';
                break;
            case 'Email':
                if (!/\S+@\S+\.\S+/.test(value)) error = 'Please enter a valid email address';
                break;
            case 'Phone':
                if (!/^\d{10}$/.test(value)) error = 'Please enter a valid 10-digit number';
                break;
            case 'Pincode':
                if (!/^\d{6}$/.test(value)) error = 'Please enter a valid 6-digit Pincode number';
                break;
            default:
                if (!value.trim()) error = `Please enter ${name.charAt(0).toUpperCase() + name.slice(1)} it can't be empty`;
                break;
        }
        return error;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const error = validateInput(name, value);

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors({
            ...errors,
            [name]: error,
        });
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            const error = validateInput(key, formData[key]);
            if (error) {
                newErrors[key] = error;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        const isValid = validateForm();
        if (isValid) {
            const newAddress = {
                ...formData,
                id: Date.now().toString(),
            };

            addAddress(newAddress);

            setCurrentAddress(newAddress);

            toggleModal1();
            resetFormData();
        } else {
            console.log('Form has errors, please fix them');
        }
    };

    const handleEditSubmit = () => {
        const isValid = validateForm();
        if (isValid && editId !== null) {
            editAddress(editId, formData);
            setCurrentAddress(formData);
            toggleEditModal(editId);
            resetFormData();
        } else {
            console.log('Form has errors, please fix them');
        }
    };

    const resetFormData = () => {
        setFormData({
            address_type: 'Home',
            Email: '',
            Name: '',
            Phone: '',
            address_details: '',
            City: '',
            State: '',
            Country: '',
            Pincode: '',
        });
        setErrors({});
    };

    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const ChangeCurrentAddress = async (address) => {
        try {
            const UID = getUserDetails()?.UID

            const { id } = address

            let res = await shopModel.put(`/currentAddress/${UID}/${id}`, {
                ...address
            })

            if (res.status === 200) {
                setCurrentAddress(id)
                navigate(-1)
            }
        }
        catch (err) {
            console.log(err?.response?.data)
        }
    }


    useEffect(() => {
        getAddress();
    }, []);

    return (
        <div className='All-products-section-main'>
            <nav className='flex flex-row p-2 items-center justify-between'>
                <div className='flex flex-row items-center'>
                    <IconButton onClick={() => navigate(-1)}>
                        <ChevronLeft />
                    </IconButton>
                    <p className='mb-0'>Address</p>
                </div>
                <div>
                    <Badge
                        badgeContent={cartItems?.length}
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: 'red',
                                color: 'white',
                            },
                        }}
                    >
                        <div className='BagIcon'>
                            <img src={addtocart} alt="Cart" />
                        </div>
                    </Badge>
                </div>
            </nav>
            <div className='Address-section'>
                <div className='Address-main-section'>
                    {addresses.map((e) => (
                        <div key={e.id} className='Address-item flex flex-row justify-between'
                            style={currentAddress?.id === e?.id ? {
                                backgroundColor: "#FBF1E7",
                                border: "1px solid #DA6E0B",
                                cursor: "pointer"
                            } : {}}
                            onClick={() => {
                                ChangeCurrentAddress(e)
                            }}>

                            <div className='flex flex-row justify-start'>
                                <div className='locIcon'>
                                    <img src={locIcon} alt="Location Icon" />
                                </div>
                                <div className='Address-item-content'>
                                    <span>{e?.address_type}</span>
                                    <p>{e?.Name}</p>
                                    <p>{e?.address_details}</p>
                                    <p>{e?.Email}</p>
                                    <p>{e?.Phone}</p>
                                </div>
                            </div>


                            <div className='flex flex-row '>
                                <div
                                    className='locIcon'
                                    style={{ width: "2rem", cursor: "pointer" }}
                                    onClick={(el) => {
                                        el.stopPropagation();
                                        toggleEditModal(e.id);
                                    }}
                                >
                                    <img src={elocIcon} alt="Edit Icon" />
                                </div>

                                <div
                                    className='locIcon'
                                    style={{ width: "2rem", cursor: "pointer" }}
                                    onClick={(el) => {
                                        el.stopPropagation();
                                        handleClickOpen()
                                        setdeleteId(e?.id)
                                    }}
                                >
                                    <img src={deleteIcon} alt="Edit Icon" />
                                </div>
                            </div>


                        </div>
                    ))}
                </div>

                <div className=''>
                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            backgroundColor: '#DA6E0B',
                            color: 'white',
                            border: 'none',
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: '10px'
                        }}
                        onClick={toggleModal1}
                    >
                        Add a new Address
                    </Button>
                </div>
            </div>

            {isModalVisible && (
                <div className="modal-overlay" onClick={toggleModal1}>
                    <div className="bottom-sheet" onClick={(e) => e.stopPropagation()}>
                        <div className='Address-container'>
                            <p>Delivery Address</p>
                            <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
                                <div className='flex justify-between flex-wrap'>
                                    {['Home', 'Office', 'Other'].map((type) => (
                                        <FormControlLabel
                                            key={type}
                                            value={type}
                                            control={
                                                <Radio
                                                    checked={formData.address_type === type}
                                                    onChange={(e) => handleChange(e)}
                                                    name="address_type"
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            color: '#DA6E0B',
                                                        },
                                                    }}
                                                />
                                            }
                                            label={type}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    backgroundColor: formData.address_type === type ? '#DA6E0B' : '#FAF6EC',
                                                    color: formData.address_type === type ? 'white' : '#333',
                                                    borderRadius: '5px',
                                                    padding: '5px 15px',
                                                    cursor: 'pointer',
                                                    textAlign: 'center',
                                                },
                                            }}
                                        />
                                    ))}
                                </div>
                            </FormControl>
                            <div className='Address-container-inputs'>
                                {['Name', 'Email', 'Phone', 'address_details', 'City', 'State', 'Country', 'Pincode'].map((field) => (
                                    <div key={field} className='Text-inputs'>
                                        <TextField
                                            name={field}
                                            id="filled-basic"
                                            label={field.charAt(0).toUpperCase() + field.slice(1)}
                                            variant="filled"
                                            fullWidth
                                            value={formData[field]}
                                            onChange={handleChange}
                                            error={!!errors[field]}
                                            helperText={errors[field]}
                                            sx={{
                                                '& .MuiFilledInput-root': {
                                                    backgroundColor: '#FAF6EC',
                                                },
                                                '& label.Mui-root': {
                                                    color: 'red',
                                                },
                                                '& .MuiFormLabel-root.Mui-error': {
                                                    color: 'red',
                                                },
                                                '& .MuiFilledInput-underline:after': {
                                                    borderBottomColor: '#DA6E0B',
                                                },
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className=''>
                                <Button
                                    style={{
                                        width: '100%',
                                        textTransform: 'none',
                                        borderRadius: '0',
                                        backgroundColor: '#DA6E0B',
                                        color: 'white',
                                        border: 'none',
                                        boxSizing: 'border-box',
                                        borderRadius: '5px',
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        padding: '10px'
                                    }}
                                    onClick={handleSubmit}
                                >
                                    Save Address
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isEditModalVisible && (
                <div className="modal-overlay" onClick={toggleEditModal}>
                    <div className="bottom-sheet" onClick={(e) => e.stopPropagation()}>
                        <div className='Address-container'>
                            <p>Edit Address</p>
                            <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
                                <div className='flex justify-between flex-wrap'>
                                    {['Home', 'Office', 'Other'].map((type) => (
                                        <FormControlLabel
                                            key={type}
                                            value={type}
                                            control={
                                                <Radio
                                                    checked={formData.address_type === type}
                                                    onChange={(e) => handleChange(e)}
                                                    name="address_type"
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            color: '#DA6E0B',
                                                        },
                                                    }}
                                                />
                                            }
                                            label={type}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    backgroundColor: formData.address_type === type ? '#DA6E0B' : '#FAF6EC',
                                                    color: formData.address_type === type ? 'white' : '#333',
                                                    borderRadius: '5px',
                                                    padding: '5px 15px',
                                                    cursor: 'pointer',
                                                    textAlign: 'center',
                                                },
                                            }}
                                        />
                                    ))}
                                </div>
                            </FormControl>
                            <div className='Address-container-inputs'>
                                {['Name', 'Email', 'Phone', 'address_details', 'City', 'State', 'Country', 'Pincode'].map((field) => (
                                    <div key={field} className='Text-inputs'>
                                        <TextField
                                            name={field}
                                            id="filled-basic"
                                            label={field.charAt(0).toUpperCase() + field.slice(1)}
                                            variant="filled"
                                            fullWidth
                                            value={formData[field]}
                                            onChange={handleChange}
                                            error={!!errors[field]}
                                            helperText={errors[field]}
                                            sx={{
                                                '& .MuiFilledInput-root': {
                                                    backgroundColor: '#FAF6EC',
                                                },
                                                '& label.Mui-root': {
                                                    color: 'red',
                                                },
                                                '& .MuiFormLabel-root.Mui-error': {
                                                    color: 'red',
                                                },
                                                '& .MuiFilledInput-underline:after': {
                                                    borderBottomColor: '#DA6E0B',
                                                },
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className=''>
                                <Button
                                    style={{
                                        width: '100%',
                                        textTransform: 'none',
                                        borderRadius: '0',
                                        backgroundColor: '#DA6E0B',
                                        color: 'white',
                                        border: 'none',
                                        boxSizing: 'border-box',
                                        borderRadius: '5px',
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        padding: '10px'
                                    }}
                                    onClick={handleEditSubmit}
                                >
                                    Update Address
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 500,
                }}>
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{

                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 400,

                    }}>
                        you want to delete this address?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} style={{
                        width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        border: '1px solid #DA6E0B ',
                        color: '#DA6E0B',
                        boxSizing: 'border-box',
                        backgroundColor: "#FDFDFD",
                        borderRadius: '5px',
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: '10px'
                    }}>
                        Disagree
                    </Button>

                    <Button onClick={() => {
                        deleteAddress(deleteId)
                        handleClose()
                    }}

                        style={{
                            width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            backgroundColor: '#DA6E0B',
                            color: 'white',
                            border: 'none',
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: '10px'
                        }}

                    >
                        Agree
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Address;
