import React, { useState, useEffect } from 'react';
import WellnessStore from '../DataRepo/WellnessStore';
import { IconButton, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress } from '@mui/material';
import meditationLandingimg from '../Assets/images/meditation.png'
import loadingimg from '../Assets/images/loading image 1.png'
import MobileStepper from '@mui/material/MobileStepper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SwipeableViews from 'react-swipeable-views';

function Pranayama() {

    const { getPranayama, selectedPranayama, unselectedPranayama, isPranayamaLoading } = WellnessStore();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getPranayama()
    }, [])

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id') || '';

    const filteredPranayama = [...selectedPranayama, ...unselectedPranayama]?.filter((e) => e.id === id)

    const [activeStep, setActiveStep] = useState(0);

    const maxSteps = filteredPranayama?.[0]?.steps?.length

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };



    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>{filteredPranayama?.[0]?.title}</p>
            </div>
        </nav>

        {isPranayamaLoading ?
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                    <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                    </div>
                </div>
            </div>

            : <div className='meditation-content'>
                <SwipeableViews
                    className='mb-15'
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {filteredPranayama?.[0]?.steps?.map((e) => {
                        return <div className='steps-info'>
                            <div className='steps-card'>
                                <img src={e?.src}></img>
                                <p>{e?.name}</p>
                                <div>
                                    <ul>
                                        {
                                            e?.points && e?.points?.map((point, index) => {
                                                return (
                                                    <li>{point?.name}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    })}
                </SwipeableViews>

                <MobileStepper
                    steps={maxSteps}
                    activeStep={activeStep}
                    nextButton={activeStep != maxSteps - 1 ?
                        <IconButton
                            size="large"
                            onClick={handleNext}
                            style={activeStep != maxSteps - 1 ?
                                { backgroundColor: "#DA6E0B", color: 'white' } : {}}
                            disabled={activeStep === maxSteps - 1}
                        >
                            <ArrowForwardIcon />

                        </IconButton> : <Button style={{ backgroundColor: "#DA6E0B", color: 'white' }} className='medstepnextbtnct' onClick={() => {
                            navigate(-1)
                        }}>Finish</Button>
                    }
                />

            </div>
        }
    </div>
}

export default Pranayama