import React, { useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextField, IconButton, LinearProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Webcam from 'react-webcam';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { getUserDetails } from '../Auth/AuthUtil';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { reportsModel } from '../Axios/AxiosConfig';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UploadReports = () => {
    const { type } = useParams();
    const [report, setReport] = useState(null);
    const [seletedReportType, setReportType] = useState(null)
    const [allReports, setReports] = useState([]);
    const webcamRef = useRef(null);
    const [uploadProgress, setUploadProgress] = useState(0); // Progress state
    const [isUploading, setIsUploading] = useState(false); // Uploading state
    const fileInput = useRef();
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const userDetails = getUserDetails()


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: 'environment',
    };

    const handleFileUpload = async (e) => {
        e.preventDefault();
        const maxFileSize = 40 * 1024 * 1024;
        let reportsArray = e.target.files;

        for (let i = 0; i < reportsArray.length; i++) {
            let file = reportsArray[i];

            console.log(file.size > maxFileSize)

            if (file.size > maxFileSize) {
                alert(`The file "${file.name}" exceeds the 25MB limit and was not uploaded.`);
                continue

            }

            let filedata = await convertToBase64(file);
            setReports((prev) => [
                ...prev,
                {
                    file: filedata,
                    fileName: file?.name,
                },
            ]);
        }
    };




    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                const base64String = fileReader.result.replace('data:', '').replace(/^.+,/, '');
                resolve(base64String);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleDelete = (index) => {
        setReports((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot().replace('data:', '').replace(/^.+,/, '');
        setReports((prev) => [
            ...prev,
            {
                file: imageSrc,
                fileName: `CapturedImage_${Date.now()}.jpg`,
            },
        ]);
        handleClose()
    }, [webcamRef]);



    const UploadReport = async () => {
        setIsUploading(true);
        try {
            let res = await reportsModel.post('/uploadreports', {
                reports: allReports,
                ReportId: Date.now().toString(),
                ReportUploadedby: userDetails?.UID,
                ReportType: report || `Report_${Date.now()}`,
                CreatedAt: new Date().toString(),
                UID: userDetails?.UID,
                filetype: type.toLowerCase(),
                Comments: '',
            },
                {
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(progress);
                    },
                }
            );

            if (res.status === 200) {
                navigate(-1);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsUploading(false);
        }
    };

    return (<div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Reports</p>
            </div>
        </nav>
        <div className='Upload-Reports'>
            <div className='Select-Reports'>
                <p>Select Your Report Type</p>

                <select onChange={(e) => {
                    setReportType(e.target.value)
                    setReport(e.target.value)
                }}>
                    <option value='ECG'>ECG</option>
                    <option value='Blood Test'>Blood Test</option>
                    <option value='Urine Test'>Urine Test</option>
                    <option value='X-ray'>X-ray</option>
                    <option value='Precription'>Prescription</option>
                    <option value='Others'>Others</option>

                </select>

                {seletedReportType === 'Others' ? (
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                            '& .MuiInputBase-root': {
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        }}
                        id='outlined-basic'
                        label='Enter your report type'

                        onChange={(e) => {
                            setReport(e.target.value)
                        }}

                        variant='outlined'
                    />
                ) : (
                    ''
                )}

                <label htmlFor='file-input'>
                    <div className='ChooseFiles'>
                        <img src='/icons/choose your file image.png' alt='Choose your file' />
                        {type === 'images' ? (
                            <input hidden type='file' id='file-input' ref={fileInput} multiple onChange={handleFileUpload}
                                accept="image/png, image/gif, image/jpeg" />
                        ) : (
                            <input hidden type='file' id='file-input' ref={fileInput} accept='.pdf' onChange={handleFileUpload} />
                        )}
                        <p>Choose your file</p>
                    </div>
                </label>

                {type === 'images' ? (
                    <>
                        <p className='text-center'>or</p>
                        <div className='TakePicturebtn'>
                            <Button
                                style={{
                                    width: '100%',
                                    textTransform: 'none',
                                    backgroundColor: '#75623F',
                                    color: '#FDFDFD',
                                    borderRadius: '5px',
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 500,
                                    padding: '10px',
                                }}
                                onClick={handleClickOpen}
                            >
                                <div className='btn-content'>
                                    <img src='/icons/camera icon.png' alt='Take pictures' />
                                    <p>Take pictures</p>
                                </div>
                            </Button>
                        </div>
                    </>
                ) : null}
            </div>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogContent>
                    {open &&
                        <div className='camera-container'>
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat='image/jpeg'
                                videoConstraints={videoConstraints}
                            />

                            <div>
                                <Button
                                    style={{
                                        width: '100%',
                                        textTransform: 'none',
                                        borderRadius: '0',
                                        border: "1px solid #DA6E0B ",
                                        color: '#DA6E0B',
                                        boxSizing: 'border-box',
                                        borderRadius: "5px",
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        padding: "10px"
                                    }}
                                    onClick={capture}>Capture</Button>
                                <Button
                                    style={{
                                        width: '100%',
                                        textTransform: 'none',
                                        borderRadius: '0',
                                        backgroundColor: '#DA6E0B', 
                                        color: 'white',
                                        border: 'none',
                                        boxSizing: 'border-box',
                                        borderRadius: "5px",
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        padding: "10px"
                                    }}

                                    onClick={() => handleClose()}
                                >Close</Button>
                            </div>
                        </div>
                    }
                </DialogContent>
            </Dialog>

            <div className='Selected-Reports-item'>
                <div className='SelectedReports'>

                    {allReports.map((e, i) => (
                        <div className='SelectedReportCard' key={i}>
                            <IconButton style={{ marginLeft: 'auto' }} onClick={() => handleDelete(i)}>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                            {type === 'images' ? (
                                <img src='/icons/Image icon-1.png' alt='Image icon' />
                            ) : (
                                <img src='/icons/pdf icon.png' alt='PDF icon' />
                            )}
                            <p className='text-center'>{e.fileName}</p>
                        </div>
                    ))}

                </div>

                {allReports.length > 0 ?
                    <div>



                        {isUploading && (
                            <div>
                                <LinearProgress variant='determinate'
                                    sx={{
                                        height: 10,
                                        borderRadius: 5,
                                        backgroundColor: '#f0e5d6',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: '#DA6E0B',
                                        },
                                    }}
                                    value={uploadProgress} />
                                <p>{uploadProgress}%</p>
                            </div>
                        )}
                        <Button
                            style={{
                                width: '100%',
                                textTransform: 'none',
                                borderRadius: '0',
                                backgroundColor: '#DA6E0B',
                                color: 'white',
                                border: 'none',
                                boxSizing: 'border-box',
                                borderRadius: "5px",
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: "10px"
                            }}
                            onClick={UploadReport}>
                            Upload Report
                        </Button>
                    </div>
                    : <></>
                }

            </div>
        </div>
    </div>
    );
};

export default UploadReports;
