import React, { useState, useEffect } from 'react';
import WellnessStore from '../DataRepo/WellnessStore';
import { IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { CircularProgress, Button } from '@mui/material';
import cautionimg from '../Assets/images/Caution icon 1.png'
import loadingimg from '../Assets/images/loading image 1.png'
import lockimg from '../Assets/images/lock_icon.png'
import Dietsampleimg1 from '../Assets/images/D-1.png'
import Dietsampleimg2 from '../Assets/images/D-2.png'
import Dietsampleimg3 from '../Assets/images/D-3.png'
import Dietsampleimg4 from '../Assets/images/D-4.png'
import SubscriptionStore from '../DataRepo/SubscriptionStore';
import AppointmentStore from '../DataRepo/AppointmentStore';
import { getUserDetails } from '../Auth/AuthUtil';

function Diet() {

    const { Diets, getDiet, isDietLoading } = WellnessStore();

    const userDetails = getUserDetails();

    const { Subscriptiondetails, Subscriptiondetailsloading, getSubscriptiondetails, isSubscribed } = SubscriptionStore()
    
    const { Appointments, isAppointmentsLoading, getAppointments } = AppointmentStore();

    const location = useLocation()

    const [showMoreDietSuitable, setshowMoreDietSuitable] = useState(true)

    const [showMoreDietNot_Suitable, setshowMoreNot_Suitable] = useState(true)

    const handleshowMoreDietSuitable = () => {
        setshowMoreDietSuitable(!showMoreDietSuitable)
    }

    const handleshowMoreDietNot_Suitable = () => {
        setshowMoreNot_Suitable(!showMoreDietNot_Suitable)
    }

    const navigate = useNavigate();

    const groupBy = (array, key) => {
        if (!array) return {};
        return array.reduce((result, currentValue) => {
            const groupKey = currentValue[key];
            if (!result[groupKey]) {
                result[groupKey] = [];
            }
            result[groupKey].push(currentValue);
            return result;
        }, {});
    };

    const DietsSuitable = groupBy(Diets?.Suitable, 'categoryEng');
    const DietsNot_Suitable = groupBy(Diets?.Not_Suitable, 'categoryEng');

    const predefinedOrder = [
        'Cereals & Millets',
        'Fruits',
        'Vegetables',
        'Pulses',
        'Spices',
        'Sugar Products',
        'Milk products',
        'Oil and Fats',
        'General',
        'Alcoholic Beverages'
    ];

    const uniqueCategories = predefinedOrder.filter(category =>
        Object.keys(DietsSuitable).includes(category) || Object.keys(DietsNot_Suitable).includes(category)
    );

    const [value, setValue] = useState(0);
    const [currentCategory, setCurrentCategory] = useState(uniqueCategories[0] || '');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setCurrentCategory(uniqueCategories[newValue]);
    };


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('category') || 0;
        setValue(parseInt(id))
    }, [location])


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const categoryName = queryParams.get('categoryname') || '';
        const index = uniqueCategories.findIndex(category => category === categoryName);
        if (index !== -1) {
            setValue(index);
        } else {
            setValue(0);
        }
    }, [location]);

    const Categorieslabel = {
        'Cereals & Millets': 'Cereals & Millets',
        'Fruits': 'Fruits & Nuts',
        'Vegetables': 'Vegetables',
        'Pulses': 'Pulses',
        'Spices': 'Spices',
        'Sugar Products': 'Sugar Products',
        'Milk products': 'Milk products',
        'Oil and Fats': 'Oil & Fats',
        'General': 'General',
        'Alcoholic Beverages': 'Alcoholic Beverages'
    }


    const landingImages = {
        'Cereals & Millets': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/Diet+landing+images/Cereals+%26+Millets.png',
        'Fruits': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/Diet+landing+images/Fruits.png',
        'Vegetables': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/Diet+landing+images/Vegetables.png',
        'Pulses': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/Diet+landing+images/Pulses.png',
        'Spices': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/Diet+landing+images/Spices.png',
        'Sugar Products': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/Diet+landing+images/Sugar+Products.png',
        'Milk products': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/Diet+landing+images/Milk+Products.png',
        'Oil and Fats': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/Diet+landing+images/Oil+%26+Fats.png',
        'General': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/Diet+landing+images/General.png',
        'Alcoholic Beverages': 'https://aiwellassets.s3.ap-south-1.amazonaws.com/Diet+landing+images/Alcoholic+Beverages.png'
    };

    useEffect(() => {
        getDiet()
        getSubscriptiondetails()
        getAppointments(userDetails?.UID)
    }, [])

    const DietSampleImages = [Dietsampleimg1, Dietsampleimg2, Dietsampleimg3, Dietsampleimg4]
    
    const filterAppointments = Appointments.filter(e => e.appointment_status === 'upcoming' || e.appointment_status === 'confirmed').sort((a, b) => {
        const dateA = a?.timestamp ? new Date(Number(a?.timestamp)) : new Date(0);
        const dateB = b?.timestamp ? new Date(Number(b?.timestamp)) : new Date(0);
        return dateB - dateA;
    });

    return (
        <div className='All-products-section-main'>
            <nav className='flex flex-row p-2 items-center justify-between'>
                <div className='flex flex-row items-center'>
                    <IconButton onClick={() => navigate(-1)}>
                        <ChevronLeft />
                    </IconButton>
                    <p className='mb-0'>Diet</p>
                </div>
            </nav>

            {isDietLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100vh' }}>
                <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                    <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                    </div>
                </div>
            </div> :

                Diets?.length === 0 ?

                    <div className='p-5'
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <div className='Unlockdiet mt-5'>

                            <div>
                                <img src={lockimg}></img>
                            </div>

                            <div style={{ width: "100%", maxWidth: "300px" }}>
                                <Button
                                    style={{
                                        width: "100%",
                                        textTransform: "none",
                                        backgroundColor: "#DA6E0B",
                                        border: "none",
                                        color: "#fdfdfd",
                                        borderRadius: "5px",
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        padding: "10px",
                                    }}

                                    onClick={() => {
                                        {
                                            isSubscribed ?
                                                filterAppointments.length === 0 ?
                                                    navigate("/DoctorProfiles")
                                                    : navigate(`/Appointment?id=${filterAppointments[0]?.Appointment_id}&doc_id=${filterAppointments[0]?.doctor_uid}`)
                                                : navigate("/PackageCheckout")
                                        }
                                    }}

                                >
                                    <p className='appointmentbooknowbtn'>
                                        {
                                            isSubscribed ?
                                                filterAppointments.length === 0 ?
                                                    "Book your consultation"
                                                    : "Complete your consultation"
                                                : 'Purchase our Wellness Package'
                                        }
                                    </p>

                                </Button>
                            </div>

                            <p>To get personalized Diet & Recipes</p>

                            <div className="circle-container">
                                {DietSampleImages.map((image, index) => (
                                    <div key={index} className="circle" style={{ backgroundImage: `url(${image})` }}></div>
                                ))}
                            </div>

                        </div>

                    </div>
                    :
                    <div className='Diet-recommendation-page'>

                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            sx={{
                                '& .MuiTabs-indicator': {
                                    display: 'none',
                                },
                                '& .MuiTab-root': {
                                    textTransform: 'none',
                                    padding: '5px',
                                    borderRadius: '4px',
                                    fontFamily: "'Poppins', sans-serif",
                                    fontSize: "13px",
                                    fontWeight: 500,
                                    border: '1px solid #ccc', // Border to make it look like a button
                                    margin: '0 4px', // Adds space between the "buttons"
                                    minWidth: 0,
                                    minHeight: 0,
                                    backgroundColor: '#D4CEC3',
                                    color: "#3B362A",
                                    '&.Mui-selected': {
                                        backgroundColor: '#75623F',
                                        color: '#FFFFFF',
                                    },
                                },
                            }}
                        >
                            {uniqueCategories.map((category, index) => (
                                <Tab key={category} label={Categorieslabel[category]} />
                            ))}
                        </Tabs>

                        <div className='DietLandingCard'>
                            <div>
                                <p>{uniqueCategories[value]}</p>
                            </div>
                            <div>
                                <img src={landingImages[uniqueCategories[value]]}></img>
                            </div>
                        </div>

                        {DietsSuitable[uniqueCategories[value]] &&
                            <div className='Diet-sections'>
                                <div>
                                    <div className='flex flow-row justify-between items-center'>
                                        <p>Suggested {uniqueCategories[value]} for you</p>
                                        <IconButton onClick={handleshowMoreDietSuitable}>
                                            {showMoreDietSuitable ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </div>
                                </div>
                                <div className={`Dietlistitems ${showMoreDietSuitable ? 'wrap-mode' : 'scroll-mode'}`}>
                                    {
                                        DietsSuitable[uniqueCategories[value]].map((e, i) => {
                                            return <div>
                                                <div>
                                                    <img src={e?.imageLink}>
                                                    </img>
                                                </div>
                                                <p>{e?.englishName}</p>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        }

                        {DietsNot_Suitable[uniqueCategories[value]] &&
                            <div className='Diet-sections'>
                                <div>
                                    <div className='flex flow-row justify-between items-center'>
                                        <p>Avoid these {uniqueCategories[value]} </p>
                                        <IconButton onClick={handleshowMoreDietNot_Suitable}>
                                            {showMoreDietNot_Suitable ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </div>

                                </div>
                                <div className={`Dietlistitems ${showMoreDietNot_Suitable ? 'wrap-mode' : 'scroll-mode'}`}>
                                    {
                                        DietsNot_Suitable[uniqueCategories[value]].map((e, i) => {
                                            return <div>
                                                <div>
                                                    <img src={e?.imageLink}>
                                                    </img>
                                                </div>
                                                <p>{e?.
                                                    englishName
                                                }</p>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        }

                        <div className='m-5'>
                            <div className='Slot-errreponse'>

                                <div className='flex flex-col items-center'>
                                    <img src={cautionimg}></img>
                                    <p style={{ fontWeight: 500, fontSize: 'large' }}>Disclaimer</p>
                                </div>
                                <p>Recommendations are approved by our Ayurveda physician Dr.Prathiban. The assessment and suggestions provided here are for health and wellness purposes only. Please do not self-medicate. Consult an Ayurveda practitioner or medical professional for accurate guidance.</p>
                            </div>
                        </div>

                    </div>

            }

        </div>
    );
}

export default Diet;
