import React, { useEffect } from 'react'
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { useNavigate, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import gmailIcon from '../Assets/images/G-mail.png'
import WhatsAppIcon from '../Assets/images/Whatsapp.png';
import { Container, Title, Accordion, rem, createStyles } from '@mantine/core';
import WellnessStore from '../DataRepo/WellnessStore';
function HelpNSupport() {
    const navigate = useNavigate();
    const { Profile, getProfile } = WellnessStore()

    const encodedMessage = `Hi,my name is ${Profile?.firstname}.I need your assistance.My user ID is ${Profile?.UID}.Can you please help me with`

    const useStyles = createStyles((theme) => ({
        wrapper: {
            paddingTop: `calc(${theme.spacing.xl} * 2)`,
            paddingBottom: `calc(${theme.spacing.xl} * 2)`,
            minHeight: 650,
        },

        title: {
            marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
        },

        item: {
            borderRadius: theme.radius.md,
            marginBottom: theme.spacing.lg,
            border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
                }`,
        },
    }));

    const { classes } = useStyles();
    const faqs = [
        {
            question: "How does Ayurveda differ from modern medicine?",
            answer: "Ayurveda is holistic and personalized, using natural remedies to address imbalances, while modern medicine focuses on symptoms with pharmaceuticals."
        },
        {
            question: "What are Doshas?",
            answer: "Doshas are the three energies in Ayurveda—Vata, Pitta, and Kapha—that govern bodily functions and health."
        },
        {
            question: "How should I incorporate Ayurvedic practices into my daily routine?",
            answer: "Incorporate Ayurvedic practices by waking early, practicing self-care, eating balanced meals for your dosha, staying hydrated, and getting enough rest."
        },
        {
            question: "Is there scientific evidence supporting Ayurveda?",
            answer: "Yes, scientific studies support Ayurveda's efficacy in herbal remedies, lifestyle interventions, and mind-body practices for various health conditions."
        },
        {
            question: "What privacy measures are in place to protect my data?",
            answer: "We implement robust security protocols to protect your data, including encryption and compliance with privacy regulations"
        }
    ]

    useEffect(() => {
        getProfile()
    })
    return <div className='All-products-section-main' style={{ position: 'relative', height: '100vh', width: '100vw' }}>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Help and Support</p>
            </div>
        </nav>
        <div className='Helpnsupport-section'>
            <div className='Helpnsupport-landing-section'>
                <div>
                    <p>
                        How can we help you?
                    </p>
                </div>
                <div>
                    <p>
                        We're available to resolve any technical concerns
                    </p>
                </div>
                <div>
                    <div
                        onClick={() => {
                            window.open(`https://wa.me/+919363135851?text=${encodedMessage}`, '_blank');
                        }}
                    >
                        <img src={WhatsAppIcon} />
                    </div>

                    <div onClick={() => {
                        window.location.href = `mailto:contact@ayurai.io?subject=Help&body=${encodedMessage}`
                    }}
                    >
                        <img src={gmailIcon} />
                    </div>
                </div>
            </div>
            {faqs &&
                <div className='helpnsupportfaq'>
                    <p>Frequently Asked Questions</p>
                    <Accordion variant="separated" id="Accordion" className='mt-3'>
                        {faqs?.map((faq, index) => {
                            return (
                                <Accordion.Item style={{ backgroundColor: "#F1EFEC", color: "#7C7870" }} className={classes.item} key={index} value={faq.question}>
                                    <Accordion.Control style={{ color: "#7C7870" }}>{faq?.question}</Accordion.Control>
                                    <Accordion.Panel>{faq?.answer}</Accordion.Panel>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion>
                </div>
            }
        </div>
    </div>
}

export default HelpNSupport