import React, { useState, useRef, useEffect } from 'react';
import Lottie from 'lottie-react';
import HomeImg from '../Assets/images/Areas of improvement.png';
import WelcomeScreenanimation from '../Assets/animations/welcomscreenrotation.json';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
// import './Slide.css'
import Vataimg from '../Assets/images/Vata.png'
import vikritinext from '../Assets/images/vikritinext.png'
import pittaimg from '../Assets/images/Pitta.png'
import kaphaimg from '../Assets/images/Kapha.png'
import prakritiimg from '../Assets/images/Prakriti.png'
import vikiritiimg from '../Assets/images/Vikriti.png'
import DietPlanIcon from '../Assets/images/Diet Plan Icon.png'
import lifestyleicon from '../Assets/images/Lifestyle Practices Icon.png'
import TeleIcon from '../Assets/images/Teleconsultation Icon.png'
import ChatIcon from '../Assets/images/Chat Icon.png'
import RecipesIcon from '../Assets/images/Recipes Icon.png'
import HerbalProductsIcon from '../Assets/images/Herbal Products Icon.png'
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';

function OnBoardingScreen2() {

    const holistic_care = [{
        img: DietPlanIcon,
        name: "Diet Plans"
    }, {
        img: RecipesIcon,
        name: "Recipes"
    },
    {
        img: lifestyleicon,
        name: "Lifestyle\nPractices"
    }, {
        img: HerbalProductsIcon,
        name: "Herbal\nProducts"
    }]

    const Preventative_measures = [
        {
            img: TeleIcon,
            name: "Tele-Consultations"
        },
        {
            img: ChatIcon,
            name: "Chat with Ayurveda Expert "
        }
    ]

    const navigate = useNavigate()

    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://aiwellassets.s3.ap-south-1.amazonaws.com/Slide.css';
        document.head.appendChild(link);
        return () => {
            document.head.removeChild(link);
        };
    }, []);

    return (
        <div className="Onboarding-Screen">
            <div className="flex flex-col items-center w-auto" style={{ width: '100%' }}>

                <div style={{ justifySelf: "flex-start" }} className='flex flex-col items-center'>
                    <div className="Welcome-section-animation">
                        <Lottie animationData={WelcomeScreenanimation} />
                    </div>
                    <p className='text-center Welcome-section-animation-txt'>Why Ayurveda?</p>
                </div>

                <div className="container" style={{ justifySelf: "center" }}>
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}

                        loop={true}

                        pagination={{ clickable: true }}

                        modules={[EffectCoverflow, Pagination, Navigation]}

                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className='Swiper-slide1-div'>
                                <p>Ayurveda, a 5,000 years-old wellness system, emphasizes balance through the harmony of three doshas:</p>
                                <div>
                                    <div className='Swiper-slide1-div-info'>
                                        <div>
                                            <img src={Vataimg}></img>
                                        </div>
                                        <div>
                                            <h1>Vata</h1>
                                            <p>Governs movement in the nervous <br />system, bones, joints, lungs, & colon</p>
                                        </div>
                                    </div>

                                    <div className='Swiper-slide1-div-info'>
                                        <div>
                                            <img src={pittaimg}></img>
                                        </div>
                                        <div>
                                            <h1>Pitta</h1>
                                            <p>Regulates metabolism in the <br />
                                                digestive system, blood, heart, & eyes</p>
                                        </div>
                                    </div>

                                    <div className='Swiper-slide1-div-info'>
                                        <div>
                                            <img src={kaphaimg}></img>
                                        </div>
                                        <div>
                                            <h1>Kapha</h1>
                                            <p>Ensures stability and lubrication <br />in muscles, lungs, fat, & brain</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='Swiper-slide1-div'>
                                <div className="dbox">

                                    <div className="dheading_tr">
                                        <img src={vikiritiimg}></img>
                                        <h1>Vikriti</h1>
                                        <p>Vikriti refers to the present imbalances in your body and mind, often resulting from factors like diet, stress, or lifestyle choices</p>
                                    </div>

                                    <div className="dheading_bl">
                                        <img src={prakritiimg}></img>
                                        <h1>Prakriti</h1>
                                        <p>Prakriti refers to your natural constitution — the unique combination of Vata, Pitta, & Kapha that defines your individual physical and
                                            mental traits</p>
                                    </div>

                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='Swiper-slide1-div'>
                                <p>Achieving Balance, Preventing Imbalance</p>
                                <div className='holistic-care mt-5'>
                                    <p>Holistic Care</p>
                                    <div className='mt-2'>
                                        {
                                            holistic_care.map((e) => {
                                                return <div>
                                                    <img src={e.img}></img>
                                                    <p>{e.name}</p>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='holistic-care mt-5'>
                                    <p>Preventive measures</p>
                                    <div className='mt-2'>
                                        {
                                            Preventative_measures.map((e) => {
                                                return <div>
                                                    <img src={e.img}></img>
                                                    <p>{e.name}</p>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>

                <div onClick={() => {
                    navigate('/wellness')
                }} style={{ position: 'absolute', justifySelf: "flex-end", bottom: '10px', left: '50%', transform: 'translateX(-50%)' }} className='Vikritinextimgicon'>
                    <img src={vikritinext}></img>
                </div>

            </div>
        </div>
    );
}

export default OnBoardingScreen2;
