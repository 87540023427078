import React, { useEffect, useState } from 'react'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { useNavigate } from 'react-router-dom'
import { IconButton, Button } from '@mui/material'
import { profileModel } from '../Axios/AxiosConfig'
import { getUserDetails } from '../Auth/AuthUtil'
import loadingimg from '../Assets/images/loading image 1.png';
import { CircularProgress } from '@mui/material';
import ProfileCard from './profilecard'
import DoshaBarChart from '../Vikriti/VikritiChart'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import WellnessStore from '../DataRepo/WellnessStore'
import { useMediaQuery } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

function ProfilePage() {
    const navigate = useNavigate()
    const [isprofileloading, setprofileloading] = useState(true)
    const [prakritiData, setPrakritiData] = useState([])
    const [ProfileData, setProfileData] = useState([])
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [isLoading, setLoading] = useState(true);

    const { Selected_Wellness, getSelected_Wellness } = WellnessStore();

    const [vikritiData, setvikritiData] = useState([])

    const [allWellnessCategories, setWellnessCategories] = useState([])

    const [vikritiresultData, setVikritiResultData] = useState([])

    const isMobile = useMediaQuery('(max-width:600px)');

    const getProfile = async (id) => {
        try {
            let res = await profileModel.get(`/Profile/${id}`)
            if (res.status === 200) {
                const profile = res?.data
                setProfileData(profile)
                setprofileloading(false)
            }
        }
        catch (err) {
            console.log(err?.response?.data)
        }
    }

    const getPrakritiData = async (id) => {
        try {
            let res = await profileModel.get(`/GetPrakritidata/${id}`);
            if (res.status === 200) {
                setPrakritiData(res.data)
            }
        } catch (err) {
            console.log(err?.response?.data);
        }
    };

    function calculateDoshaValues(vikritiResponse) {
        let v = 0;
        let p = 0;
        let k = 0;

        const uniqueCategories = new Set();

        vikritiResponse?.vikritiList?.forEach((vikriti) => {
            vikriti.dosha_increase.split(",").forEach((dosha) => {
                switch (dosha.trim().toLowerCase()) {
                    case "v":
                        v++;
                        break;
                    case "p":
                        p++;
                        break;
                    case "k":
                        k++;
                        break;
                }
            });

            vikriti.dosha_decrease.split(",").forEach((dosha) => {
                switch (dosha.trim().toLowerCase()) {
                    case "v":
                        v--;
                        break;
                    case "p":
                        p--;
                        break;
                    case "k":
                        k--;
                        break;
                }
            });

            uniqueCategories.add(vikriti.category);
        });

        const totalDoshas = vikritiResponse?.vikritiList?.length;

        const vPercent = (v / totalDoshas) * 100;
        const pPercent = (p / totalDoshas) * 100;
        const kPercent = (k / totalDoshas) * 100;

        return {
            doshaValues: { v: vPercent, p: pPercent, k: kPercent },
            uniqueCategories: Array.from(uniqueCategories)
        };
    }

    function calculatePercentages(vikritiResponse) {
        const { doshaValues, uniqueCategories } = calculateDoshaValues(vikritiResponse);

        const vataFloat = doshaValues?.v;
        const pittaFloat = doshaValues?.p;
        const kaphaFloat = doshaValues?.k;

        const vataPulse = vikritiResponse?.vataPulseScore || 0;
        const pittaPulse = vikritiResponse?.pittaPulseScore || 0;
        const kaphaPulse = vikritiResponse?.kaphaPulseScore || 0;

        const totalPulse = Math.abs(vataPulse) + Math.abs(pittaPulse) + Math.abs(kaphaPulse);
        let vataFloatPulse = 0, pittaFloatPulse = 0, kaphaFloatPulse = 0;

        if (totalPulse !== 0) {
            vataFloatPulse = (vataPulse * 100) / totalPulse;
            pittaFloatPulse = (pittaPulse * 100) / totalPulse;
            kaphaFloatPulse = (kaphaPulse * 100) / totalPulse;
        }

        const vataScore = vataFloat || 0;
        const vataPulseScore = vataFloatPulse || 0;
        const pittaScore = pittaFloat || 0;
        const pittaPulseScore = pittaFloatPulse || 0;
        const kaphaScore = kaphaFloat || 0;
        const kaphaPulseScore = kaphaFloatPulse || 0;

        const vPercent = (0.7 * vataScore + 0.3 * vataPulseScore);
        const pPercent = (0.7 * pittaScore + 0.3 * pittaPulseScore);
        const kPercent = (0.7 * kaphaScore + 0.3 * kaphaPulseScore);

        const doshaPercentages = { v: Math.floor(vPercent), p: Math.floor(pPercent), k: Math.floor(kPercent) };


        return { doshaPercentages, uniqueCategories };
    }

    const getvikritiData = async (id) => {
        try {
            let res = await profileModel.get(`/Vikriti/${id}`);
            if (res.status === 200) {
                setVikritiResultData(calculatePercentages(res.data))
                setvikritiData(res.data)
                setLoading(false)
            }
        } catch (err) {
            console.log(err?.response?.data);
        }
    };

    const findDominantDosha = (prakritiState) => {
        if (prakritiState) {
            if (prakritiState.vata >= Math.max(prakritiState.pitta, prakritiState.kapha)) {
                return ['Vata', "Dominant", `${prakritiState?.vata?.toFixed(0)}%`];
            } else if (prakritiState.pitta >= Math.max(prakritiState.vata, prakritiState.kapha)) {
                return ['Pitta', "Dominant", `${prakritiState?.pitta?.toFixed(0)}%`];
            } else {
                return ['Kapha', "Dominant", `${prakritiState?.kapha?.toFixed(0)}%`];
            }
        }
        return ['', "Dominant", , 0];
    };

    useEffect(() => {
        const userDetails = getUserDetails()
        getProfile(userDetails?.UID)
        getPrakritiData(userDetails?.UID)
        getvikritiData(userDetails?.UID)
    }, [])


    useEffect(() => {
        getSelected_Wellness()
    }, [])

    const handleCardClick = (index) => {
        setWellnessCategories(prevCategories =>
            prevCategories?.map((category, i) =>
                i === index
                    ? { ...category, selected: !category.selected }
                    : category
            )
        );
    };

    const SubmitWellnessInterest = async () => {
        try {
            let res = await profileModel.post('/AddwellnessCategories', {
                UID: getUserDetails()?.UID,
                Categories: allWellnessCategories
            })

            if (res.status === 200) {
                handleClose()
                getSelected_Wellness()
            }
        }
        catch (err) {
            console.log(err.response.data)
        }
    }

    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Profile </p>
            </div>
        </nav>
        {
            isprofileloading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                    <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                    </div>
                </div>
            </div> :

                <div className='user-Profile-main-section'>
                    <div className='Profile-section'>
                        <ProfileCard profileData={ProfileData} />
                    </div>
                    <div className='PrakritiAnalayais-section'>
                        <div>
                            <p>Vikriti Analysis</p>
                            <div>
                                <Button
                                    style={{
                                        width: '100%',
                                        textTransform: 'none',
                                        borderRadius: '0',
                                        border: '1px solid #DA6E0B ',
                                        color: '#DA6E0B',
                                        boxSizing: 'border-box',
                                        backgroundColor: "#F4ECD6",
                                        borderRadius: '5px',
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        padding: '10px'
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        navigate('/VikritiIntro')
                                    }}
                                >
                                    Retake
                                </Button>
                            </div>
                        </div>
                        <div className="VikritiChart">
                            <DoshaBarChart vikritiresultData={vikritiresultData} style={{ border: "1px solid red" }} />
                        </div>
                    </div>
                    <div className='PrakritiAnalayais-section'>
                        <div>
                            <p>Prakriti Analysis</p>
                            <div>
                                <Button
                                    style={{
                                        width: '100%',
                                        textTransform: 'none',
                                        borderRadius: '0',
                                        border: '1px solid #DA6E0B ',
                                        color: '#DA6E0B',
                                        boxSizing: 'border-box',
                                        backgroundColor: "#F4ECD6",
                                        borderRadius: '5px',
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        padding: '10px'
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        navigate('/PrakritiIntro')
                                    }}
                                >
                                    Retake
                                </Button>
                            </div>
                        </div>
                        <div className="circleIndicatorContainer">
                            <div style={{ position: 'relative', width: 250, height: 250 }}>

                                <CircularProgressbar
                                    value={prakritiData?.vata}
                                    className="first-circle"
                                    strokeWidth={1}
                                    styles={buildStyles({
                                        pathColor: "#75623F",
                                        trailColor: "transparent",
                                        textColor: "#ffffff",
                                    })}
                                />

                                <div style={{ position: 'absolute', top: '-5%', transform: 'translateY(-0%)', right: '50%', textAlign: 'right' }}>
                                    <span className='prakritilabel'>Vata</span>
                                </div>

                                <div style={{ position: 'absolute', top: 10, left: 10, width: 230, height: 230 }}>

                                    <CircularProgressbar
                                        value={prakritiData?.pitta}
                                        className="second-circle"
                                        strokeWidth={1}
                                        styles={buildStyles({
                                            pathColor: "#FF5C00",
                                            trailColor: "transparent",
                                            textColor: "#ffffff",
                                        })}
                                    />
                                </div>

                                <div style={{ position: 'absolute', top: '-1%', transform: 'translateY(-0%)', right: '50%', textAlign: 'right' }}>
                                    <span className='prakritilabel' >Pitta</span>
                                </div>

                                <div style={{ position: 'absolute', top: 20, left: 20, width: 210, height: 210 }}>

                                    <CircularProgressbar
                                        value={prakritiData?.kapha}
                                        className="third-circle"
                                        strokeWidth={1}
                                        styles={buildStyles({
                                            pathColor: "#6CBA6C",
                                            trailColor: "transparent",
                                            textColor: "#ffffff",
                                        })}
                                    />

                                </div>

                                <div style={{ position: 'absolute', top: '2%', transform: 'translateY(-0%)', right: '50%', textAlign: 'right', }}>
                                    <span className='prakritilabel'>Kapha</span>
                                </div>


                                <div
                                    className="inner-circle-content"
                                    style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}
                                >
                                    <span className="inner-circle-text">
                                        <div className="dominant-prakriti-doshas">
                                            {findDominantDosha(prakritiData)?.map((e) => {
                                                return <p key={e}>{e}</p>;
                                            })}
                                        </div>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='Shop-Wellness-Categories mt-3' style={{
                        backgroundColor: "#F4ECD6", borderRadius: "8px", padding: "10px"
                    }}>
                        <div className='myCate'>
                            <p>My Categories</p>
                            <div>
                                <Button
                                    style={{
                                        width: '100%',
                                        textTransform: 'none',
                                        borderRadius: '0',
                                        border: '1px solid #DA6E0B ',
                                        color: '#DA6E0B',
                                        boxSizing: 'border-box',
                                        backgroundColor: "#F4ECD6",
                                        borderRadius: '5px',
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        padding: '10px'
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setWellnessCategories(Selected_Wellness)
                                        handleClickOpen()
                                    }}
                                >
                                    Add
                                </Button>
                            </div>

                        </div>

                        <div className='shop-selected-wellness-categories mt-5'>
                            {Selected_Wellness?.filter((e) => e.selected)?.map((category, index) => (
                                <div
                                    key={index}
                                    className={`WellnessCard`}
                                    onClick={() => {
                                        navigate(`/Products?category=${category?.name}`)
                                    }}
                                >
                                    <div className={`WellnessImg`}>
                                        <img src={category.imageUrl} alt={category.name} />
                                    </div>
                                    <p className='mt-2'>
                                        {category.name}
                                    </p>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
        }
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogContent>
                <div className='addcatealtdiv'>
                    <p>Add / change Categories</p>
                    <p>Here is your unselected categories</p>
                    <div>
                        {
                            allWellnessCategories?.map((e, i) => {
                                return <div className='addcatealtdivitemcard' style={e.selected ? { border: "1px solid #DA6E0B", borderRadius: "5px" } : {}}
                                    onClick={() => handleCardClick(i)}>
                                    <div>
                                        <img src={e.imageUrl} />
                                    </div>
                                    <div>
                                        <p>{e.name}</p>
                                    </div>
                                </div>
                            })
                        }
                    </div>

                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            marginTop: "1rem",
                            backgroundColor: '#DA6E0B',
                            color: 'white',
                            border: 'none',
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: '10px'
                        }}
                        onClick={() => {
                            SubmitWellnessInterest()
                        }}
                    >
                        Change
                    </Button>
                </div>
            </DialogContent>

        </Dialog>
    </div>
}

export default ProfilePage