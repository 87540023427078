import React, { useState, useEffect } from 'react';
import loadingimg from '../Assets/images/loading image 1.png'
const ImageZoom = ({ imageUrl }) => {
    
    const [zoomStyle, setZoomStyle] = useState({
        '--url': `url(${imageUrl})`,
        '--zoom-x': '0%',
        '--zoom-y': '0%',
        '--display': 'none',
    });

    useEffect(() => {
        setZoomStyle({
            '--url': `url(${imageUrl || loadingimg})`,
            '--zoom-x': '0%',
            '--zoom-y': '0%',
            '--display': 'none',
        });
    }, [imageUrl]);

    const handleMouseMove = (event) => {

        const imageZoom = event.currentTarget;
        const { offsetWidth, offsetHeight } = imageZoom;
        const { offsetX, offsetY } = event.nativeEvent;

        const pointer = {
            x: (offsetX * 100) / offsetWidth,
            y: (offsetY * 100) / offsetHeight,
        };

        setZoomStyle((prevState) => ({
            ...prevState,
            '--display': 'block',
            '--zoom-x': `${pointer.x}%`,
            '--zoom-y': `${pointer.y}%`,
        }));
        
    };

    const handleMouseOut = () => {
        setZoomStyle((prevState) => ({
            ...prevState,
            '--display': 'none',
        }));
    };

    return (
        <div
            id="imageZoom"
            style={zoomStyle}
            onMouseMove={handleMouseMove}
            onMouseOut={handleMouseOut}
        >
            <img src={imageUrl || loadingimg} alt="Zoomable" />
        </div>
    );
};

export default ImageZoom;
