import create from 'zustand';
import { teleConsultationModel } from '../Axios/AxiosConfig';
import { getUserDetails } from '../Auth/AuthUtil';

const SubscriptionStore = create((set) => ({
    Subscriptiondetails: [],
    isSubscribed: false,
    Subscriptiondetailsloading: true,

    getSubscriptiondetails: async () => {

        try {
            const UID = getUserDetails()?.UID
            const res = await teleConsultationModel.get(`subscription/${UID}`)
            if (res.status === 200) {
                set({
                    Subscriptiondetails: res?.data?.data,
                    Subscriptiondetailsloading: false,
                    isSubscribed: true
                })
            }
        }

        catch (err) {
            if (err?.response?.data.message === 'User Not yet Subscribed') {
                set({
                    isSubscribed: false
                })
            }
            else if (err?.response?.data.message === 'Package Expired') {
                set({
                    isSubscribed: false,
                    Subscriptiondetails: err?.response?.data?.data
                })
            }

            console.log(err?.response?.data);
        }

    },

}))

export default SubscriptionStore