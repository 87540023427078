import React from 'react'
import create from 'zustand'
import { shopModel } from '../Axios/AxiosConfig';
import { getUserDetails } from '../Auth/AuthUtil';
import axios from 'axios';

const WishlistStore = create((set, get) => ({
    WishlistItems: [],
    isWishlistLoading: true,

    addWishlist: async (id) => {
        try {
            const userId = getUserDetails()?.UID
            const res = await shopModel.post('/wishlist/add', {
                userId,
                productId: id
            })

            if (res.status === 200) {
                set(() => ({
                    WishlistItems: res.data,
                    isWishlistLoading: false
                }));
            }

        }
        catch (err) {
            console.log(err?.response?.data)
        }
    },


    removeWishlist: async (id) => {
        try {
            const userId = getUserDetails()?.UID
            const res = await shopModel.post('/wishlist/remove', {
                userId,
                productId: id
            })

            if (res.status === 200) {
                set(() => ({
                    WishlistItems: res.data,
                    isWishlistLoading: false
                }));
            }

        }
        catch (err) {
            console.log(err?.response?.data)
        }
    },

    getWishlistItems: async () => {
        try {
            const userId = getUserDetails()?.UID
            const res = await shopModel.get(`/wishlist/${userId}`)

            if (res.status === 200) {
                set(() => ({
                    WishlistItems: res.data,
                    isWishlistLoading: false
                }));
            }

        }
        catch (err) {
            console.log(err?.response?.data)
        }
    }

}))

export default WishlistStore