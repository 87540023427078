import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function DoshaBarChart({ vikritiresultData }) {
    const data = [
        { name: 'V', value: vikritiresultData?.doshaPercentages?.v.toFixed(2) || 0 },
        { name: 'P', value: vikritiresultData?.doshaPercentages?.p.toFixed(2) || 0 },
        { name: 'K', value: vikritiresultData?.doshaPercentages?.k.toFixed(2) || 0 },
    ];

    const chartData = {
        labels: data.map(d => d.name),
        datasets: [
            {
                label: '',
                data: data.map(d => d.value),
                borderRadius: 100,
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea) {
                        return null;
                    }
                    const gradientV = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                    gradientV.addColorStop(0, '#BDC5CC');
                    gradientV.addColorStop(1, 'rgba(189, 197, 204, 0.33)');

                    const gradientP = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                    gradientP.addColorStop(0, '#CDB9AB');
                    gradientP.addColorStop(1, 'rgba(205, 185, 171, 0.32)');

                    const gradientK = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                    gradientK.addColorStop(0.38, '#5BC35B');
                    gradientK.addColorStop(1, 'rgba(91, 195, 91, 0.28)');

                    const gradients = [gradientV, gradientP, gradientK];
                    return gradients[context.dataIndex];
                },

                borderWidth: 1,

            }
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                color: 'black',
                formatter: function(value, context) {
                    return value;
                }
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw}%`;
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                // max: 100,
                grid: {
                    display: false
                },
                title: {
                    display: false,
                    text: 'Percentage',
                    font: {
                        size: 14,
                        weight: 'bold',
                    }
                },
            },
            x: {
                grid: {
                    display: false
                }
            }
        }
    };

    return <Bar data={chartData} options={options} />;
}
