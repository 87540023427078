import React from 'react'
import step1 from '../Assets/images/step-1.png'
import step2 from '../Assets/images/step-2.png'
import step3 from '../Assets/images/step-3.png'
import step4 from '../Assets/images/step-4.png'
import WelcomeScreenanimation from '../Assets/animations/welcomscreenrotation.json';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import vikritinext from '../Assets/images/vikritinext.png'
import { useNavigate } from 'react-router-dom'
import Doc_vector from '../Assets/images/doc_vector.png'
import showers from '../Assets/animations/prakriticonfetti.json'
import Lottie from 'lottie-react'
import { Button } from '@mui/material'

function TeleConsultationIntro() {
    const steps = [step1, step2, step3, step4]

    const navigate = useNavigate()

    const settings = {
        dots: true,
        speed: 500,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return <div className='Welcome-page-section' >
        <div className='Wellness-categories-section' style={{ flex: 0.5, width: '100%' }}>

            {/* <div className="Lottie-background">
                <Lottie animationData={showers} loop={true} />
            </div> */}

            <div className="flex items-center" style={{ width: "100%" }}>

                <div className="Welcome-section-animation" style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                    <Lottie animationData={WelcomeScreenanimation} />
                </div>

                <div
                    className="skip-btn"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    <p style={{ color: "#DA6E0B", borderBottom: "1px solid #DA6E0B" }}>Book later</p>
                </div>
            </div>



            <p className='text-center pkg-info-T'>
                Schedule a teleconsultation  <br />with our Ayurveda Doctor
            </p>

            {/* <p className='text-center'>Follow these 4 Simple Steps to <br />Start Your Transformation</p> */}

            {/* <div className='Carosel-container'>
                <Slider {...settings}>
                    {steps.map((step, index) => (
                        <div key={index} className='Carosel-item'>
                            <img
                                src={step}
                                alt={`Step ${index + 1}`}
                                style={{
                                    borderRadius: '10px',
                                }}
                            />
                        </div>
                    ))}
                </Slider>
            </div> */}

            <div className='Doctor_vector'>
                <img src={Doc_vector} />
            </div>


            <div className=''>
                <Button
                    style={{
                        width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: "5px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: "10px"
                    }}

                    onClick={() => {
                        navigate('/DoctorProfiles')
                    }
                    }
                >
                    Book an appointment
                </Button>
            </div>

        </div>
    </div>
}

export default TeleConsultationIntro