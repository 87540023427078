import React, { useState, useEffect } from 'react'
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Rating from '@mui/material/Rating';
import { Box, Skeleton, Grid, IconButton, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TeleConsultationStore from '../DataRepo/TeleConsultationStore';
function DoctorProfiles() {
    const navigate = useNavigate()
    const { Doctors, getDoctors, isDoctorsLoading } = TeleConsultationStore();
    const [search, setsearch] = useState('')

    useEffect(() => {
        getDoctors()
    }, [])

    return (
        <div className='All-products-section-main'>

            <nav className='flex flex-row p-2 items-center justify-between'>
                <div className='flex flex-row items-center'>
                    <IconButton onClick={() => navigate('/')}>
                        <ChevronLeft />
                    </IconButton>
                    <p className='mb-0'>Doctor Profiles</p>
                </div>
            </nav>

            <div className='Reports-main-page'>
                {
                    isDoctorsLoading ? <div className='Doctors-Container'>
                        <Box
                            display="flex"
                            alignItems="center"
                            padding="20px"

                            borderRadius="10px"
                            width="100%"

                        >
                            <Skeleton variant="rectangular" width={'10rem'} height={100} borderRadius="10px" />
                            <Box marginLeft="20px" width="100%">
                                <Skeleton variant="text" width="60%" height={30} />
                                <Skeleton variant="text" width="40%" height={20} />
                                <Box display="flex" alignItems="center" marginTop="10px">
                                    <Skeleton variant="circular" width={20} height={20} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            padding="20px"
                            backgroundColor="#f0f0f0"
                            borderRadius="10px"
                            width="100%"
                            maxWidth="500px"
                        >
                            <Skeleton variant="rectangular" width={'10rem'} height={100} borderRadius="10px" />
                            <Box marginLeft="20px" width="100%">
                                <Skeleton variant="text" width="60%" height={30} />
                                <Skeleton variant="text" width="40%" height={20} />
                                <Box display="flex" alignItems="center" marginTop="10px">
                                    <Skeleton variant="circular" width={20} height={20} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                    <Skeleton variant="circular" width={20} height={20} style={{ marginLeft: 8 }} />
                                </Box>
                            </Box>
                        </Box>
                    </div> :
                        <div className='Doctors-Container'>
                            {Doctors &&
                                Doctors?.filter((e) => {
                                    if (search === '') {
                                        return true;
                                    } else if (e.name.toLowerCase().includes(search.toLowerCase())) {
                                        return true;
                                    }
                                    return false;
                                }).map((e, index) => (
                                    <div key={index} style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            navigate(`/BookAppointment?id=${e.PID}`)
                                        }}>
                                        <div className='doc-img-card'>
                                            <img src={e?.img} alt={e?.name} />
                                        </div>
                                        <div>
                                            <p>{e?.name}</p>
                                            <p>{e?.speciality}</p>
                                            <p>{e?.course_details}</p>
                                            <Rating name="half-rating-read" value={Number(parseInt(e?.rating))} precision={0.5} readOnly />
                                            <div style={{ width: "100%" }}>
                                                <Button style={{
                                                    width: '100%',
                                                    textTransform: 'none',
                                                    backgroundColor: '#DA6E0B',
                                                    color: '#FDFDFD',
                                                    border: '1px solid #DA6E0B',
                                                    boxSizing: 'border-box',
                                                    paddingRight: "3rem",
                                                    paddingLeft: "3rem",
                                                    borderRadius: "5px",
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontWeight: 500,
                                                    padding: "5px"
                                                }}>
                                                    Book Now
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                        </div>
                }
            </div>

        </div>
    )
}

export default DoctorProfiles