import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { IconButton, Button } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Cartempty from '../Assets/images/empty cart.png'
import loadingimg from '../Assets/images/loading image 1.png'
import useCartStore from '../DataRepo/CartStore';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { CircularProgress } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios'
import success from '../Assets/animations/success lottie.json'
import locIcon from '../Assets/images/orange_location_icon.png';
import elocIcon from '../Assets/images/edit_location_icon.png';
import blocIcon from '../Assets/images/location_icon.png'
import addimg from '../Assets/images/add icon 1.png'
import { getUserDetails } from '../Auth/AuthUtil';
import offerimg from '../Assets/images/offer.png'
import offerApplied from '../Assets/animations/offerapplied.json'
import Lottie from 'lottie-react';
import useAddressStore from '../DataRepo/AddressStore';
import pauymentsuccess from '../Assets/Raw/paymentsuccess_ding.mp3'
import { shopModel } from '../Axios/AxiosConfig';
import { amber } from '@mui/material/colors';

function Cart() {
    const navigate = useNavigate();
    const [hash, sethash] = useState('')
    const [coupon, setcoupon] = useState('')
    const [couponError, setCouponError] = useState('')
    const [Transcation_id, setTranscation] = useState('')
    const [redeemcredits, setRedeemcredits] = useState(0)
    const [PendingOrders, setPendingOrders] = useState([])
    const { cartItems, updateQuantity, decreaseQuantity, isCartLoading, totalPrice, deliveryCharges, getCouponDetails, Coupons, CouponDetails, setCoupon, removeItem, getCart, clearCart, emptyCart } = useCartStore();
    const { currentAddress, getAddress } = useAddressStore();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [calculatedTotalPrice, setCalculatedTotalPrice] = useState(0);
    const ringtone = new Audio(pauymentsuccess)


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const userDetails = getUserDetails()

    useEffect(() => {
        setCalculatedTotalPrice(totalPrice());
    }, [cartItems, totalPrice]);

    useEffect(() => {
        getCouponDetails()
        getCart()
        getAddress()
    }, [])

    const initiatePayment = async () => {
        if (currentAddress) {
            try {
                const response = await axios.post('https://api.ayurai.in/api/data/payment/makepayment',
                    {
                        amount: ((price + deliveryCharges) - ((price / 100) * discount)).toString(),
                        txnid: Date.now().toString(),
                        phone: currentAddress?.Phone,
                        email: currentAddress?.Email,
                        productinfo: "Aiwell Products",
                        firstname: currentAddress?.Name,
                    }
                );

                if (response.status === 200) {
                    sethash(response?.data?.hash)
                    setTranscation(response?.data?.TranscationID)
                    handleClickOpen()
                    intiateOrderProcess()
                }
            }

            catch (error) {
                console.error('Error initiating payment:', error?.response);
            }
        }

        else {
            navigate('/Address')
        }
    };

    const handleCoupon = () => {
        let ValidCoupon = Coupons.find((e) => e?.couponName?.toLowerCase() === coupon?.toLowerCase()?.trim());
        if (ValidCoupon) {
            setCoupon(ValidCoupon);
        } else {
            setCouponError("Please enter a valid coupon code");
        }
    };

    const intiateOrderProcess = async () => {
        try {
            let res = await shopModel.post('/intiateOrderProcess', {
                userid: userDetails?.UID,
                Cart: cartItems,
                discount,
                redeemcredits,
                Address: currentAddress
            })

            if (res.status === 200) {
                setPendingOrders(res?.data?.results)
            }
        }

        catch (err) {
            console.log(err?.response?.data)
        }
    }

    const Placeorder = async () => {
        try {

            let res = await shopModel.post('/CreateAndUpdateOrder', {
                userid: userDetails?.UID,
                Orders: PendingOrders,
            })

            if (res.status === 200) {
                clearCart()

                handleClickOpen2()

                handleClose()

                ringtone.play()

                setCoupon(null)

                emptyCart()

                setTimeout(() => {
                    navigate('/MyOrders')
                }, 2500)
            }

        }

        catch (err) {
            console.log(err?.response?.data)
        }
    }


    const checkout = () => {
        var data = {
            key: 'Ey2EbY',
            hash: hash,
            txnid: Transcation_id.toString(),
            amount: ((price + deliveryCharges) - ((price / 100) * discount)).toString(),
            firstname: currentAddress?.Name,
            email: currentAddress?.Email,
            phone: currentAddress?.Phone,
            productinfo: 'Aiwell Products',
            surl: 'http://thirdparty.com/testresponse.php',
            furl: 'http://thirdparty.com/testresponse.php',
        };

        var handlers = {
            responseHandler: function (BOLT) {
                if (BOLT.response.txnStatus == "SUCCESS") {
                    Placeorder()
                    console.log('Your payment has been successful');
                }
                if (BOLT.response.txnStatus == "FAILED") {
                    console.log('Payment failed. Please try again.');
                }
                if (BOLT.response.txnStatus == "CANCEL") {
                    console.log('Payment failed. Please try again.');
                }
            },

            catchException: function (BOLT) {
                console.log(`Payment failed. Please try again. ${BOLT}`);
            }
        };
        window.bolt.launch(data, handlers);
    }

    const price = parseInt(calculatedTotalPrice);

    const discount = CouponDetails?.discount || 0;

    const [paymentStatus, setPaymentStatus] = useState(null);

    const order = {
        orderId: "ORDER12345",
        amount: 10000,
    };

    const invokePayPage = async (type) => {
        try {
            setPaymentStatus("processing");

            const res = await shopModel.post("generate-token", {
                amount: order?.amount
            });

            if (!res.data) {
                throw new Error("Token URL not received");
            }

            const callback = async (responseStatus) => {
                if (responseStatus === "USER_CANCEL") {

                    console.log("Transaction canceled by the user");
                    window.PhonePeCheckout.closePage();
                    setPaymentStatus("failure");

                } else if (responseStatus === "CONCLUDED") {

                    console.log("Transaction concluded successfully");

                    setPaymentStatus("success");

                    await processOrder(order.orderId);

                }
                else {
                    window.PhonePeCheckout.closePage();
                }
            };

            if (window.PhonePeCheckout && window.PhonePeCheckout.transact) {
                window.PhonePeCheckout.transact({
                    tokenUrl: res.data,
                    type,
                    callback,
                });
            } else {
                console.error("PhonePeCheckout is not available");
                setPaymentStatus("failure");
                window.PhonePeCheckout.closePage();
            }
        } catch (error) {
            console.error("Error invoking PayPage:", error);
            setPaymentStatus("failure");
        }
    };

    const closePayPage = () => {
        if (window.PhonePeCheckout && window.PhonePeCheckout.closePage) {
            window.PhonePeCheckout.closePage();
            console.log("PayPage iFrame closed");
        } else {
            console.error("PhonePeCheckout closePage method not available");
        }
    };

    const processOrder = async (orderId) => {
        try {

            const response = await shopModel.post("process-order", {
                orderId,
            });

            console.log("Order processed successfully:", response.data);

        } catch (error) {
            console.error("Error processing order:", error);
            setPaymentStatus("failure");
        }
    };

    return (
        <div className='All-products-section-main'>
            <nav className='flex flex-row p-2'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Cart</p>
            </nav>
            {isCartLoading ?
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                    <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                        <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                        </div>
                    </div>
                </div> :
                cartItems.length > 0 ?
                    <div>
                        <div className='Cart-item-section'>
                            <div className='Cart-items-container'>
                                {cartItems?.map((e, i) => (
                                    <div key={i} className='Cart-item'>
                                        <div>
                                            <img src={e?.src} alt={e?.productName} style={{ mixBlendMode: "multiply" }} />
                                        </div>

                                        <div>
                                            <p className='mt-2 ml-1'>{e?.productName}</p>
                                            <p className='mt-2 ml-1'>{e?.desc}</p>
                                            <p className='mt-2 ml-1'>₹ {(e?.price)}</p>
                                        </div>

                                        <div>
                                            <IconButton onClick={() => {
                                                removeItem(e?.id)
                                            }}>
                                                <HighlightOffIcon />
                                            </IconButton>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                                <RemoveIcon
                                                    style={{ cursor: 'pointer' }}
                                                    fontSize='small'
                                                    onClick={(el) => {
                                                        el.stopPropagation();
                                                        decreaseQuantity(e?.id, userDetails?.UID);
                                                    }}
                                                />
                                                <span style={{ fontSize: '12px' }}>{e.quantity}</span>
                                                <AddIcon
                                                    style={{ cursor: 'pointer' }}
                                                    fontSize='small'
                                                    onClick={(el) => {
                                                        el.stopPropagation();
                                                        updateQuantity(e?.id, userDetails?.UID);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className='Cart-items-info'>
                                {CouponDetails &&
                                    <div className="CouponAppliedContainer">
                                        <div className="lottie-background">
                                            <Lottie animationData={offerApplied} />
                                        </div>
                                        <div className="content">
                                            <img src={offerimg} alt="Offer" />
                                            <div>
                                                <p>{CouponDetails?.couponName} Applied</p>
                                                <p>You have saved ₹{((price / 100) * discount).toFixed(2)} by applying a {discount}% discount for this order</p>
                                            </div>
                                        </div>
                                        <IconButton style={{ alignSelf: "flex-start", justifySelf: "flex-end", marginLeft: 'auto' }}>
                                            <HighlightOffIcon />
                                        </IconButton>
                                    </div>

                                }
                                {!CouponDetails &&
                                    <div className="coupon-container">
                                        <input type="text" placeholder="Enter your coupon code" className="coupon-input" onChange={(e) => {
                                            setcoupon(e.target.value)
                                            setCouponError('')
                                        }} />
                                        <button className="apply-button" onClick={handleCoupon} >Apply</button>
                                    </div>}

                                {couponError != '' &&
                                    <p className='couponError'>{couponError}</p>
                                }

                                {currentAddress &&
                                    <div className='Address-item flex flex-row justify-between mb-3'>
                                        <div className='flex flex-row justify-start'>
                                            <div className='locIcon'>
                                                <img src={locIcon} alt="Location Icon" />
                                            </div>
                                            <div className='Address-item-content'>
                                                <span>{currentAddress?.address_type}</span>
                                                <p>{currentAddress?.Name}</p>
                                                <p>{currentAddress?.address_details}</p>
                                                <p>{currentAddress?.Email}</p>
                                                <p>{currentAddress?.Phone}</p>
                                            </div>
                                        </div>
                                        <div
                                            className='locIcon'
                                            style={{ width: "2rem", cursor: "pointer" }}
                                            onClick={(el) => {
                                                el.stopPropagation();
                                                navigate('/Address')
                                            }
                                            }
                                        >
                                            <img src={elocIcon} alt="Edit Icon" />
                                        </div>
                                    </div>
                                }

                                {!currentAddress &&
                                    <div className='addAddressbtnct mb-3' onClick={() => {
                                        navigate('/Address')
                                    }}>
                                        <div>
                                            <img src={blocIcon} style={{ width: '20px' }}></img>
                                            <p>Add Delivery Address</p>
                                        </div>
                                        <div>
                                            <img src={addimg} style={{ width: '20px' }}></img>
                                        </div>
                                    </div>
                                }



                                <div className='Cart-total-container'>
                                    <div>
                                        <p>Order Details</p>
                                    </div>
                                    <div>
                                        <div>
                                            <p>Sub Total</p>
                                            <p>{`₹ ${calculatedTotalPrice}`}</p>
                                        </div>
                                        <div>
                                            <p>Delivery Charges</p>
                                            <p>{`₹ ${deliveryCharges}`}</p>
                                        </div>
                                        {
                                            CouponDetails &&
                                            <div>
                                                <p>Coupon Discount</p>
                                                <p>- ₹{((price / 100) * discount).toFixed(2)}</p>
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        <p>Total : <span>₹ {((price + deliveryCharges) - ((price / 100) * discount))}</span></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='Cart-checkout-section'>
                            <div>
                                <p>Total : &nbsp; ₹ {((price + deliveryCharges) - ((price / 100) * discount))}</p>
                                <p>View details</p>
                            </div>
                            <div>
                                <div>
                                    <Button
                                        style={{
                                            width: '100%',
                                            textTransform: 'none',
                                            backgroundColor: '#DA6E0B',
                                            color: '#FDFDFD',
                                            border: '1px solid #DA6E0B',
                                            boxSizing: 'border-box',
                                            borderRadius: "5px",
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 500,
                                            padding: "10px"
                                        }}

                                        onClick={() => {
                                            // userDetails?.UID === "11733d8a-3071-70fc-7e5e-f31d4938e412" ?
                                            //     invokePayPage("IFRAME") : initiatePayment()
                                            initiatePayment()
                                        }}

                                    >
                                        Proceed to pay
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='EmptyCart'>
                        <img src={Cartempty}></img>
                        <p>Your Cart is Empty</p>
                        <p>"Boost your wellness—shop your favorites now!"</p>
                        <div>
                            <Button
                                style={{
                                    width: '100%',
                                    textTransform: 'none',
                                    backgroundColor: '#FDFDFD',
                                    color: '#DA6E0B',
                                    border: '1px solid #DA6E0B',
                                    boxSizing: 'border-box',
                                    borderRadius: "5px",
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 500,
                                    padding: "10px"
                                }}
                                onClick={() => navigate('/Shop')}
                            >
                                View Products
                            </Button>
                        </div>
                    </div>
            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <ShoppingBagIcon style={{
                        color: "#75623F", fontSize: "2rem"
                    }}></ShoppingBagIcon> Confirm Order
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" >
                        Takes 5-7 working days to deliver the product!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => {
                            checkout()
                            handleClose()
                        }}>
                            Confirm
                        </Button>
                    </>

                </DialogActions>
            </Dialog>

            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <div className='Appointment-booked-success'>
                        <Lottie animationData={success} loop={false}></Lottie>
                        <p>Order Placed Successfully</p>
                    </div>
                </DialogContent>
            </Dialog>
        </div >
    );
}

export default Cart;
