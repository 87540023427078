import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconButton, Button, TextField, Grid } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useCartStore from '../DataRepo/CartStore'
import pkgdetail from '../Assets/images/pkhdetailinfo.png'
import offerApplied from '../Assets/animations/offerapplied.json'
import Lottie from 'lottie-react';
import { profileModel, teleConsultationModel } from '../Axios/AxiosConfig';
import { getUserDetails } from '../Auth/AuthUtil';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import offerimg from '../Assets/images/offer.png'
import pauymentsuccess from '../Assets/Raw/paymentsuccess_ding.mp3';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import tabsqicon from '../Assets/images/tabler-icon-square-rounded-check.png'
import success from '../Assets/animations/success lottie.json';

function PackageCheckout() {

    const navigate = useNavigate()

    const [coupon, setcoupon] = useState('')

    const [couponError, setCouponError] = useState('')

    const [showmore, setShowmore] = useState(false)

    const [open, setOpen] = useState(false);

    const [open2, setOpen2] = useState(false);

    const [open3, setOpen3] = useState(false);

    const [hash, sethash] = useState('')

    const [Transcation_id, setTranscation] = useState('')

    const userDetails = getUserDetails()

    const [ProfileData, setProfileData] = useState([])

    const [isprofileloading, setprofileloading] = useState(true)

    const [errors, setErrors] = useState({
        email: '',
        phone: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setProfileData((prevState) => ({
            ...prevState,
            [name]: value
        }));


        setErrors((prevState) => ({
            ...prevState,
            [name]: ''
        }));
    };

    const validateInputs = () => {
        let isValid = true;
        const newErrors = {};

        if (!ProfileData.email) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(ProfileData.email)) {
            newErrors.email = 'Please enter a valid email';
            isValid = false;
        }

        if (!ProfileData.phone) {
            newErrors.phone = 'Phone number is required';
            isValid = false;
        } else if (!/^(?:\+91|91)?\d{10}$/.test(ProfileData.phone)) {
            newErrors.phone = 'Please enter a valid phone number';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleClose3 = () => {
        setOpen3(false);
    };

    const getProfile = async (id) => {
        try {
            let res = await profileModel.get(`/Profile/${id}`)
            if (res.status === 200) {
                const profile = res?.data
                setProfileData(profile)
                setprofileloading(false)
            }
        }
        catch (err) {
            console.log(err?.response?.data)
        }
    }

    const { getCouponDetails, Coupons, CouponDetails, setCoupon } = useCartStore();

    const PackagePricing = {
        planName: "Premium Wellness Plan",

        duration: 3,

        price: 499,

        orgprice: 999,

        quantity: 3,

        teleconsultationlimit: 3,

        products_discount: 5,

        info: [
            'One session free Teleconsultation',
            'Personalized Dietary Plan',
            'Unlimited Chat feature',
            'Delicious Recipes Plan',
            'One Time Prakriti Assessment',
            'Tailored Lifestyle Practices',
            // 'Digital Pulse Diagnosis'
        ]
    }

    const handleCoupon = () => {
        let ValidCoupon = Coupons.find((e) => e?.couponName?.toLowerCase()?.trim() === coupon?.toLowerCase()?.trim());

        if (ValidCoupon) {
            setCoupon(ValidCoupon);
        } else {
            setCouponError("Please enter a valid coupon code");
        }

    };

    const price = PackagePricing?.price * PackagePricing?.duration;

    const discount = CouponDetails?.discount || 0;

    const ringtone = new Audio(pauymentsuccess)

    const initiatePayment = async () => {
        if (ProfileData?.email != '' && ProfileData?.phone != '') {
            if (((price) - ((price / 100) * discount)) > 0) {
                try {
                    const response = await axios.post('https://api.ayurai.in/api/data/payment/makepayment',
                        {
                            amount: ((price) - ((price / 100) * discount)).toString(),
                            txnid: Date.now(),
                            phone: ProfileData?.phone || "123456790",
                            email: ProfileData?.email,
                            productinfo: 'Aiwell Wellness Package',
                            firstname: ProfileData?.firstname || userDetails?.UID.toString(),
                        });

                    if (response.status === 200) {
                        sethash(response.data.hash)
                        setTranscation(response?.data?.TranscationID)
                        handleClickOpen()
                    }
                } catch (error) {
                    console?.error('Error initiating payment:', error?.response);
                }
            }
            else {
                addSubscription()
            }
        }
        else {
            handleClickOpen3()
        }

    };

    const handleSaveProfile = async () => {
        if (validateInputs()) {
            try {
                let res = await profileModel.post(`/Profile`, {
                    ...ProfileData,
                    UID: getUserDetails()?.UID,
                });
                if (res.status === 200) {
                    handleClose3()
                    initiatePayment()
                }
            } catch (err) {
                console.log(err?.response?.data);
            }
        }
    };

    const checkout = () => {
        var data = {
            key: 'Ey2EbY',
            hash: hash,
            txnid: Transcation_id,
            amount: ((price) - ((price / 100) * discount)).toString(),
            firstname: ProfileData?.firstname || userDetails?.UID.toString(),
            email: ProfileData?.email,
            phone: ProfileData?.phone || "123456790",
            productinfo: 'Aiwell Wellness Package',
            surl: 'http://thirdparty.com/testresponse.php',
            furl: 'http://thirdparty.com/testresponse.php',
        };

        var handlers = {
            responseHandler: function (BOLT) {
                if (BOLT.response.txnStatus == "SUCCESS") {
                    addSubscription()
                    console.log('Your payment has been successful');
                }
                if (BOLT.response.txnStatus == "FAILED") {
                    console.log('Payment failed. Please try again.');
                }
                if (BOLT.response.txnStatus == "CANCEL") {
                    console.log('Payment failed. Please try again.');
                }
            },
            catchException: function (BOLT) {
                console.log('Payment failed. Please try again.');
            }
        };


        window.bolt.launch(data, handlers);
    }

    const addSubscription = async () => {
        try {
            const res = await teleConsultationModel.post('/subscription', {
                userId: userDetails?.UID,
                planName: PackagePricing?.planName,
                price: price,
                teleconsultationlimit: PackagePricing?.teleconsultationlimit,
                products_discount: PackagePricing?.products_discount,
                discount: parseInt(((price / 100) * discount).toFixed(2)),
                finalPrice: ((price) - ((price / 100) * discount)),
                duration: PackagePricing?.duration,
                paymentDetails: {
                    "transactionId": "123456",
                    "paymentMethod": "UPI"
                }
            })

            if (res.status == 200) {
                handleClickOpen2()
                ringtone.play()
                setCoupon(null)
                setTimeout(() => {
                    navigate('/PackageInfo')
                }, 2500)
            }
        }
        catch (err) {
            console.log(err?.response?.data);
        }
    }

    useEffect(() => {
        getCouponDetails()
        getProfile(userDetails?.UID)
    }, [])

    return <div className='Packages-Checkout-page-section'>
        <nav className='flex flex-row p-2 items-center justify-between pt-5'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => {
                    if (ProfileData.length === 0) {
                        navigate('/Profile')
                    }
                    else {
                        navigate('/')
                    }

                }}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Package Details</p>
            </div>
        </nav>

        <div>
            <div className='Packages-Checkout-section'>
                <div>
                    <div className='flex items-center justify-between'>
                        <div className='flex gap-5'>
                            <div>
                                <p>{PackagePricing?.planName}</p>
                                <p>{PackagePricing.duration} months</p>
                            </div>
                            <div style={{ padding: '5px', height: "max-content", borderRadius: "5px", color: "#3B362A", backgroundColor: "#8CEB8C" }}>
                                <p>Best Deal</p>
                            </div>
                        </div>

                        <div style={{ width: '2rem' }}>
                            <img src={tabsqicon} />
                        </div>

                    </div>

                    <div className="package-arrow mt-5 flex items-center justify-evenly" style={{
                        backgroundColor: "#75623F",
                        color: "#fdfdfd"
                    }}>
                        <p>₹499</p>
                        <p><del>₹999</del></p>
                        {/* <p>3months</p> */}
                    </div>

                    <p className='mt-5'>Elevate your well-being with personalized support & holistic care</p>

                    {
                        showmore &&
                        <div className='Package-details-info'>
                            {
                                PackagePricing?.info?.map((e) => {
                                    return <div className='flex items-center gap-5'>
                                        <img src={pkgdetail} style={{ width: '20px' }}></img>
                                        <p>{e}</p>
                                    </div>
                                })
                            }
                        </div>
                    }

                    <div className='package-show-more' onClick={() => {
                        setShowmore(!showmore)
                    }}>
                        <p>{showmore ? 'Show less' : "Show details"} {!showmore ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</p>
                    </div>
                </div>

                <div >
                    {!CouponDetails &&
                        <div className="coupon-container mt-5">
                            <input type="text" placeholder="Enter your coupon code" className="coupon-input" onChange={(e) => {
                                setcoupon(e.target.value)
                                setCouponError('')
                            }} />
                            <button className="apply-button" onClick={handleCoupon} >Apply</button>
                        </div>
                    }

                    {CouponDetails &&
                        <div className="CouponAppliedContainer mt-5">
                            <div className="lottie-background">
                                <Lottie animationData={offerApplied} />
                            </div>
                            <div className="content">
                                <img src={offerimg} alt="Offer" />
                                <div>
                                    <p>{CouponDetails?.couponName} Applied</p>
                                    <p>You have saved ₹{((price / 100) * discount).toFixed(2)} by applying a {discount}% discount for this order</p>
                                </div>
                            </div>


                            <IconButton style={{ alignSelf: "flex-start", justifySelf: "flex-end", marginLeft: 'auto' }} onClick={() => {
                                setCoupon(null)
                            }}>
                                <HighlightOffIcon />
                            </IconButton>

                        </div>
                    }

                    {
                        couponError != '' &&
                        <p className='couponError'>{couponError}</p>
                    }

                    <div className='Cart-total-container'>
                        <div>
                            <p>Payment details</p>
                        </div>
                        <div>
                            <div>
                                <p>Wellness Package</p>
                                <p>{`₹ ${PackagePricing.price} x ${PackagePricing.quantity} months`}</p>
                            </div>
                            <div>
                                <p>Sub Total</p>
                                <p>{`₹ ${price}`}</p>
                            </div>
                            {
                                CouponDetails &&
                                <div>
                                    <p>Coupon Discount</p>
                                    <p>- ₹{((price / 100) * discount).toFixed(2)}</p>
                                </div>
                            }
                        </div>
                        <div>
                            <p>Total : <span>₹ {((price) - ((price / 100) * discount))}</span></p>
                        </div>
                    </div>

                    <div style={{ padding: "10px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div>
                            <Button
                                style={{
                                    width: '100%',
                                    textTransform: 'none',
                                    backgroundColor: '#DA6E0B',
                                    color: '#FDFDFD',
                                    border: '1px solid #DA6E0B',
                                    boxSizing: 'border-box',
                                    paddingRight: "3rem",
                                    paddingLeft: "3rem",
                                    borderRadius: "5px",
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 500,
                                    padding: "10px"
                                }}
                                onClick={() => initiatePayment()}
                            >
                                Proceed to Pay
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <Dialog
            open={open3}
            onClose={handleClose3}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Contact details"}
            </DialogTitle>

            <DialogContent>

                <div className='mt-5'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Email"
                                variant="outlined"
                                fullWidth
                                name="email"
                                value={ProfileData.email}
                                onChange={handleChange}
                                error={!!errors.email}
                                helperText={errors.email}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Phone"
                                variant="outlined"
                                fullWidth
                                name="phone"
                                value={ProfileData.phone}
                                onChange={handleChange}
                                error={!!errors.phone}
                                helperText={errors.phone}
                            />
                        </Grid>
                    </Grid>
                </div>

                <p className='mt-2'
                    style={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 400,
                        fontSize: "12px"
                    }}
                >

                    Please fill in your email and phone number before continuing with the payment.</p>

            </DialogContent>
            <DialogActions>
                <Button

                    style={{
                        textTransform: 'none',
                        backgroundColor: '#DA6E0B',
                        color: '#FDFDFD',
                        border: '1px solid #DA6E0B',
                        boxSizing: 'border-box',
                        borderRadius: "5px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: "10px"
                    }}

                    onClick={handleSaveProfile} autoFocus>
                    Save
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Confirm Wellness Package"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to proceed to payment?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={checkout} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={open2}
            onClose={handleClose2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <div className='Appointment-booked-success'>
                    <Lottie animationData={success} loop={false}></Lottie>
                    <p>Wellness Package Booked Successfully</p>
                </div>
            </DialogContent>
        </Dialog>

    </div>

}

export default PackageCheckout