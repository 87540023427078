import React, { useState, useEffect, useRef } from 'react';
import { IconButton, Badge, Fab } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import TeleConsultationStore from '../DataRepo/TeleConsultationStore';
import useCartStore from '../DataRepo/CartStore';
import { getUserDetails } from '../Auth/AuthUtil';
import { ZIM } from "zego-zim-web";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import chatImg from '../Assets/images/chat.png';
import addtocart from '../Assets/images/add_to _bag_brown.png';
import calendaicon from '../Assets/images/Calender.png';
import clock from '../Assets/images/clock.png';
import videoCallgrn from '../Assets/images/Video Call Orange Color.png';
import audiocallgrn from '../Assets/images/Call Icon Orange Color.png';
import videocall from '../Assets/images/Video Call Orange Color.png'
import audiocall from '../Assets/images/Call Icon Orange Color.png'
import Reportimg from '../Assets/images/add report.png';
import SmsIcon from '@mui/icons-material/Sms';
import AppointmentStore from '../DataRepo/AppointmentStore';

function randomID(len) {
    let result = '';
    const chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP';
    const maxPos = chars.length;
    for (let i = 0; i < len; i++) {
        result += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return result;
}

function AppointmentPage() {
    const { Appointment_data, getOneAppointment } = AppointmentStore();
    const { cartItems } = useCartStore();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id') || '';

    useEffect(() => {
        getOneAppointment(id);
    }, [id]);



    return (
        <div className='All-products-section-main'>
            <nav className='flex flex-row p-2 items-center justify-between'>
                <div className='flex flex-row items-center'>
                    <IconButton onClick={() => navigate(-1)}>
                        <ChevronLeft />
                    </IconButton>
                    <p className='mb-0'>Appointment</p>
                </div>
            </nav>
            
            <div className='Appointment-confirm-page'>
                <div className='Appointment-confirm-page-doctor-details'>
                    <div>
                        <img src={Appointment_data?.doctor_photo} alt="Doctor" />
                        <div className='Appointment-confirm-page-doctor-info-details'>
                            <div>
                                <p>{Appointment_data?.doctor_name}</p>
                                <p>{Appointment_data?.doctor_speciality}</p>
                            </div>
                            <div>
                                <p>Appointment details</p>
                                <div className='Appointment-selected-info'>
                                <div>
                                    <div className='icons'> <img src={calendaicon} ></img></div>
                                    <div>
                                        <p>{Appointment_data?.appointment_date}</p></div>
                                </div>

                                <div>
                                    <div className='icons'>
                                        <img src={clock} ></img>
                                    </div>
                                    <div >
                                        <p>{Appointment_data?.appointment_time}</p>
                                    </div>

                                </div>
                                <div>
                                    <div className='icons'>
                                        <img src={Appointment_data?.consultation_type  === 'Video' ? videocall : audiocall}></img>
                                    </div>
                                    <div>
                                        <p>{Appointment_data?.consultation_type}</p>
                                    </div>
                                </div>

                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='Appointment-confirm-page-info h-100'>
                    <div className='add-report-container'  onClick={()=>{
                    navigate('/Reports')
                }}>
                        <img src={Reportimg} alt="Add Report" />
                        <p>Add a report</p>
                    </div>
                </div>

            </div>
            <div className="floating-fab" >
                <Fab aria-label="chat" className="fab" onClick={()=>{
                    navigate(`/Chat/${Appointment_data?.patient_id}/${Appointment_data.doctor_id}`)
                }}>
                   <SmsIcon/>
                </Fab>
            </div>
        </div>
    );
}

export default AppointmentPage;
