import React from 'react'
import { getUserDetails } from '../Auth/AuthUtil'
import DoshaChart from './Chart'
import { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import goalsettingimg from '../Assets/images/goal setting.png'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import cautionimg from '../Assets/images/Caution icon 1.png'
import "slick-carousel/slick/slick-theme.css";
import fibpro from '../Assets/images/flbpro.png'
import bspro from '../Assets/images/bspro.png'
import WellnessStore from '../DataRepo/WellnessStore'
import { useNavigate } from 'react-router-dom'
import leftvec from '../Assets/images/left vector.png';
import rightvec from '../Assets/images/right vector.png';
import yogalandingimg from '../Assets/images/Yoga_Landingpage.png'
import pranayamalandingimg from '../Assets/images/Pranayama.png'
import meditationlandingimg from '../Assets/images/MeditationL.png'
import { profileModel } from '../Axios/AxiosConfig'
import bookconsultationvector from '../Assets/images/book_cons_vector.png'
import doctorvectorimgcard from '../Assets/images/doctor_card_vector.png'
import ShopStore from '../DataRepo/ShopStore'
import AppointmentStore from '../DataRepo/AppointmentStore'
import videoCallgrn from '../Assets/images/Video Call Orange Color.png'
import audiocallgrn from '../Assets/images/Call Icon Orange Color.png'
import calendaicon from '../Assets/images/Calender.png'
import clock from '../Assets/images/clock.png'
import lockimg from '../Assets/images/lock_icon.png'
import Dietsampleimg1 from '../Assets/images/D-1.png'
import Dietsampleimg2 from '../Assets/images/D-2.png'
import Dietsampleimg3 from '../Assets/images/D-3.png'
import Dietsampleimg4 from '../Assets/images/D-4.png'
import SubscriptionStore from '../DataRepo/SubscriptionStore'

function HomePage() {

    const userDetails = getUserDetails()

    const { getTopProducts, Top_Products } = ShopStore();

    const { Subscriptiondetails, Subscriptiondetailsloading, getSubscriptiondetails, isSubscribed } = SubscriptionStore()

    const { Appointments, isAppointmentsLoading, getAppointments } = AppointmentStore()

    const [isprofileloading, setprofileloading] = useState(true)

    const [ProfileData, setProfileData] = useState([])

    const { Diets, getDiet, isDietLoading, getRecipes, Recipes, isRecipeLoading } = WellnessStore();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    const [currentIndex, setCurrentIndex] = useState(0);

    const [currentRindex, setRindex] = useState(0);

    const navigate = useNavigate()

    useEffect(() => {
        getDiet()
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % Diets?.Suitable?.length);
        }, 3000);

        return () => clearInterval(intervalId);
    }, [Diets?.Suitable?.length]);


    const getProfile = async (id) => {
        try {
            let res = await profileModel.get(`/Profile/${id}`)
            if (res.status === 200) {
                const profile = res?.data
                setProfileData(profile)
                setprofileloading(false)
            }
        }
        catch (err) {
            console.log(err?.response?.data)
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRindex((prevIndex) => (prevIndex + 1) % Recipes?.length);
        }, 3000);

        return () => clearInterval(intervalId);
    }, [Recipes?.length]);

    useEffect(() => {
        getTopProducts()
        getSubscriptiondetails()
        getProfile(userDetails?.UID)
        getAppointments(userDetails?.UID)
    }, [])

    const testKitSlider = [
        {
            id: fibpro,
            title: 'Track your Glucose effortlessly with our user-friendly CGM',
            description: 'Free style libre pro',
            code: '9545784',
        },
        {
            id: bspro,
            title: 'Discover Your Health Answers with our innovative kit!',
            description: 'BugSpeaks',
            code: '9545783',
        }
    ];


    useEffect(() => {
        if (Recipes.length === 0) {
            getRecipes()
        }
    }, [])


    const filterAppointments = Appointments.filter(e => e.appointment_status === 'upcoming' || e.appointment_status === 'confirmed').sort((a, b) => {
        const dateA = a?.timestamp ? new Date(Number(a?.timestamp)) : new Date(0);
        const dateB = b?.timestamp ? new Date(Number(b?.timestamp)) : new Date(0);
        return dateB - dateA;
    });

    const DietSampleImages = [Dietsampleimg1, Dietsampleimg2, Dietsampleimg3, Dietsampleimg4]

    return <div className='Homepage-main-screen'>
        <div className='Homepage-head-section'>
            <p>Hello, {ProfileData?.firstname}. Have a Nice Day!</p>
            
            {
                filterAppointments.length === 0 ?
                    <div
                        className="Goal-main-page-card flex flex-row items-center justify-between mt-2 relative"
                        style={{ marginTop: "1rem", }}
                    >
                        <div
                            style={{
                                flex: "0.5",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <p style={{ textAlign: "center" }}>Schedule a Teleconsultation</p>

                            <p style={{ textAlign: "center" }}>
                                To make your health journey much easier
                            </p>

                            <div style={{ width: "100%", maxWidth: "200px" }}>
                                <Button
                                    style={{
                                        width: "100%",
                                        textTransform: "none",
                                        backgroundColor: "#DA6E0B",
                                        border: "none",
                                        color: "#fdfdfd",
                                        borderRadius: "5px",
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        padding: "10px",
                                    }}
                                    onClick={() => {
                                        navigate("/DoctorProfiles");
                                    }}
                                >
                                    <p className='appointmentbooknowbtn'>Book an Appointment</p>
                                </Button>
                            </div>

                        </div>

                        <div
                            className="Goal-main-page-card-wellness-img"
                            style={{
                                flex: "0.3",
                                display: "flex",
                                alignItems: "center",
                                padding: "1rem",
                                paddingBottom: "0",
                                justifyContent: "center",
                                position: "relative",
                            }}
                        >
                            <img
                                src={bookconsultationvector}
                                alt="Consultation"
                                style={{
                                    position: "absolute",
                                    top: "-20px",
                                    maxWidth: "100%",
                                    transform: "translateY(-50%)",
                                }}
                            />
                        </div>

                    </div> :
                    <div className="Homepage-Appointments-container" style={{ marginTop: "1rem", }}>
                        <div className='All-appointments-container'>
                            <p className='uptitile'>Upcoming Appointment</p>
                            <div className='Appointments-container'
                                onClick={() => {
                                    navigate(`/Appointment?id=${filterAppointments[0].Appointment_id}&doc_id=${filterAppointments[0].doctor_uid}`)
                                }}>
                                <div>
                                    <div>
                                        <img src={filterAppointments[0]?.doctor_photo}></img>
                                    </div>
                                    <div>
                                        <p>{filterAppointments[0]?.doctor_name}</p>
                                        <p>{filterAppointments[0]?.doctor_speciality}</p>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <img src={calendaicon} />
                                        <p>{filterAppointments[0]?.appointment_date?.split('-')?.reverse()?.join('-')}</p>
                                    </div>
                                    <div>
                                        <img src={clock} />
                                        <p>{filterAppointments[0]?.appointment_time}</p>
                                    </div>
                                    <div>
                                        <img src={filterAppointments[0]?.consultation_type === "Video" ? videoCallgrn : audiocallgrn} />
                                        <p>{filterAppointments[0]?.consultation_type} Consultation</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }


            {
                Diets?.length === 0 || Recipes?.length === 0 ?
                    <div className='Unlockdiet mt-5'>
                        <div>
                            <img src={lockimg}></img>
                        </div>

                        <div style={{ width: "100%", maxWidth: "300px" }}>
                            <Button
                                style={{
                                    width: "100%",
                                    textTransform: "none",
                                    backgroundColor: "#DA6E0B",
                                    border: "none",
                                    color: "#fdfdfd",
                                    borderRadius: "5px",
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    padding: "10px",
                                }}

                                onClick={() => {
                                    {
                                        isSubscribed ?
                                            filterAppointments.length === 0 ?
                                                navigate("/DoctorProfiles")
                                                : navigate(`/Appointment?id=${filterAppointments[0]?.Appointment_id}&doc_id=${filterAppointments[0]?.doctor_uid}`)
                                            : navigate("/PackageCheckout")
                                    }
                                }}

                            >
                                <p className='appointmentbooknowbtn'>
                                    {
                                        isSubscribed ?
                                            filterAppointments.length === 0 ?
                                                "Book your consultation"
                                                : "Complete your consultation"
                                            : 'Purchase our Wellness Package'
                                    }
                                </p>

                            </Button>
                        </div>

                        <p>To get personalized Diet & Recipes</p>

                        <div className="circle-container">
                            {DietSampleImages.map((image, index) => (
                                <div key={index} className="circle" style={{ backgroundImage: `url(${image})` }}></div>
                            ))}
                        </div>

                    </div>
                    :
                    <div className='mt-5 Diet-home-reSection'>
                        {
                            Diets?.Suitable?.length > 0 &&
                            <div>
                                <p>Diet</p>
                                <div className='Diet-card-infinite-loop' onClick={() => {
                                    navigate(`/Diet?categoryname=${Diets?.Suitable?.[currentIndex]?.categoryEng}`)
                                }}>
                                    <div>
                                        <p>{Diets?.Suitable?.[currentIndex]?.englishName}</p>
                                        <p>{Diets?.Suitable?.[currentIndex]?.categoryEng}</p>
                                    </div>
                                    <div>
                                        <img src={Diets?.Suitable?.[currentIndex]?.imageLink}></img>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            Recipes.length > 0 &&
                            <div>
                                <p>Recipes</p>
                                <div className='Diet-card-infinite-loop' onClick={() => {
                                    navigate(`/Recipes?id=${Recipes?.[currentRindex]?.id}`)
                                }}>
                                    <div>
                                        <p>{Recipes?.[currentRindex]?.recipe}</p>
                                        <p>{Recipes?.[currentIndex]?.benefits}</p>
                                    </div>
                                    <div>
                                        <img src={Recipes?.[currentRindex]?.imageLink}></img>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
            }

            <div className='mt-5 Practices-section'>
                <div>
                    <img src={leftvec} alt="Left Arrow" />
                    <p>Harmony Within</p>
                    <img src={rightvec} alt="Right Arrow" />
                </div>
                <div className='Practices-main-content-section'>
                    <div>
                        <div onClick={() => {
                            navigate('/Yogas')
                        }}>

                            <img src={yogalandingimg}></img>
                            <p>Yoga</p>

                        </div>
                        <div onClick={() => {
                            navigate('/Pranayamas')
                        }}>
                            <img src={pranayamalandingimg}></img>
                            <p>Pranayama</p>
                        </div>
                        <div onClick={() => {
                            navigate('/Meditations')
                        }}>
                            <img src={meditationlandingimg}></img>
                            <p>Meditation</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-5 Top-Products-landing-section'>
                <div>
                    <p>Top Products</p>
                    <p style={{ cursor: "pointer" }} onClick={() => {
                        navigate('/Products?type=All')
                    }}>View all</p>
                </div>

                <div>
                    {
                        Top_Products.map((e, i) => {
                            return <div className='Top-Products-landing-sectionpcard' style={{ cursor: "pointer" }} key={i} onClick={() => {
                                navigate(`/Product?id=${e?.id}`)
                            }}>
                                <div>
                                    <img src={e?.imgurl}></img>
                                </div>

                                <div>
                                    <p>{e?.name}</p>
                                    <p>{e?.desc}</p>
                                    <div className='Product-section-price-details'>
                                        <p className='mr-3'><del>{e?.orgprice > 0 && `₹ ${e?.orgprice}`}</del></p>
                                        <p>₹ {e?.price}</p>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>

            <div className='mt-5' style={{ display: "none" }}>
                <Slider {...settings}>
                    {testKitSlider.map(item => (
                        <div className='testkitslidecard flex flex-row'>
                            <div>
                                <img src={item.id}></img>
                            </div>
                            <div className='testkitslidecard-info'>
                                <p>{item.title}</p>
                                <div>
                                    <Button
                                        style={{
                                            width: '100%',
                                            textTransform: 'none',
                                            borderRadius: '0',
                                            backgroundColor: '#8CEB8C',
                                            color: '#3B362A',
                                            border: 'none',
                                            boxSizing: 'border-box',
                                            borderRadius: "5px",
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 500,
                                            padding: "10px"
                                        }}

                                        onClick={() => {
                                            //   navigate('/SignUp')
                                        }
                                        }
                                    >
                                        Explore here
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            <div className='mt-10'>
                <div className='Slot-errreponse'>
                    <div className='flex flex-col items-center'>
                        <img src={cautionimg}></img>
                        <p style={{ fontWeight: 500, fontSize: 'large' }}>Disclaimer</p>
                    </div>
                    <p>Recommendations are approved by our Ayurveda physician Dr.Prathiban. The assessment and suggestions provided here are for health and wellness purposes only. Please do not self-medicate. Consult an Ayurveda practitioner or medical professional for accurate guidance.</p>
                </div>
            </div>

        </div>
    </div>
}

export default HomePage