import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, Outlet } from 'react-router-dom';
import { Drawer, IconButton, BottomNavigation, Button, BottomNavigationAction, Badge, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Sidebar, { SidebarItem } from './Sidebar';
import useCartStore from '../DataRepo/CartStore';
import Order from '../Assets/images/My orders.png';
import shopimg from '../Assets/images/Shop.png';
import dietimg from '../Assets/images/Diet.png';
import doc_section from '../Assets/images/Doctor.png';
import AiwellLogo from '../Assets/images/Aiwell Logo 1.png';
import notificationIcon from '../Assets/images/notification.png';
import test_kit from '../Assets/images/Diagnostic Tests.png';
import brown_icon from '../Assets/images/Brown heart icon (1).png'
import Coin_Icon from '../Assets/images/Coin_icon.png'
import addtocart from '../Assets/images/add_to _bag_brown.png'
import OrderActive from '../Assets/images/My orders white.png';
import homeactiveicon from '../Assets/images/Home white icon.png';
import homeicon from '../Assets/images/Home.png'
import shopimgActive from '../Assets/images/Shop White icon.png';
import dietimgActive from '../Assets/images/Group 13049.png';
import doc_sectionActive from '../Assets/images/Doctor white icon.png';
import test_kitActive from '../Assets/images/Diagnostic Tests white icon.png';
import searchicon from '../Assets/images/search icon-black.png';
import SidebarIcon from '../Assets/images/hamburger 1.png';
import profileIcon from '../Assets/images/Profile.png'
import profileIconActive from '../Assets/images/Profile white icon.png'
import settingsIcon from '../Assets/images/Settings_brown.png'
import logouticon from '../Assets/images/log out_lightgrey.png'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { clearTokens } from '../Auth/AuthUtil';
import CreditsStore from '../DataRepo/CreditsStore';

function HomeScreen() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [opendialog, setOpendialog] = useState(false);


    const handleClickOpendialog = () => {
        setOpendialog(true);
    };

    const handleCloseDialog = () => {
        setOpendialog(false);
    };

    const location = useLocation();
    const navigate = useNavigate();

    const { cartItems } = useCartStore();
    const { getCredits, Credits } = CreditsStore();

    const isMobile = useMediaQuery('(max-width:600px)');

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const drawerContent = (
        <div
            role="presentation"
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <div className='Aiwell-splash-logo p-5 flex-col' style={{ border: "none" }}>
                <img src={AiwellLogo} alt="Aiwell Logo" />
                <p style={{ borderBottom: "none", fontSize: '16px' }}>Scientifically crafted for you</p>
            </div>

            <List className='Drawerlistitems' style={{ flex: 1 }}>
                {[
                    { path: '/', label: 'Home', icon: homeicon, activeIcon: homeactiveicon },
                    { path: '/TeleConsultation', label: 'Doctor', icon: doc_section, activeIcon: doc_sectionActive },
                    { path: '/DietSection', label: 'Diet', icon: dietimg, activeIcon: dietimgActive },
                    { path: '/Shop', label: 'Shop', icon: shopimg, activeIcon: shopimgActive },
                    // { path: '/TestKit', label: 'Test Kit', icon: test_kit, activeIcon: test_kitActive },
                    { path: '/MyOrders', label: 'My Orders', icon: Order, activeIcon: OrderActive },
                    { path: '/Profilepage', label: 'My Profile', icon: profileIcon, activeIcon: profileIcon },
                    { path: '/Settings', label: 'Settings', icon: settingsIcon, activeIcon: settingsIcon },
                ].map(({ path, label, icon, activeIcon }) => (
                    <ListItem
                        button
                        component={Link}
                        to={path}
                        key={path}
                        sx={{
                            backgroundColor: location.pathname === path ? "#75623F" : "",
                            color: location.pathname === path ? "#FDFDFD" : "inherit",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: "500",
                            padding: "10px",
                            fontSize: "20px",
                            "&.Mui-selected": {
                                backgroundColor: "#75623F",
                                color: "#FDFDFD",
                            },
                            "&:hover": {
                                backgroundColor: location.pathname === path ? "#75623F" : "#E0E0E0", // Optional hover state
                            },
                        }}>
                        <ListItemIcon>
                            <img
                                src={location.pathname === path ? activeIcon : icon}
                                alt={label}
                                style={{ width: "2rem" }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={label}
                            primaryTypographyProps={{
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: "400",
                            }}
                        />
                    </ListItem>
                ))}
            </List>

            <div className='flex flex-row p-5 pl-0 gap-5 items-center m-5 ml-2 Drawerlistitems' style={{ marginTop: 'auto' }} onClick={() => { handleClickOpendialog() }}>
                <div>
                    <img src={logouticon} style={{ width: "2rem" }} alt="Logout Icon" />
                </div>
                <div>
                    <p>Log out</p>
                </div>
            </div>

            <Dialog
                open={opendialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Logout"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to log out?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{
                            // width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            border: '1px solid #DA6E0B ',
                            color: '#DA6E0B',
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: '10px'
                        }}

                        onClick={handleCloseDialog}>No</Button>
                    <Button style={{
                        // width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: '5px',
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: '10px'
                    }}
                        onClick={() => {
                            clearTokens()
                            handleCloseDialog()
                            window.location.reload();
                        }} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );


    useEffect(() => {
        getCredits()
    }, [])


    return (
        <div className="home-container flex">
            {!isMobile && (
                <Sidebar>
                    {[
                        { path: '/', label: 'Home', icon: homeicon, activeIcon: homeactiveicon },
                        { path: '/TeleConsultation', label: 'Doctor', icon: doc_section, activeIcon: doc_sectionActive },
                        { path: '/DietSection', label: 'Diet', icon: dietimg, activeIcon: dietimgActive },
                        { path: '/Shop', label: 'Shop', icon: shopimg, activeIcon: shopimgActive },
                        // { path: '/TestKit', label: 'Test Kit', icon: test_kit, activeIcon: test_kitActive },
                        { path: '/MyOrders', label: 'My Orders', icon: Order, activeIcon: OrderActive },
                    ].map(({ path, label, icon, activeIcon }) => (
                        <Link to={path} key={path}>
                            <SidebarItem
                                icon={
                                    <img
                                        src={location.pathname === path ? activeIcon : icon}
                                        alt={label}
                                        style={{ width: "2rem" }}
                                    />
                                }
                                text={label}
                                active={location.pathname === path}
                            />
                        </Link>
                    ))}
                </Sidebar>
            )}

            <div className="home-content flex-1">
                <nav className='home-content-nav flex flex-row p-2 mb-50'>
                    <div className="flex-1">
                        {isMobile && (
                            <>
                                <IconButton onClick={toggleDrawer(true)}>
                                    <img src={SidebarIcon} alt="Menu" style={{ width: '2rem' }} />
                                </IconButton>
                                <Drawer
                                    anchor="left"
                                    open={drawerOpen}
                                    onClose={toggleDrawer(false)}
                                    sx={{
                                        '& .MuiDrawer-paper': {
                                            width: '300px',
                                            backgroundColor: "#F8F8F4"
                                        },
                                    }}
                                >
                                    {drawerContent}
                                </Drawer>
                            </>
                        )}
                    </div>
                    
                    <div className='flex flex-row'>

                        <div className='flex flex-row items-center coins-credits-nav '
                            onClick={() => {
                                navigate('/credits')
                            }}
                        >
                            <img src={Coin_Icon}></img>
                            <p>{(Credits?.totalCredits - Credits?.usedCredits) || 0} Credits</p>
                        </div>

                        <IconButton component={Link} to="/Search">
                            <div className='nav-icons'>
                                <img src={searchicon} alt="Search" />
                            </div>
                        </IconButton>

                        <IconButton component={Link} to="/Checkout">
                            <Badge badgeContent={cartItems?.length} color="error">
                                <div className='nav-icons'>
                                    <img src={addtocart} alt="Cart" />
                                </div>
                            </Badge>
                        </IconButton>

                        <IconButton component={Link} to="/wishlist">
                            <div className='nav-icons'>
                                <img src={brown_icon} alt="Cart" />
                            </div>
                        </IconButton>

                        {/* <IconButton>
                            <div className='nav-icons'>
                                <img src={notificationIcon} alt="Notifications" />
                            </div>
                        </IconButton> */}
                    </div>
                </nav>

                <div >
                    <Outlet />
                </div>
            </div>

            {isMobile && (
                <BottomNavigation
                    showLabels
                    value={location.pathname}
                    onChange={(event, newValue) => {
                        navigate(newValue);
                    }}
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: '#F4ECD6',
                    }}
                >
                    {[
                        { path: '/', label: 'Home', icon: homeicon, activeIcon: homeactiveicon },
                        { path: '/TeleConsultation', label: 'Doctor', icon: doc_section, activeIcon: doc_sectionActive },
                        { path: '/DietSection', label: 'Diet', icon: dietimg, activeIcon: dietimgActive },
                        { path: '/Shop', label: 'Shop', icon: shopimg, activeIcon: shopimgActive },
                    ].map(({ path, label, icon, activeIcon }) => (
                        <BottomNavigationAction
                            key={path}
                            value={path}
                            icon={
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={location.pathname === path ? activeIcon : icon} alt={label} style={{ width: '24px', height: '24px' }} />
                                    <span style={{ fontSize: '12px', marginTop: '4px', lineHeight: '1.2' }}>{label}</span>
                                </div>
                            }
                            sx={{
                                padding: "0",
                                minWidth: "0",
                                "&.Mui-selected": {
                                    backgroundColor: "#75623F",
                                    color: "#FDFDFD",
                                },
                            }}
                        />
                    ))}
                </BottomNavigation>
            )}
        </div>
    );
}

export default HomeScreen;
