import React, { useState, useEffect } from 'react'
import step1 from '../Assets/images/step-1.png'
import step2 from '../Assets/images/step-2.png'
import step3 from '../Assets/images/step-3.png'
import step4 from '../Assets/images/step-4.png'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import vikritinext from '../Assets/images/vikritinext.png'
import { useNavigate } from 'react-router-dom'
import showers from '../Assets/animations/prakriticonfetti.json'
import Lottie from 'lottie-react'
import { getUserDetails } from '../Auth/AuthUtil'
import { profileModel } from '../Axios/AxiosConfig'
function PackageInfo() {

    const steps = [step1, step2, step3, step4]
    const [isprofileloading, setprofileloading] = useState(true)
    const [ProfileData, setProfileData] = useState([])
    const navigate = useNavigate()

    const getProfile = async (id) => {
        try {
            let res = await profileModel.get(`/Profile/${id}`)
            if (res.status === 200) {
                const profile = res?.data
                setProfileData(profile)
                setprofileloading(false)
            }
        }
        catch (err) {
            console.log(err?.response?.data)
        }
    }

    const settings = {
        dots: true,
        speed: 500,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };


    useEffect(() => {
        const userDetails = getUserDetails()
        getProfile(userDetails?.UID)
    }, [])

    return <div className='Welcome-page-section'>
        <div className='Wellness-categories-section'>

            <div className="Lottie-background">
                <Lottie animationData={showers} loop={true} />
            </div>

            <p className='text-center pkg-info-T'>
                Congratulations <br />on Your Wellness Package!
            </p>

            <p className='text-center'>Follow these 4 Simple Steps to <br />Start Your Transformation</p>

            <div className='Carosel-container'>
                <Slider {...settings}>
                    {steps.map((step, index) => (
                        <div key={index} className='Carosel-item'>
                            <img
                                src={step}
                                alt={`Step ${index + 1}`}
                                style={{
                                    // width: '100%',
                                    // height: '100%',
                                    // objectFit: 'cover',
                                    borderRadius: '10px',
                                }}
                            />
                        </div>
                    ))}
                </Slider>
            </div>

            <div className='mt-5' onClick={() => {
                if (ProfileData.length === 0) {
                    navigate('/Profile')
                }
                else {
                    navigate('/DoctorProfiles')
                }

            }} style={{ alignSelf: "center", justifySelf: "flex-end", marginTop: "auto", width: "3rem", cursor: "pointer" }} >
                <img src={vikritinext}></img>
            </div>

        </div>
    </div>
}

export default PackageInfo