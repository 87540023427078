import React, { useState, useEffect } from 'react';
import WellnessStore from '../DataRepo/WellnessStore';
import { IconButton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress } from '@mui/material';
import loadingimg from '../Assets/images/loading image 1.png'
import yogalandingimg from '../Assets/images/yoga.png'
function YogaPage() {
    const navigate = useNavigate()
    const { getYoga, selectedYogas, unselectedYogas, isYogaLoading } = WellnessStore();

    useEffect(() => {
        getYoga()
    }, [])

    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Yoga</p>
            </div>
        </nav>
        {
            isYogaLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                    <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                    </div>
                </div>
            </div>
                : <div className='Yoga-page-section'>
                    <div >
                        <div>
                            <img src={yogalandingimg}></img>
                        </div>
                        <div>
                            <p>Yoga</p>
                            <p>"Yoga is the journey of the self, through the self, to the self."</p>
                        </div>
                    </div>
                    <div className='Yoga-content-section'>
                        {
                            selectedYogas.length > 0 &&
                            <div>
                                <p>Recommended for you</p>
                                <div className='mt-2 Yogalist-container'>
                                    {
                                        selectedYogas.map((e, i) => {
                                            return <div className='yogacard' onClick={() => {
                                                navigate(`/yoga?id=${e?.id}`)
                                            }}>
                                                <div>
                                                    <p>{e?.title}</p>
                                                    <p>{e?.pose}</p>
                                                </div>
                                                <div>
                                                    <img src={e?.image} />
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        }
                        {unselectedYogas?.length > 0 &&
                            <div className='mt-3'>
                                <p>Explore other Yoga</p>
                                <div className='mt-2 Yogalist-container'>
                                    {
                                        unselectedYogas.map((e, i) => {
                                            return <div className='yogacard' onClick={() => {
                                                navigate(`/yoga?id=${e?.id}`)
                                            }}>
                                                <div>
                                                    <p>{e?.title}</p>
                                                    <p>{e?.pose}</p>
                                                </div>
                                                <div>
                                                    <img src={e?.image} />
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>}
                    </div>
                </div>
        }
    </div>
}

export default YogaPage