import axios from 'axios';
import create from 'zustand';
import { getUserDetails } from '../Auth/AuthUtil';
import { shopModel } from '../Axios/AxiosConfig';

const useAddressStore = create((set) => ({
    addresses: [],
    currentAddress: null,

    addAddress: async (newAddress) => {
        try {
            const userId = getUserDetails()?.UID
            let response = await shopModel.post(`/addAddress/${userId}`, {
                ...newAddress
            })
            if (response && response?.status === 200) {
                set({
                    addresses: response?.data?.addresses
                })
            }

        }
        catch (err) {
            console.log(err?.response?.data);
        }
    },

    deleteAddress: async (id) => {
        try {
            const userId = getUserDetails()?.UID
            let response = await shopModel.delete(`/deleteAddress/${userId}/${id}`)
            if (response?.status === 200) {
                set((state) => ({
                    addresses: state.addresses.filter((address) => address.id !== id),
                }))
            }
        }
        catch (err) {
            console.log(err?.response?.data);
        }
    },

    editAddress: async (id, updatedAddress) => {
        try {
            const userId = getUserDetails()?.UID
            let response = await shopModel.put(`/editAddress/${userId}/${id}`, {
                ...updatedAddress
            })
            if (response && response?.status === 200) {
                set({
                    addresses: response?.data?.addresses
                })
            }

        }
        catch (err) {
            console.log(err?.response?.data);
        }
    },

    setCurrentAddress: (item) => {
        set({ currentAddress: item })
    },

    getAddress: async () => {
        try {
            const userId = getUserDetails()?.UID
            let response = await shopModel.get(`/getAddress/${userId}`)
            if (response && response?.status === 200) {
                set({
                    addresses: response?.data?.addresses || [],
                    currentAddress: response?.data?.currentAddress || null
                })
            }
        }
        catch (err) {
            console.log(err?.response?.data);
        }
    },
}));

export default useAddressStore;
