import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import heighticon from '../Assets/images/height.png';
import weighticon from '../Assets/images/weight.png';
import dobiocn from '../Assets/images/date of birth.png';
import maleicon from '../Assets/images/Male.png';
import femaleicon from '../Assets/images/Female.png';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const ProfileCard = ({ profileData }) => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:600px)');
    const requiredFields = ['firstname', 'lastname', 'gender', 'dob', 'height', 'weight', 'email', 'mothertounge', 'src', 'phone'];
    const completedFields = requiredFields.filter(field => profileData[field] !== "").length;
    const completionPercentage = (completedFields / requiredFields.length) * 100;

    return (
        <div className="profile-card-container">
            <div className="profile-image-container">
                <img
                    src={profileData?.src != "" ? profileData?.src : `https://eu.ui-avatars.com/api/?name=${profileData?.firstname}+${profileData?.lastname}&size=250`}
                    alt="Profile Picture"
                    className="profile-image"
                    style={{
                        objectFit: 'cover'
                    }}
                />
                <div className="profile-progress">
                    <div className="circular-progress-wrapper">
                        
                        <CircularProgress
                            className="circular-progress"
                            variant="determinate"
                            value={completionPercentage}
                            size={isMobile ? 100 : 150}

                            thickness={2}
                            style={{ color: '#7fcc92' }}
                        />
                        
                    </div>
                </div>
            </div>
            <div className="profile-details">
                <h2 className="profile-name">{profileData.firstname} {profileData.lastname}</h2>
                <div className="profile-info">
                    <span>
                        <img src={profileData?.gender === "Male" ? maleicon : femaleicon} alt="Gender Icon" />
                        <p>{profileData.gender}</p>
                    </span>
                    <span>
                        <img src={dobiocn} alt="Calendar Icon" />
                        <p>{profileData.dob}</p>
                    </span>
                    <span>
                        <img src={heighticon} alt="Height Icon" />
                        <p></p>{profileData.height}
                    </span>
                    <span>
                        <img src={weighticon} alt="Weight Icon" />
                        <p>{profileData.weight}</p>
                    </span>
                </div>
                <div className="profile-buttons">
                    <button className="profile-button">
                        {completionPercentage.toFixed(0)}% {'Complete'}
                    </button>
                    <button className="edit-profile-button" onClick={() => {
                        navigate('/EditProfile')
                    }}>
                        Edit Profile
                    </button>
                </div>
                <p className="profile-assessment">
                    {/* Complete {completionPercentage.toFixed(0)}% of your prakriti assessment */}
                </p>
            </div>
        </div>
    );
};

export default ProfileCard;
