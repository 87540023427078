import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { isTokenExpired, refreshToken, getUserDetails } from './AuthUtil';
import { ZIM } from "zego-zim-web";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import loadingimg from '../Assets/images/loading image 1.png';
import { CircularProgress } from '@mui/material';
import { profileModel } from '../Axios/AxiosConfig';
import { generateToken, messaging } from '../Notifications.jsx/firebase';
import { onMessage, isSupported } from "firebase/messaging";

const cookies = new Cookies();

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [callActive, setCallActive] = useState(false);
  const [ProfileData, setProfileData] = useState([])
  const zegoCloudInstance = useRef(null);
  const videoCallDivRef = useRef(null);
  const userDetails = getUserDetails();

  const [userInfo, setUserInfo] = useState({
    userName: "",
    userId: "",
  });

  useEffect(() => {

    const checkAuthentication = async () => {
      let accessToken = cookies.get('accessToken');

      if (!accessToken || isTokenExpired(accessToken)) {
        const refreshed = await refreshToken();
        accessToken = cookies.get('accessToken');

        if (!refreshed || !accessToken) {
          setIsAuthenticated(false);
          return;
        }
      }

      if (isTokenExpired(accessToken)) {
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(true);
      }
    };

    checkAuthentication();
  }, []);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate('/SplashScreen', { state: { from: location } });
    } else {
      document.addEventListener('click', handleUserInteraction);
      isSupported().then((supported) => {
        if (supported) {
          generateToken();
          onMessage(messaging, (payload) => {
            console.log('Message received:', payload);
          });
        } else {
          console.log('Firebase Messaging is not supported in this environment.');
        }
      });
      return () => {
        document.removeEventListener('click', handleUserInteraction);
      };
    }
  }, [isAuthenticated]);

  const handleUserInteraction = () => {
    document.removeEventListener('click', handleUserInteraction);
    init();
  };

  const getProfile = async (id) => {
    try {
      let res = await profileModel.get(`/Profile/${id}`)
      if (res.status === 200) {
        const profile = res?.data
        setProfileData(profile)
      }
    }
    catch (err) {
      console.log(err?.response?.data)
    }
  }

  useEffect(() => {
    getProfile(userDetails?.UID)
  }, [])

  const init = async () => {

    const userId = userDetails?.UID?.split('-')[0];

    const userName = `${ProfileData?.firstname || 'user'}`;

    setUserInfo({ userName, userId });

    const appID = 197553733;
    const serverSecret = "0fbade5cc539bd4335b2336f0c75baf4";

    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      null,
      userId,
      userName
    );

    zegoCloudInstance.current = ZegoUIKitPrebuilt.create(kitToken);
    zegoCloudInstance.current.addPlugins({ ZIM });

    zegoCloudInstance.current.setCallInvitationConfig({
      ringtoneConfig: {
        incomingCallUrl: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/in-y2mate.com+-+Space+Guitar+Bliss+Ringtone.mp3',
      },
      onCallInviteReceived: (data) => {
        zegoCloudInstance.current.joinRoom({
          container: videoCallDivRef.current,
          roomID: data.roomID,
          scenario: {
            mode: ZegoUIKitPrebuilt.OneONoneCall,
          },
          onLeaveRoom: () => {
            setCallActive(false);
          },
        });
        setCallActive(true);
      },

      onCallInviteRejected: () => {
        console.log('Call was rejected.');
      },

      onCallInviteCanceled: () => {
        console.log('Call was canceled.');
        setCallActive(false);
      },
      
    });
  };

  if (isAuthenticated === null) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
        <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
          <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
          <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
          </div>
        </div>
      </div>
    );
  }

  return isAuthenticated ? (
    <>
      {children}
      <div ref={videoCallDivRef} style={{ zIndex: 2000 }}></div>
    </>
  ) : null;
};

export default ProtectedRoute;