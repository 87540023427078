import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const setTokens = (accessToken, refreshToken) => {
    cookies.set('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
};

export const saveUserDetails = (firstname, lastname, email, mobile_number, UID) => {
    cookies.set('UserDetails', JSON.stringify({
        firstname, lastname, email, mobile_number, UID,
    }));
};

export const getUserAcc = () => {
    try {
        const data = cookies.get('UserDetails');
        if (data) {
            if (typeof data === 'string') {
                
                return JSON.parse(data);
            }
            
            return data;
        } else {
            // Return null if the cookie does not exist
            return null;
        }
    } catch (error) {
        console.error('Failed to retrieve or parse UserDetails cookie:', error);
        return null;
    }
};

export const clearTokens = () => {
    cookies.remove('accessToken');
    localStorage.removeItem('refreshToken');
};

export const getAccessToken = () => {
    return cookies.get('accessToken');
};

export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
};

export const getUserDetails = () => {
    const token = getAccessToken();
    if (!token) return null;
    const extractedToken = jwtDecode(token);
    const userDetails = {
        UID: extractedToken?.username,
    };

    return userDetails;
};

export const isTokenExpired = (token) => {
    if (!token) return true;
    const decoded = jwtDecode(token);
    const now = Date.now() / 1000;
    return decoded.exp < now;
};

export const refreshToken = async () => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
        clearTokens();
        return false;
    }
    try {
        const response = await axios.post('https://qvgt79a302.execute-api.ap-south-1.amazonaws.com/dev/Renew', {
            user: "",
            token: refreshToken
        });
        setTokens(response.data.accessToken, response.data.refreshToken);
        return true;
    } catch (error) {
        clearTokens();
        return false;
    }
};
