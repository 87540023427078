import React from 'react'
import { useEffect, useState } from 'react'
import ReportStore from '../DataRepo/ReportStore';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

function ViewReport() {
    const { Reports, isReportLoading, getReports } = ReportStore()
    const { id, key } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (Reports.length === 0) {
            getReports()
        }
    }, [])

    const selectedReport = Reports?.filter((e) => e?.id === id)?.[0]
    const selectedReportUrl = selectedReport?.Reports?.[key]?.Location;

    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Reports</p>
            </div>
        </nav>
        <div className='Reports-main-page'>
            <div className='Reports-text-box'>
                <p>{selectedReport?.ReportType}</p>
                <div className='flex flex-row justify-center align-items-center display-report'  >
                    {
                        selectedReport.filetype === 'images' ?
                            <img src={selectedReportUrl}></img> :
                            <iframe src={`https://docs.google.com/gview?url=${selectedReportUrl}&embedded=true`} width={"100%"} height={"800px"}  ></iframe>
                    }
                </div>
            </div>

        </div>
    </div>
}

export default ViewReport