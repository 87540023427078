import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment, IconButton, Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Email from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Lock from '@mui/icons-material/Lock';
import { toast, Zoom } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import OTPInput from 'react-otp-input'
import axiosInstance from '../Axios/AxiosConfig';
import { setTokens, saveUserDetails } from './AuthUtil'
import { Auth_URL } from '../Axios/config';
import { useLocation } from 'react-router-dom';

function SignUp() {
    const [showPassword, setShowPassword] = useState(false);
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const location = useLocation()
    const [SignupMethod, setSignUpMethod] = useState('Email');
    const [isModalVisible, setIsModalVisible] = useState(false); 
    const [timer, setTimer] = useState(0);
    const [isWaiting, setIsWaiting] = useState(false);
    const [otp, setOtp] = useState('');
    const [link, setlink] = useState('')
    const navigate = useNavigate();
    
 

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('Link') || '';
        setlink(id)
    }, [location]);


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const validate = () => {
        let tempErrors = {};
        tempErrors.emailOrPhone = SignupMethod === 'Email'
            ? /^\S+@\S+\.\S+$/.test(user) ? '' : 'Please enter a valid email address'
            : /^[0-9]{10}$/.test(user) ? '' : 'Please enter a valid Phone number';
        tempErrors.password = password.length > 7 ? '' : 'Password must be at least 8 characters long.';
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === '');
    };

    const handleSubmit = () => {
        if (validate()) {
            handleSignup();
        } else {
            console.log(errors)
            console.log('Form is invalid');
        }
    };

    const validateInput = () => {
        if (otp.length === 6) {
            return true
        }
        else {
            setError('Invalid input. Please enter a valid 6 digit OTP');
        }
    }

    const handleSignup = async () => {
        try {
            let res;

            if (SignupMethod === 'Email') {
                res = await axios.post(`${Auth_URL}Signup`, {
                    email: user,
                    user: user,
                    password
                });
            }

            else {
                res = await axios.post(`${Auth_URL}SignupWithPhone`, {
                    phone: `+91${user}`,
                    password
                });
            }

            if (res.status === 200) {
                setIsModalVisible(true);
            }

        } catch (err) {
            if (err.response?.status === 500) {
                toast.error(err?.response?.data?.error, {
                    position: 'top-center',
                    limit: 1,
                    transition: Zoom,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                });

                setIsModalVisible(true);
            }
        }
    };

    const handleVerify = async () => {
        try {
            let res = await axios.post(`${Auth_URL}VerfiyAccount`, {
                user: SignupMethod === 'Email' ? user : `+91${user}`,
                code: otp,
                password
            });

            if (res.status == 200) {
                handleLogin()
                setIsModalVisible(false)
            }
        }

        catch (err) {
            if (err.response.status === 500) {
                setError(err?.response?.data?.error)
                console.log(err?.response?.data)
            }
        }
    }

    const handleResend = async () => {
        try {
            let res = await axios.post(`${Auth_URL}Resend-Confirmation-code`, {
                user: SignupMethod === 'Email' ? user : `+91${user}`,
            });

            if (res.status == 200) {
                setIsWaiting(true);
                setTimer(30);
                setOtp('')
                setError('')
            }
        }
        catch (err) {
            if (err.response.status === 500) {
                setError(err?.response?.data?.error)
                console.log(err?.response?.data)
            }
        }
    }


    const handleLogin = async () => {
        try {
            let res = await axios.post(`${Auth_URL}Signin`, {
                email: SignupMethod === 'Email' ? user : `+91${user}`,
                password: password
            });

            if (res.status == 200) {
                const { accessToken, refreshToken, id } = res.data;
                setTokens(accessToken, refreshToken);
                const firstname = name.split(' ')[0];
                const lastname = name.split(' ')[1];
                const email = SignupMethod === 'Email' ? user : "";
                const mobile = SignupMethod === 'Email' ? '' : `+91${user}`;

                saveUserDetails(firstname, lastname, email, mobile, id);


                if (link != '') {
                    if (link === 'Wellness-Package') {
                        navigate('/PackageCheckout')
                    }
                    else {
                        navigate('/Profile')
                    }
                }
                else {
                    navigate('/Profile')
                }
            }
        }
        catch (err) {
            if (err.response.status === 500) {
                setError(err?.response?.data?.error)
                console.log(err?.response?.data)
            }
        }
    }


    const handleNameChange = (e) => {
        setName(e.target.value);
        setErrors({ ...errors, name: '' });
    };

    const handleEmailOrPhoneChange = (e) => {
        setUser(e.target.value);
        setErrors({ ...errors, emailOrPhone: '' });
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setErrors({ ...errors, password: '' });
    };

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    useEffect(() => {
        let countdown;
        if (isWaiting && timer > 0) {
            countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else if (timer === 0 && isWaiting) {
            setIsWaiting(false);
        }
        return () => clearInterval(countdown);
    }, [timer, isWaiting]);


    const maskFirstChars = (str, numChars) => {
        if (!str || str.length <= numChars) return '*'.repeat(str.length);
        return '*'.repeat(numChars) + str.slice(numChars);
    };

    return (
        <div className="SignUp-page">
            <div>

                <div className="Sigup-heading">
                    <p>SignUp</p>
                    <p>Create an account</p>
                </div>

                <div className="flex flex-col gap-5">
                    {/* <TextField
                        value={name}
                        onChange={handleNameChange}
                        placeholder="Enter your name"
                        error={!!errors.name}
                        helperText={errors.name}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                    /> */}
                    <TextField
                        value={user}
                        onChange={handleEmailOrPhoneChange}
                        placeholder={SignupMethod === 'Email' ? 'Enter mail id' : 'Enter Mobile Number'}
                        error={!!errors.emailOrPhone}
                        helperText={errors.emailOrPhone}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {SignupMethod === 'Email' ? <Email /> : <SmartphoneIcon />}
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Create Password"
                        type={showPassword ? 'text' : 'password'}
                        error={!!errors.password}
                        helperText={errors.password}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Lock />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                </div>

                <div className="flex flex-col gap-5 Welcome-action-btn">
                    <div>
                        <Button
                            style={{
                                width: '100%',
                                textTransform: 'none',
                                borderRadius: '0',
                                backgroundColor: '#DA6E0B',
                                color: 'white',
                                border: 'none',
                                boxSizing: 'border-box',
                                borderRadius: '5px',
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: '10px'
                            }}
                            onClick={handleSubmit}
                        >
                            Sign Up
                        </Button>
                    </div>
                    <div>
                        <Button
                            style={{
                                width: '100%',
                                textTransform: 'none',
                                borderRadius: '0',
                                border: '1px solid #DA6E0B ',
                                color: '#DA6E0B',
                                boxSizing: 'border-box',
                                borderRadius: '5px',
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: '10px'
                            }}
                            onClick={() => {
                                if (SignupMethod === 'Email') {
                                    setSignUpMethod('Mobile');
                                } else {
                                    setSignUpMethod('Email');
                                }
                            }}
                        >
                            {SignupMethod === 'Email' ? 'Sign up with Mobile Number' : 'Sign up with Email'}
                        </Button>
                    </div>
                    <div className="Signuptermsandcondition">
                        <p className="flex flex-row justify-center items-center gap-5 text-center">
                            By signing up you accept the<br />Terms & Conditions
                        </p>
                    </div>
                    <div className="flex flex-row justify-center items-center gap-5 Signuptermsandcondition">
                        <p style={{ color: '#75623F' }}>Are you an existing User?</p>
                        <p
                            style={{
                                fontWeight: '500',
                                color: '#75623F',
                                borderBottom: '1px solid #75623F',
                                cursor: 'pointer'
                            }}
                            onClick={() => { navigate('/Login'); }}
                        >
                            Login
                        </p>
                    </div>
                </div>

                {isModalVisible && (
                    <div className="modal-overlay" onClick={toggleModal}>
                        <div className="bottom-sheet" onClick={(e) => e.stopPropagation()}>
                            <div className="handle"></div>
                            <div className="bottom-sheet-content">
                                <h2>Authentication Code</h2>
                                <p>{SignupMethod === "Email" ?
                                    `Enter the 6 digit code send to your respective mail id ends with ${maskFirstChars(user, 6)}` :
                                    `Enter the 6 digit code send to your respective mobile number ${maskFirstChars(user, 6)}`}</p>
                                <div className='login-otp-text-input'>

                                    <div className='login-otp-text-input-div'>
                                        <OTPInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={6}
                                            inputStyle={{
                                                border: "1px solid transparent",
                                                borderRadius: "8px",
                                                width: "54px",
                                                height: "54px",
                                                fontSize: "16px",
                                                color: "#000",
                                                fontWeight: "500",
                                                outline: "1px solid silver",
                                                caretColor: "blue"
                                            }}

                                            renderSeparator={<span style={{ visibility: "hidden" }}>--</span>}
                                            renderInput={(props) => <input {...props} inputmode="numeric" />}
                                        />
                                    </div>

                                    <div className='login-otp-text-input-div'>
                                        <p style={
                                            isWaiting ? { visibility: 'visible' } : { visibility: "hidden" }
                                        }>{timer} s</p>

                                        <p onClick={handleResend}>Resend Code</p>
                                    </div>
                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                    <div className='login-otp-text-input-div'>
                                        <Button
                                            style={{
                                                width: '100%',
                                                textTransform: 'none',
                                                borderRadius: '0',
                                                backgroundColor: '#DA6E0B',
                                                color: 'white',
                                                border: 'none',
                                                boxSizing: 'border-box',
                                                borderRadius: '5px',
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: 500,
                                                padding: '10px'
                                            }}
                                            onClick={handleVerify}
                                        >
                                            Verify Account
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
}

export default SignUp;
