import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ReportStore from '../DataRepo/ReportStore';
import { IconButton } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

function ViewReportList() {
    const { Reports, isReportLoading, getReports } = ReportStore()
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (Reports.length == 0) {
            getReports()
        }
    }, [])

    const selectedReport = Reports.filter((e) => e.id === id)?.[0]

    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Reports</p>
            </div>
        </nav>
        <div className='Reports-main-page'>
            <div className='Reports-text-box'>
                <p>{selectedReport?.ReportType}</p>
                <div className='di-selectedReportscards'>
                    {
                        selectedReport?.Reports.map((e, i) => {
                            return <div className='SelectedReportCard' key={i} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    navigate(`/ViewReport/${selectedReport?.id}/${i}`)
                                }}>
                                {selectedReport.filetype === 'images' ? (
                                    <img src={e?.Location} alt='Image icon' 
                                    style={{ alignSelf: "center", justifySelf: "center" }} />
                                ) : (
                                    <img src='/icons/pdf icon.png' alt='PDF icon' />
                                )}
                                <p className='text-center mt-auto mb-2'>{e?.Key?.split('/')[3]}</p>
                            </div>
                        })
                    }
                </div>
            </div>

        </div>
    </div>
}

export default ViewReportList