import React, { useState } from 'react'
import { TextField, InputAdornment, IconButton, Button } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import OTPInput from 'react-otp-input';
import { Auth_URL } from '../Axios/config';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Lock from '@mui/icons-material/Lock';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import { toast, Zoom } from 'react-toastify';

function ForgotPassword() {


    const { id } = useParams()
    const [user, setUser] = useState(id);
    const [password, setPassword] = useState('');
    const [SignupMethod, setSignUpMethod] = useState('Email');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [timer, setTimer] = useState(0);
    const [isWaiting, setIsWaiting] = useState(false);
    const [errors, setErrors] = useState({});
    const [otp, setOtp] = useState('');
    const [isOTPModal, setOTPModal] = useState(false);

    const navigate = useNavigate()

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    function addCountryCodeIfNumber(input) {
        if (!isNaN(input) && input.trim() !== '') {
            return '+91' + input;
        }
        return input;
    }

    function CheckEmailOrPhone(input) {
        if (!isNaN(input) && input.trim() !== '') {
            return "Phone";
        }
        return "Email";
    }

    const validate = () => {
        let tempErrors = {};
        const emailRegex = /^\S+@\S+\.\S+$/;
        const phoneRegex = /^[0-9]{10}$/;
        if (!emailRegex.test(user) && !phoneRegex.test(user)) {
            tempErrors.emailOrPhone = 'Please enter a valid email address or phone number';
        }
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === '');
    }

    const handleEmailOrPhoneChange = (e) => {
        setUser(e.target.value);
        setErrors({ ...errors, emailOrPhone: '' });
    };

    const handleResetPWD = async () => {
        try {
            const res = await axios.post(`${Auth_URL}ConfirmPassword`, {
                user: addCountryCodeIfNumber(user),
                code: otp,
                newpassword: password
            })

            if (res.status === 200) {
                navigate('/login')
            }

        }
        catch (err) {
            if (err?.response?.data?.error === 'CodeMismatchException') {
                toast.error('Please enter the correct OTP', {
                    position: 'top-center',
                    limit: 1,
                    transition: Zoom,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                });
            }
        }
    }

    const handleVerifyOTP = async () => {
        try {
            const res = await axios.post(`${Auth_URL}ForgotPassword`, {
                user: addCountryCodeIfNumber(user)
            })

            if (res.status === 200) {
                setOTPModal(true)
            }

        }
        catch (err) {
            toast.error(err?.response?.data?.name, {
                position: 'top-center',
                limit: 1,
                transition: Zoom,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            });

            setTimeout(() => {
                navigate('/login')
            }, 2500)
        }
    }

    const handleSubmit = () => {
        if (validate()) {
            handleVerifyOTP()
            setSignUpMethod(CheckEmailOrPhone(user))
        }
    };

    const maskFirstChars = (str, numChars) => {
        if (!str || str.length <= numChars) return '*'.repeat(str.length);
        return '*'.repeat(numChars) + str.slice(numChars);
    };

    const toggleModal3 = () => {
        setOTPModal(!isOTPModal);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setErrors({ ...errors, password: '' });
    };

    return <div className="SignUp-page">
        <div>
            <div className="rpwdh-heading">
                <p>Forgot Password</p>
                <p>Enter your mail id / mobile number to
                    reset password</p>
            </div>
            <div className="flex flex-col gap-5">
                <TextField
                    value={user}
                    onChange={handleEmailOrPhoneChange}
                    placeholder={"Enter mobile number/ Mail id"}
                    error={!!errors.emailOrPhone}
                    style={{ width: "20rem" }}
                    helperText={errors.emailOrPhone}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LoginIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className="flex flex-col gap-5 Welcome-action-btn">
                <div>
                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            backgroundColor: '#DA6E0B',
                            color: 'white',
                            border: 'none',
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: '10px'
                        }}
                        onClick={() => {
                            handleSubmit()
                        }}
                    >
                        Verify & Reset
                    </Button>
                </div>
            </div>
            {isOTPModal && (
                <div className="modal-overlay" onClick={toggleModal3}>
                    <div className="bottom-sheet" onClick={(e) => e.stopPropagation()}>
                        <div className="handle"></div>
                        <div className="bottom-sheet-content">
                            <h2>Authentication Code</h2>
                            <p>{SignupMethod === "Email" ?
                                `Enter the 6 digit code sent to your respective mail id ends with ${maskFirstChars(user, 6)}` :
                                `Enter the 6 digit code sent to your respective mobile number ${maskFirstChars(user, 6)}`}</p>
                            <div className='login-otp-text-input'>
                                <div className='login-otp-text-input-div'>
                                    <OTPInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={6}
                                        inputStyle={{
                                            border: "1px solid transparent",
                                            borderRadius: "8px",
                                            width: "54px",
                                            height: "54px",
                                            fontSize: "16px",
                                            color: "#000",
                                            fontWeight: "500",
                                            outline: "1px solid silver",
                                            caretColor: "blue"
                                        }}
                                        renderSeparator={<span style={{ visibility: "hidden" }}>--</span>}
                                        renderInput={(props) => <input {...props} autoComplete="one-time-code" inputmode="numeric"/>} 
                                    />

                                </div>

                                <div className='login-otp-text-input-div'>
                                    <p style={
                                        isWaiting ? { visibility: 'visible' } : { visibility: "hidden" }
                                    }>{timer} s</p>

                                    <p onClick={handleVerifyOTP}>Resend Code</p>
                                </div>

                                {error && <p style={{ color: 'red' }}>{error}</p>}
                                <TextField
                                    value={password}
                                    sx={{ marginTop: "1rem" }}
                                    onChange={handlePasswordChange}
                                    placeholder="New Password"
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Lock />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <div className='login-otp-text-input-div'>
                                    <Button
                                        style={{
                                            width: '100%',
                                            textTransform: 'none',
                                            borderRadius: '0',
                                            backgroundColor: '#DA6E0B',
                                            color: 'white',
                                            border: 'none',
                                            boxSizing: 'border-box',
                                            borderRadius: '5px',
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 500,
                                            padding: '10px'
                                        }}
                                        onClick={handleResetPWD}
                                    >
                                        Reset
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    </div>
}

export default ForgotPassword