import React from 'react';
import PhenoTypeAssesment from '../Assets/images/phenotype assessment.png';
import DoneIcon from '@mui/icons-material/Done';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import outerCircle from '../Assets/images/vector.png';
import PrakritiRotation from '../Assets/animations/Prakriti rotation 2.json';
import uq from '../Assets/images/current imabalance.png'
import imp from '../Assets/images/wellness package.png'
import Lottie from 'lottie-react';
import { Button } from '@mui/material';
import ChatBot from '../Assets/images/Prakriti screen green circle.png'
import { useNavigate } from 'react-router-dom';

function VikritiIntro() {
    let navigate = useNavigate()

    return (
        <div className='Profile-page-section'>

            <div className='Profile-img-section'>
                <div>Homeostasis Assessment</div>
                <div>
                    <Lottie
                        animationData={PrakritiRotation}
                        className='PrakritiRotationAnimation'
                    />
                </div>
            </div>

            <div className='Prakriti-intro-main-section'>
                <div className='Prakriti-into-title'>
                    <div>
                        <img src={ChatBot}></img>
                    </div>
                    <div>
                        <p>
                            I am your Personalized Coach
                        </p>
                    </div>
                    <div style={{
                        justifySelf: "flex-end",
                        cursor: 'pointer',
                        borderBottom: "1px solid silver",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 400,
                    }} onClick={() => {
                        navigate('/')
                    }}>
                        <p>Skip now</p>
                    </div>
                </div>
                <div className='Prakriti-into-title-discription'>

                    <p>Homeostasis Imbalance</p>
                    <p>Homeostasis imbalance represents the present condition of health, <br />
                        revealing any deviations from an individual's inherent constitution, <br />
                        signaling potential illness.</p>

                </div>

                <div className='Prakriti-into-detailed-discription'>
                    <p>Through this assessment, discover your</p>
                    <div>
                        <div>
                            <img src={uq}></img>
                            <p>Current Imbalance</p>
                        </div>
                        <div>
                            <img src={imp}></img>
                            <p>Wellness Packages Tailored for You</p>
                        </div>
                    </div>
                </div>


                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignSelf: "flex-end",
                    alignItems: "center", width: '100%'
                }}>
                    <div className='phenotypebtn'>
                        <p>Now let's understand your Imbalance</p>
                    </div>
                    <Button
                        style={{
                            textTransform: 'none',
                            borderRadius: '0',
                            backgroundColor: '#DA6E0B',
                            color: 'white',
                            border: 'none',
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: '10px',
                            paddingRight: "2rem",
                            paddingLeft: "2rem"
                        }}

                        onClick={() => {
                            navigate('/Vikriti')
                        }}

                    >
                        Start Your Assesment
                    </Button>
                </div>



                {/* <div className='background-animation'>
                    <Lottie
                        animationData={PrakritiRotation}
                        className='PrakritiRotationAnimation'
                    />
                </div> */}

            </div>
            
        </div>
    );
}

export default VikritiIntro;
